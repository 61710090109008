module PositiveTS {
export module Service {
export module FileUpload {
  const SERVICE_NAME ="file_upload"
  const ACTION_FILE_UPLOAD = "FILE_UPLOAD"
  const ACTION_SAVE_FILE_BASE64 = "SAVE_FILE_BASE64"
  const ACTION_FILE_DELETE = "FILE_DELETE"
  const READ_FILE_BASE64 = "READ_FILE_BASE64"

  const ADDON_FILES_UPDATE = "ADDON_FILES_UPDATE"
  const ADDON_LOCAL_MANIFEST = "ADDON_LOCAL_MANIFEST"

  const TRAN_PATH = "c:/sva/tran"
  const URL_MANIFEST = '/addon/manifest.json'
  const STATUS_SUCCESS = "OK"


  function getAddonLocalManifestDict() {
    return {data: {
      manufestUrl: PositiveTS.Service.StrUtils.getHosturl() + URL_MANIFEST
    },
    payments: [],
    action: ADDON_LOCAL_MANIFEST };
  };

  function getAddonFilesUpdateDict(versionToUpdate=null) {

    let manifsetURL =posUtils.isBlank(versionToUpdate) ? URL_MANIFEST :  `/addon/${versionToUpdate}/manifest.json`;
    return {data: {
      manufestUrl: PositiveTS.Service.StrUtils.getHosturl() + manifsetURL
    },
    payments: [],
    action: ADDON_FILES_UPDATE };
  };

  function getFileDeleteDict(filename) {

    return {data: {
      filename: filename
    },
    payments: [],
    action: ACTION_FILE_DELETE };

  };


  function getReadFileBase64Dict(filename) {

    return {data: {
      filename: filename
    },
    payments: [],
    action: READ_FILE_BASE64 };

  };

  function getSaveFileBase64Dict(filename, base64data) {

    return {data: {
      filename: filename,
      base64data: base64data 
    },
    payments: [],
    action: ACTION_SAVE_FILE_BASE64 };

  };

  export function genericFileSave(filename,base64data){
    return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension( getSaveFileBase64Dict(filename , base64data),SERVICE_NAME )
    .then(function(x){
      if (STATUS_SUCCESS !== x.request.result){
        console.error("Error while saving unified generic file: " + filename + "!");
        throw new Error(x.request.result);
      }
      return x.request.result;
    });  
  }

  export async function deleteFile(filename) {
    let res = await Addon.sendNativeMessageToExtension(getFileDeleteDict(filename), SERVICE_NAME);
    
    if(!res.request || res.request.result !== STATUS_SUCCESS) {
      console.error(`Error while deleting ${filename}`);
      throw new Error(res.request.result);
    }
    return res.request.result;
  }

  export function deleteTRAN() {
    return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension( getFileDeleteDict(TRAN_PATH),SERVICE_NAME )
    .then(function(x){
      if (STATUS_SUCCESS !== x.request.result){
        console.error("Error while deleting TRAN file!!!");
        throw new Error(x.request.result);
      }
      return x.request.result;
    });  
  };

  export function saveTRAN(base64data) {
    return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension( getSaveFileBase64Dict(TRAN_PATH , base64data),SERVICE_NAME )
    .then(function(x){
      if (STATUS_SUCCESS !== x.request.result){
        console.error("Error while saving unified TRAN file!!!");
        throw new Error(x.request.result);
      }
      return x.request.result;
    });  
  };

  export function readFileBase64(filePath) {
    return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension( getReadFileBase64Dict(filePath ),SERVICE_NAME )
    .then(function(x){
      if ( x.request.result.startsWith( "ERROR:" ) ){
        console.error("Error while sreading file!!!");
        throw new Error(x.request.result);
      }
      return x.request.result;
    });  
  };



  export function addonLocalManifest() {
    return new Promise((resolve,reject) => {
      return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension( getAddonLocalManifestDict(),SERVICE_NAME )
      .then(function(x) {
        resolve(x.request.result);
      })
      .catch((err) => {
        console.error(err)
        resolve(null);
      })
    })
    
  }

  export function addonLocalManifestVer() {
    return addonLocalManifest()
    .then(function(response:any) {
      return JSON.parse(response).ver;
    })
    .catch(function(){
      return 'unknown';
    });    
  };

  export async function addonManifestVersions(){
    try{
      let response = await Service.Ajax.promiseJqAjax(PositiveTS.Service.StrUtils.getHosturl() + URL_MANIFEST,null,"GET");
      let parsedData = JSON.parse(response.request.result);
      return parsedData;
    }catch(err){
      console.error(err);
      return null;
    }
  }

  export function addonFilesUpdate (versionToUpdate =null) {
    return PositiveTS.Service.GenericAddon.sendNativeMessageToExtension(getAddonFilesUpdateDict(versionToUpdate),SERVICE_NAME )
    .then(function(x){
      if (STATUS_SUCCESS !== x.request.result){
        throw new Error(x.request.result);
      }
      return x.request.result;
    });  
  };
}}}












