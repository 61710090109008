module PositiveTS {
  export module Service {
    export class EMV extends PositiveShared.Emv {


      static instance: EMV
      static PINPAD_DEAL = PositiveShared.PanEntryModeType.PINPAD_DEAL;
      static PHONE_DEAL = PositiveShared.PanEntryModeType.PHONE_DEAL;

      static ERRORS_THAT_JUSTIFIES_RECOVERY = [
        10000,
        10003,
        10022,
        10041,
        10043,
        10053,
        10054
      ]



      constructor() {
        super(Pinpad.getInstance(),
          Pinpad.getPinpadConfig(),
          Pinpad.getAddonstatic(),
          Pinpad.getLoadingMessagesAndDialogManager(),
          Pinpad.getLogger(),
          MultiCurr.getInstance())
      }

      static getInstance(): EMV {
        if (!EMV.instance) {

          EMV.instance = new EMV();
        }

        return EMV.instance;
      }

      static isFullEmv(panEntryMode) {
        return this.getInstance().isFullEmv(panEntryMode);
      }

      async AddendumSettlForJR(entitySequence): Promise<string> {
        let sequence = await Storage.Entity.Sequence.getSequenceForInvType(entitySequence)
        let sequenceNumber = sequence.sequence + 1;

        sequenceNumber = String(sequenceNumber);

        if (sequenceNumber.length > 9) {
          sequenceNumber = sequenceNumber.substr(sequenceNumber.length - 9, sequenceNumber.length);
        }


        let flightID = String(Pinia.flightsStore.flight.id)
        let actualPosNumber = this.configurations.posNumber;
        let posNumber = String(actualPosNumber).padStart(2, "0")
        let paddedSequence = String(sequenceNumber)
        let addendumObj = {
          user: `${flightID}-${posNumber}-${paddedSequence}`.substr(0, 19),
          shiftId1: flightID,
          userData1: posNumber
        }

        return JSON.stringify(addendumObj);
      }


      async createPaymentRequestObj(amount, emvMode, emvCreditTerms: number, numberOfPayments: number, verificationCode?, tranType = "1", firstPayment?, specialAddendumSettl = null, ParameterJ = "4", extraRequestTagData = null) {

        if (session.pos.hasFlights) {
          specialAddendumSettl = await this.AddendumSettlForJR(Storage.Entity.Sequence.TYPE_DEBIT_INVOICE);
        }

        if (Service.Gateway.isEnabled()) {
          let shiftId1 = Service.Gateway.getShiftId1();
          let shiftId2 = Service.Gateway.getShiftId2();
          let shiftId3 = await Service.Gateway.getShiftId3();
          let shiftTxnDate = moment().format('YYYY-MM-DD HH:mm:ss');
          if (!extraRequestTagData) {
            extraRequestTagData = {}
          }
          extraRequestTagData["AddendumSettl"] = JSON.stringify({ shiftId1, shiftId2, shiftId3, shiftTxnDate })
          extraRequestTagData["SwitchSend"] = "1"
        }

        return await super.createPaymentRequestObj(amount, emvMode, emvCreditTerms, numberOfPayments, verificationCode, tranType, firstPayment, specialAddendumSettl, ParameterJ, extraRequestTagData)
      }

      static async swipe(amount?: number, throwException = false) {
        if (session.pos.usePinPad && !session.pos.isEmv) {
          if (posUtils.isBlank(amount)) {
            amount = 0;
          }
          return Service.MSR.swipeTrack2(amount)
        }

        return await this.getInstance().swipe(throwException)
      }

      static async setAdapativeEMVPosNumberIfNeeded() {
        if (jsonConfig.getVal(jsonConfig.KEYS.adaptiveEmvPosNumber)) {

          let result = await this.getInstance().setAdapativeEMVPosNumber();

          if (result) {
            jsonConfig.setVal(jsonConfig.KEYS.emvPosNumber, result)
          }
        }
      }

      async createCancelRequestObj(originalPaymentToCancel, isRefund = false) {
        let specialAddendumSettl = null;

        if (session.pos.hasFlights && originalPaymentToCancel.AddendumSettl) {
          if (!isRefund) {
            specialAddendumSettl = originalPaymentToCancel.AddendumSettl
          } else {
            specialAddendumSettl = await this.AddendumSettlForJR(Storage.Entity.Sequence.TYPE_CREDIT_INVOICE)
          }
        }
        let extraRequestTagData = null
        if (Service.Gateway.isEnabled()) {
          extraRequestTagData = {};

          let shiftId1 = Service.Gateway.getShiftId1();
          let shiftId2 = Service.Gateway.getShiftId2();
          let shiftId3 = await Service.Gateway.getShiftId3();
          let shiftTxnDate = moment().format('YYYY-MM-DD HH:mm:ss');
          extraRequestTagData["AddendumSettl"] = JSON.stringify({ shiftId1, shiftId2, shiftId3, shiftTxnDate })
          extraRequestTagData["SwitchSend"] = "1"
        }


        return await super.createCancelRequestObj(originalPaymentToCancel, isRefund, specialAddendumSettl, extraRequestTagData)
      }

      async createCreditRequestObj(paymentToCredit, isManual: boolean): Promise<PositiveShared.EmvRequest> {

        let specialAddendumSettl = null;

        if (session.pos.hasFlights) {
          specialAddendumSettl = await this.AddendumSettlForJR(Storage.Entity.Sequence.TYPE_CREDIT_INVOICE)
        }
        let extraRequestTagData = null
        if (Service.Gateway.isEnabled()) {
          extraRequestTagData = {};
          let shiftId1 = Service.Gateway.getShiftId1();
          let shiftId2 = Service.Gateway.getShiftId2();
          let shiftId3 = await Service.Gateway.getShiftId3();
          let shiftTxnDate = moment().format('YYYY-MM-DD HH:mm:ss');
          extraRequestTagData["AddendumSettl"] = JSON.stringify({ shiftId1, shiftId2, shiftId3, shiftTxnDate })
          extraRequestTagData["SwitchSend"] = "1"
        }

        return await super.createCreditRequestObj(paymentToCredit, isManual, specialAddendumSettl, extraRequestTagData);
      }

      getTotalAndAttachToZ(zReport: Storage.Entity.ZReport) {
        return this.getTotal()
          .then(result => {
            zReport.emvTransmitReport = JSON.stringify(result.StaticObj);
          })
      }

      static getDepositReport(sessionNumber: string, date: string) {
        return this.getInstance().getDepositReport(sessionNumber, date);
      }

      static updatePinPad(): Promise<PositiveShared.EmvOutput> {
        return this.getInstance().updatePinPad();
      }

      static sendEMVLogsToCaspitFTP(): Promise<PositiveShared.EmvOutput> {
        return this.getInstance().sendEMVLogsToCaspitFTP();
      }

      static callCaspitTechServer(): Promise<PositiveShared.EmvOutput> {
        return this.getInstance().callCaspitTechServer();
      }

      static async terminalCheck() {
        await this.getInstance().terminalCheck();
      }

      static getTotal(): Promise<PositiveShared.EmvOutput> {
        return this.getInstance().getTotal();
      }

      static DisplayQR(qrString, timeout = 30) {
        return this.getInstance().DisplayQR(qrString, timeout);
      }

      async getTransmitReportAndAttachToZ(zReport: Storage.Entity.ZReport): Promise<Storage.Entity.ZReport> {
        try {
          if (!zReport.emvTransmitReport) {
            return zReport;
          }

          let transReport = JSON.parse(zReport.emvTransmitReport)
          let request = this.createGetTransmitReportObj(transReport.SessionNumber, transReport.Date)
          const addonResponse = await this.pinpad.sendRequestToPinpad(request, PositiveShared.EmvRequestType.EMV, i18next.t('emv.GETTING_TRANS_REPORT'))
          console.dir(addonResponse);
          let result = this.pinpad.parseResult(addonResponse)
          transReport.DepositReport = result.DepositReport
          zReport.emvTransmitReport = JSON.stringify(transReport)
          return zReport;
        } catch (error) {
          Logger.error(error);
          throw error;
        }
      }

      static printTransmitReportStandalone(DepositReport) {
        Printing.Reports.ZXReports.printTransmitReportStandalone(DepositReport);
      }

      async startPinpadRecovery(request: any, parsedResult: any, isResetService: boolean, isShownResetInstructions: boolean = false) {
        try {
          app.showLoadingMessage(i18next.t('emv.pinpadRecoveryProcess'));
          let xField = request.XField;
          let foundTransaction = await this.findTransactionInPinpad(xField);
          let errorMessage: string = '';
          if (!posUtils.isBlank(foundTransaction) && foundTransaction.success) {
            localStorage.removeItem("lastEMVErrorCode");
            return { success: true, result: foundTransaction.transaction, error: null, request: foundTransaction.request };
          } else {
            let parsedTransactionResult = foundTransaction.transaction;
            if (this.pinpad.isCommunicationError(Number(parsedTransactionResult?.ResultCode)) || posUtils.isBlank(parsedTransactionResult?.ResultCode)) {
              // Start pinpad service recovery process if we are not in http pinpad setup
              localStorage.setItem("lastEMVErrorCode", parsedTransactionResult?.ResultCode || "NETWORK_ERROR")
              errorMessage = this.pinpadErrorBuilder()
              if (this.configurations.isHttpConnection) {
                return { success: false, result: parsedTransactionResult, error: errorMessage };
              }
              // We have 2 things we can do before calling it quits and showing the contact support message
              // 1. Try to reset the pinpad service and try again
              // 2. Try to physically reset the pinpad with instructions to the user on how to do it and try finding the transaction again
              if (!isResetService) {
                app.showLoadingMessage(i18next.t('emv.killingPinpadAddon'));
                await this.pinpad.killPinpadAddon();
                localStorage.removeItem("lastEMVErrorCode");
                return await this.startPinpadRecovery(request, parsedTransactionResult, true, isShownResetInstructions);
              } else {
                if (!isShownResetInstructions) {
                  // Show instructions to the user on how to reset the pinpad
                  await Pinia.componentsStore.openComponent({
                    componentName: "TimedAlertDialog", args: [{
                      title: i18next.t('error'),
                      content: i18next.t('emv.pinpadResetInstructionsMessage'),
                      hideCancelButton: true,
                      confirmButtonText: i18next.t('emv.pinpadResetInstructionsConfirmButton'),
                      timeToEnable: 60,
                    }]
                  })
                  localStorage.removeItem("lastEMVErrorCode");
                  return await this.startPinpadRecovery(request, parsedTransactionResult, isResetService, true);
                } else {
                  return { success: false, result: foundTransaction.transaction, error: errorMessage };
                }
              }
            } else {
              // If we got here, we have a transaction that we can't find in the pinpad
              throw new Error(i18next.t('emv.paymentNotFoundInRecovery'));
            }
          }
        } catch (e) {
          this.logger.error(e)
          this.logger.error(e.stack)
          let dummyOutput = new PositiveShared.EmvOutput()
          dummyOutput.ResultCode = "-1000"
          return { success: false, result: dummyOutput, error: e.message };
        }
      }

      pinpadErrorBuilder(showRestartGif = false): string {
        const legitErrorCodes = ["10054", "10003", "10022"]
        let lastEMVErrorCode = window.localStorage.getItem("lastEMVErrorCode");

        let content = showRestartGif ? i18next.t("emv.PINPAD_NOT_CONNECTED_ANDROID") : i18next.t("emv.PINPAD_NOT_CONNECTED");

        if (!posUtils.isBlank(Service.EMV.getIpAddress())) {
          content = i18next.t("emv.PINPAD_NOT_CONNECTED_IP");
        }

        if (!posUtils.isNullOrUndefinedOrEmptyString(lastEMVErrorCode)) {
          content += `\n\n${i18next.t("emv.errorNum")} ${lastEMVErrorCode}`
          if (i18next.te(`emv.errors.${lastEMVErrorCode}`)) {
            content += "\n" + i18next.t(`emv.errors.${lastEMVErrorCode}`);
          }
        }
        return content;
      }

      static async payWithCreditCard(amount, emvMode: string, creditCardPaymentType, numberOfPayments, verificationCode?, tranType = "1", firstPayment?, isRetry?, ParameterJ = "4", extraRequestTagData?): Promise<{ success: boolean, result: PositiveShared.EmvOutput, error: string }> {
        return await this.getInstance().payWithCreditCard(amount, emvMode, creditCardPaymentType, numberOfPayments, verificationCode, tranType, firstPayment, isRetry, ParameterJ, extraRequestTagData);
      }

      static async GetCardDetails(amount, emvMode: string, creditCardPaymentType, numberOfPayments, verificationCode?, tranType = "1", firstPayment?, isRetry?, ParameterJ = "2", extraRequestTagData?)
        : Promise<{ success: boolean, result: PositiveShared.EmvOutput, error: string }> {
        return await this.getInstance().payWithCreditCard(amount, emvMode, creditCardPaymentType, numberOfPayments, verificationCode, tranType, firstPayment, isRetry, ParameterJ, extraRequestTagData);
      }

      static cancelPayment(paymentToCancel: PositiveShared.EmvOutput)
        : Promise<{ success: boolean, result: PositiveShared.EmvOutput, error: string }> {
        return this.getInstance().cancelPayment(paymentToCancel);
      }

      static creditPayment(originalPayment: any)
        : Promise<{ success: boolean, result: PositiveShared.EmvOutput, error: string, originalPayment: any }> {
        return this.getInstance().creditPayment(originalPayment);
      }

      static async callShvaWithoutTransmittingTransactions() {
        return await this.getInstance().callShvaWithoutTransmittingTransactions();
      }

      static async transmitAndDeleteTranIfNeeded() {
        return await this.getInstance().transmitAndDeleteTranIfNeeded();
      }

      static async isPinPadConnected(): Promise<boolean> {
        return await this.getInstance().isPinPadConnected();
      }

      static async compareToTranAndTransmit(zReport: Storage.Entity.ZReport): Promise<void> {
        return await this.getInstance().compareToTranAndTransmit(zReport);
      }
      static getIpAddress() {
        if (!posUtils.isBlank(localStorage.getItem('emvIpAddress'))) {
          return localStorage.getItem('emvIpAddress');
        }
        return jsonConfig.getVal(jsonConfig.KEYS.emvIpAddress)
      }


      async compareToTranAndTransmit(zReport: Storage.Entity.ZReport, retryCount: number = 0): Promise<void> {
        try {
          zReport.XFields = localStorage.getItem("XFields")
          app.showLoadingMessage(i18next.t("emv.filteringFaildTran"));

          let emvVersion = await this.pinpadVersion()

          //run the filtering only on pinpads with version greater than 1.50.1
          //due to a bug in older pinpad versions
          if ((emvVersion >= 1501 || jsonConfig.getVal(jsonConfig.KEYS.sunmiInternalEMVPayment)) && !jsonConfig.getVal(jsonConfig.KEYS.disableSaleFiltering) && retryCount == 0) {
            let XFieldsObj = this.XFields;
            let xFieldsToCancelLater;
            if (!posUtils.isNullOrUndefined(XFieldsObj)) {
              if (jsonConfig.getVal(jsonConfig.KEYS.saleFilteringFaileds)) {
                xFieldsToCancelLater = [...XFieldsObj.successfullXFields, ...XFieldsObj.unknownXFields, ...XFieldsObj.failedXFields];
              } else {
                xFieldsToCancelLater = [...XFieldsObj.successfullXFields, ...XFieldsObj.unknownXFields];
              }
            } else {
              xFieldsToCancelLater = [];
            }
            xFieldsToCancelLater = _.uniq(xFieldsToCancelLater);

            let sales = await appDB.sales.where('invoiceSequence').above(0).toArray();

            for (let sale of sales) {
              for (let payment of sale.payments) {
                if (payment.method == Storage.Entity.SalePayment.METHOD_CREDIT) {
                  let data = JSON.parse(payment.data)
                  for (let payementData of data) {
                    if (payementData) {
                      let searchResult = xFieldsToCancelLater.indexOf(payementData.Xfield)
                      if (searchResult > -1) {
                        xFieldsToCancelLater.splice(searchResult, 1)
                      }
                    }
                  }
                }
              }
            }

            // Now its time to cancel the transactions that we have left
            for (let XField of xFieldsToCancelLater) {
              let queryRequest = this.createTransactionQueryRequest(XField);
              try {
                let queryResponse = await this.pinpad.sendRequestToPinpad(queryRequest, PositiveShared.EmvRequestType.EMV)
                queryResponse = this.pinpad.parseResult(queryResponse);
                if (queryResponse.ResultCode == "0") { //Transaction was found
                  await this.cancelPayment(queryResponse.EMV_Output);
                }
              } catch (err) {
                console.error("When trying to cancel xfield ::: Request ::: ", queryRequest);
                console.error("When trying to cancel xfield ::: Error :::", err);
              }
            }
          }

          app.hideLoadingMessage();
          let transmitResult = await this.transmit()

          if (transmitResult.ResultCode != "0") {
            if (transmitResult.ResultCode != "10019" && transmitResult.ResultCode != "10021") {
              if (retryCount < 3) {
                return await this.compareToTranAndTransmit(zReport, retryCount + 1);
              } else {
                throw new Error(i18next.t('emv.ERROR_WHILE_DOING_TRANSMIT_WITH_RETRY'))
              }
              // throw new Error(i18next.t('emv.ERROR_WHILE_DOING_TRANSMIT'))
            } else {
              //there are no transactions to send to the switch
              //that's not an error as far as we concern but we notifiy it anyway
              let creditCardPaymentsExists = await zReportVC.isCreditCardPaymentsExists()
              if (creditCardPaymentsExists && !jsonConfig.getVal(jsonConfig.KEYS.transmitTranToSwitchWhenBackToOnline)) {
                PositiveTS.Service.Logger.error(transmitResult.ResultCode + " - " + i18next.t('emv.ERROR_WHILE_DOING_TRANSMIT'))
                await app.promiseShowAlert({
                  header: i18next.t('emv.payAttention'),
                  content: i18next.t('emv.creditPaymentsExistsButStillGot10019'),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true,
                })
              }

            }
          }
          else if (this.configurations.usePinpadInSwitchMode == true) {
            let deleteResult = await this.deleteCurrentPinpadTran()
            if (deleteResult.ResultCode != "0") {
              console.error(deleteResult.ResultCode + " - " + i18next.t('emv.ERROR_WHILE_DELETING_TRAN'))
              PositiveTS.Service.Logger.error(deleteResult.ResultCode + " - " + i18next.t('emv.ERROR_WHILE_DELETING_TRAN'))
            }
          }

          localStorage.removeItem("XFields")
          let XFieldsObj = {
            unknownXFields: [],
            failedXFields: [],
            successfullXFields: [],
          }

          localStorage.setItem("XFields", JSON.stringify(XFieldsObj))


          if (!this.configurations.usePinpadInSwitchMode) {
            console.debug('get total and attach to Z')
            await this.getTotalAndAttachToZ(zReport)
            console.debug('get transmit report and attach to Z')
            let res = await this.getTransmitReportAndAttachToZ(zReport)
            console.dir(res)
          }
          return;
        }
        catch (error) {
          PositiveTS.Service.Logger.error(error);
          app.hideLoadingMessage();
          throw (error);
        }

      }

      static manualConfirmationNumberRequired(result: PositiveShared.EmvOutput): boolean {
        return this.getInstance().manualConfirmationNumberRequired(result);
      }

      static oldFileExists(result: PositiveShared.EmvOutput): boolean {
        return this.getInstance().oldFileExists(result);
      }

      static oldTranNotEmpty(result: PositiveShared.EmvOutput): boolean {
        return this.getInstance().oldTranNotEmpty(result);
      }

      static isTimeoutResponse(result: PositiveShared.EmvOutput) {
        return this.getInstance().isTimeoutResponse(result);
      }

      static async showEmvRecoveryMessage() {
        let showRestartGif = session.isAndroid && !(Pinia.globalStore.simpleSelfService || Pinia.globalStore.selfServiceSuperMarket)
        const legitErrorCodes = ["10054", "10003", "10022"]
        let lastEMVErrorCode = window.localStorage.getItem("lastEMVErrorCode");
        if (legitErrorCodes.includes(lastEMVErrorCode)) {
          showRestartGif = false
        }

        let content = EMV.getInstance().pinpadErrorBuilder(showRestartGif);

        let options: any = {
          header: i18next.t('emv.pinpadErrorTitle'),
          content: content,
          continueButtonText: i18next.t('ok'),
          hideCancelButton: true
        }
        if (showRestartGif) {
          options.imageUrl = `${(<any>window).images_path}pos/restart-pos.gif`;
          if (Pinia.globalStore.mobileLayout) {
            options.imageHeight = 195;
            options.width = '100vw';
          }
          else {
            options.imageHeight = 337;
          }

        }
        return await app.showAlertDialog(options)
      }

      static async checkIfRecoveryNeeded(awaitForMessage = false) {

        await app.showLoadingMessageAsync(i18next.t('emv.cardReaderConnectivity'));

        let isConnected = false;
        try {
          //we don't have credit payments - don't allow credit payments unless we have EMPTY TRAN!
          isConnected = await this.getInstance().isPinPadConnected()
          if (isConnected) {
            Pinia.globalStore.setEmvRecoveryNeeded(false);
            app.hideLoadingMessage();
          } else {
            //we can't check! don't allow to do credit card deals
            Pinia.globalStore.setEmvRecoveryNeeded(true)
            //notify the user that the pinpad is offline...

            if (awaitForMessage) {
              await this.showEmvRecoveryMessage();
            } else {
              this.showEmvRecoveryMessage();
            }
          }
        } catch (error) {
          console.error(error);
          Pinia.globalStore.setEmvRecoveryNeeded(true)
          app.hideLoadingMessage()
          //block the credit card
        }

        if (isConnected && Service.Withdrawal.posHasCashWithdrawals()) {
          try {
            app.showLoadingMessage(i18next.t('withdrawal.loadingWithdrawalParams'));
            await Service.Withdrawal.updateWithdrawalParams();
            app.hideLoadingMessage();
          } catch (err) {
            console.error(err);
          }
        }
      }

      private get XFields(): { successfullXFields: Array<any>, failedXFields: Array<any>, unknownXFields: Array<any> } {
        return JSON.parse(localStorage.getItem("XFields"));
      }

      storeXField(XField) {
        let XFieldsObj = this.XFields;
        XFieldsObj.unknownXFields.push(XField)
        XFieldsObj.unknownXFields = _.uniq(XFieldsObj.unknownXFields);
        localStorage.setItem("XFields", JSON.stringify(XFieldsObj))
        localStorage.setItem("lastXField", XField)

      }

      moveUnknownXFieldToFailed(XField) {
        let XFieldsObj = this.XFields
        let searchResult = XFieldsObj.unknownXFields.indexOf(XField)
        if (searchResult > -1) {
          XFieldsObj.failedXFields.push(XField)
          XFieldsObj.failedXFields = _.uniq(XFieldsObj.failedXFields);
          XFieldsObj.unknownXFields.splice(searchResult, 1)
          localStorage.setItem("XFields", JSON.stringify(XFieldsObj))
        }
      }

      markXFieldAsSuccessful(XField) {
        let XFieldsObj = this.XFields
        let hasFoundTransaction = false;
        let searchResult = XFieldsObj.unknownXFields.indexOf(XField)
        //if we found the transaction in the unknownXFields
        if (searchResult > -1) {
          XFieldsObj.unknownXFields.splice(searchResult, 1)
          hasFoundTransaction = true;
        }

        let searchResultFailed = XFieldsObj.failedXFields.indexOf(XField)
        //if we found the transaction in the failedXFields
        if (searchResultFailed > -1) {
          XFieldsObj.failedXFields.splice(searchResultFailed, 1)
          hasFoundTransaction = true;
        }

        //if we found the transaction either of the arrays we update
        if (hasFoundTransaction) {
          XFieldsObj.successfullXFields.push(XField)
          localStorage.setItem("XFields", JSON.stringify(XFieldsObj))
          localStorage.setItem("lastXField", null)
          this.updateXFieldSequencesAfterSuccessfullTransactionIfNeeded(true);
        }
      }

      moveFailedXFieldToSuccessfullAfterRecovery(XField) {
        let XFieldsObj = this.XFields
        let searchResult = XFieldsObj.failedXFields.indexOf(XField)
        if (searchResult > -1) {
          XFieldsObj.successfullXFields.push(XField)
          XFieldsObj.failedXFields.splice(searchResult, 1)

          localStorage.setItem("XFields", JSON.stringify(XFieldsObj))
          this.updateXFieldSequencesAfterSuccessfullTransactionIfNeeded(true);
        }
      }

      removeXFieldFromSuccessfull(XField) {
        let XFieldsObj = this.XFields
        if (XFieldsObj != null) {
          let searchResult = XFieldsObj.successfullXFields.indexOf(XField)
          if (searchResult > -1) {
            XFieldsObj.successfullXFields.splice(searchResult, 1)
            localStorage.setItem("XFields", JSON.stringify(XFieldsObj))
          }
        }
      }

      saveCancelCall(uid: string, xField: string) {
        let cancelRequests;
        if (!posUtils.isNullOrUndefinedOrEmptyString(localStorage.getItem("cancelRequests"))) {
          cancelRequests = JSON.parse(localStorage.getItem('cancelRequests'))
        } else {
          cancelRequests = {};
        }
        if (!cancelRequests[uid]) {
          cancelRequests[uid] = []
        }
        cancelRequests[uid].push(xField);
        localStorage.setItem('cancelRequests', JSON.stringify(cancelRequests));
      }

      async getCancelCallByUidFromPinpad(uid) {
        if (posUtils.isNullOrUndefinedOrEmptyString(localStorage.getItem("cancelRequests"))) {
          return null;
        }
        let cancelRequests = JSON.parse(localStorage.getItem("cancelRequests"));
        if (!posUtils.isNullOrUndefinedOrEmptyString(cancelRequests[uid])) {
          for (let i = 0; i < cancelRequests[uid].length; i++) {
            let queryRequest = this.createTransactionQueryRequest(cancelRequests[uid][i]);
            let queryResponse = await this.pinpad.sendRequestToPinpad(queryRequest, PositiveShared.EmvRequestType.EMV, i18next.t('emv.queryTransaction'));
            queryResponse = this.pinpad.parseResult(queryResponse);
            if (queryResponse.ResultCode == "0") {  //Transaction was found
              this.logger.log(queryResponse);
              return queryResponse.EMV_Output;
            }
          }
        }
        return null;
      }

      static isNegativeCharge(paymentData) {
        return this.getInstance().isNegativeCharge(paymentData);
      }

      static async getCardDetails(amount, paymentType) {
        try {
          let ParmaterJ = '2';
          let extraRequestTagData = {}
          extraRequestTagData["AllowCardInsideAfter"] = 1;
          let response = await EMV.GetCardDetails(amount, Service.EMV.PINPAD_DEAL, paymentType, 1, null, "1", null, null, ParmaterJ, extraRequestTagData);
          return response;
        } catch (err) {
          console.error(err)
          return Promise.reject(err);
        }

      }
      static checkCardAvailableNumOfPayments(response) {
        let availableMinNumOfPayments = 1;
        let availableMaxNumOfPayments = 1;
        try {
          let creditTerm = response.result?.PossibleCreditTerms?.CreditTerm
          if (creditTerm.length > 0) {
            response.result.PossibleCreditTerms.CreditTerm.forEach(creditPayments => {
              if (creditPayments["@Avail"] == "True" && creditPayments["@Code"] == "8") {
                availableMinNumOfPayments = Number(creditPayments["@MinPayments"]);
                availableMaxNumOfPayments = Number(creditPayments["@MaxPayments"]);
              }
            })
          }
          return { availableMinNumOfPayments, availableMaxNumOfPayments }
        } catch (err) {
          console.log(err)
          return { availableMinNumOfPayments, availableMaxNumOfPayments }
        }
      }
      static async extractCreditCardExtraTagData(response) {
        try {
          let extraRequestTagData = {};
          extraRequestTagData["Pan"] = response.result.Pan
          extraRequestTagData["PanEntryMode"] = response.result.PanEntryMode;
          extraRequestTagData["ContinueJ2"] = 1;
          extraRequestTagData["AllowCardInsideBefore"] = 1;
          return extraRequestTagData;
        } catch (err) {
          console.error(err)
          return Promise.reject(err);
        }
      }

      verifyEmvOutput(output: PositiveShared.EmvOutput, input: PositiveShared.EmvRequest, originalPayment?: PositiveShared.EmvOutput): { hasError: boolean, errorMessage: string, isCancellationError?: boolean } {
        return super.verifyEmvOutput(output, input, originalPayment);
      }

      generateXField(transactionType: string, mtiType: string, amount: string = null): string {
        if (this.configurations.preventDuplicateTransactionWithXFields) {
          let xFieldSequences = this.getXFieldSequencesFromLocalStorage();


          let amoundPadded = (String(amount) || '').substr(-7).padStart(7, '0');
          let paymentNumberPadded = String(xFieldSequences.XFieldPaymentSequenceNumber).substr(-2).padStart(2, '0');
          let xFieldSequenceNumber = String(xFieldSequences.XFieldSequenceNumber).substr(-5).padStart(5, '0');
          let posNumber = String(session.pos.posNumber).substr(-3).padStart(3, '0');

          return `${amoundPadded}${paymentNumberPadded}${xFieldSequenceNumber}${posNumber}`;
        }


        return super.generateXField(transactionType, mtiType, amount);
      }

      parseXField(xField: string): any {
        return {
          amount: xField.substr(0, 7),
          paymentNumber: xField.substr(7, 2),
          xFieldSequenceNumber: xField.substr(9, 5),
          posNumber: xField.substr(14, 3)
        };
      }

      isDataXField(xFieldData: any, EmvOutput: PositiveShared.EmvOutput): boolean {
        if (parseInt(xFieldData.amount) == parseInt(EmvOutput.Amount) &&
          parseInt(xFieldData.posNumber) == session.pos.posNumber) {
          return true;
        }

        return false;
      }

      updateXFieldSequencesAfterSuccessfullTransactionIfNeeded(isPayment = false) {
        if (this.configurations.preventDuplicateTransactionWithXFields) {
          let xFieldKey = isPayment ? 'XFieldPaymentSequenceNumber' : 'XFieldSequenceNumber';
          let max = isPayment ? 100 : 100000;
          let currentXFieldPaymentsSequenceNumber = parseInt(localStorage.getItem(xFieldKey) || '0');
          localStorage.setItem(xFieldKey, String((currentXFieldPaymentsSequenceNumber + 1) % max));

          if (!isPayment) {
            localStorage.setItem('XFieldPaymentSequenceNumber', '0');
          }
        }
      }

      async initXFieldSequencesIfNeeded() {
        if (this.configurations.preventDuplicateTransactionWithXFields && posUtils.isBlank(localStorage.getItem('XFieldSequenceNumber'))) {

          let allSales = await appDB.sales.where('invoiceSequence').above(-1).desc().sortBy('timestamp');

          salesloop: for (let sale of allSales) {
            for (let salePayment of sale.payments) {
              if (salePayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT && !posUtils.isBlank(salePayment.data)) {
                let creditCardData = JSON.parse(salePayment.data);

                if (creditCardData.length > 0 && creditCardData[0].Xfield) {

                  let parsedXField = this.parseXField(creditCardData[0].Xfield);

                  if (this.isDataXField(parsedXField, creditCardData[0])) {
                    localStorage.setItem('XFieldSequenceNumber', String(parseInt(parsedXField.xFieldSequenceNumber) + 1));
                    localStorage.setItem('XFieldPaymentSequenceNumber', '0');
                    return;
                  } else {
                    break salesloop;
                  }
                }
              }
            }
          }

          localStorage.setItem('XFieldSequenceNumber', '0');
          localStorage.setItem('XFieldPaymentSequenceNumber', '0');
        }
      }


      async initRecoveryProccessIfNeeded() {
        let lastXField = localStorage.getItem("lastXField")
        if (posUtils.isNullOrUndefinedOrEmptyString(lastXField)) {
          return;
        }
        // We init the recovery proccess if we have a lastXField and a sale
        let sale = await appDB.sales.where('invoiceSequence').equals(-1).first();
        if (posUtils.isNullOrUndefinedOrEmptyString(sale)) {
          return;
        } else {
          app.showLoadingMessageDialog(i18next.t("emv.checkingForSaleInPinpad"));
          await posVC.fetchSale(sale);
          const paymentsTotal = posVC.salePayments.reduce((acc, payment) => acc + payment.amount, 0);
          const itemsTotal = posVC.saleItems.reduce((acc, item) => acc + item.priceNetoAfterDiscounts, 0);


          if (paymentsTotal < itemsTotal) {
            let transaction = await this.findTransactionInPinpad(lastXField);
            app.hideLoadingMessageDialog();
            let amount = transaction.transaction.Amount / 100; // price recieved is in agurot
            const isCreditTransaction = transaction.transaction.TranType == "53" && itemsTotal < 0;
            const isNormalSale = transaction.transaction.TranType == "1" && itemsTotal > 0;
            if (transaction.success && (isCreditTransaction || isNormalSale)) {
              let paymentCurrency = null;
              if (Service.MultiCurr.getInstance().isMultiCurr()) {
                paymentCurrency = transaction.transaction?.Currency ? PositiveShared.MultiCurrencyService.getCurrencyFromEmvCode(transaction.transaction.Currency) : Service.MultiCurr.getInstance().getPosCurrency();
              } else {
                paymentCurrency = Service.MultiCurr.getInstance().getPosCurrency()
              }
              let dialogAction = await app.showAlertDialog({
                header: i18next.t('emv.pinpadRecovery.title'),
                content: i18next.t("emv.pinpadRecovery.content",
                  { amount: amount, currency: Service.MultiCurr.getInstance().getCurrencySign(paymentCurrency) }),
                continueButtonText: i18next.t("ok"),
                cancelButtonText: i18next.t("cancel"),
              })
              if (dialogAction === 'continue') {
                console.log("Recovery process started for payment of amount: ", amount, " in currency: ", paymentCurrency, " with xField: ", lastXField)
                let fullAmoundInPosCurrency;
                if (Service.MultiCurr.getInstance().isMultiCurr()) {
                  Pinia.globalStore.setPaymentCurrency(paymentCurrency);
                  fullAmoundInPosCurrency = Service.MultiCurr.getInstance().translateAmount(amount, paymentCurrency);
                } else {
                  fullAmoundInPosCurrency = amount;
                }
                await Service.CreditCardPayment.savePayment({
                  salePayment: Service.CreditCardPayment.getSalePayment(),
                  cardDetails: transaction.transaction
                }, amount, paymentCurrency, fullAmoundInPosCurrency);
                let savePaymentResult = await app.showAlertDialog({
                  header: i18next.t('emv.pinpadRecovery.title'),
                  content: i18next.t("emv.pinpadRecovery.confirmContent"),
                  continueButtonText: i18next.t("ok"),
                })
                if (savePaymentResult === 'continue') {
                  pNavigator.pushPage('pos', i18next.t('homepage.pos'), null, null);
                  await posVC.goToPaymentScreen()
                  await PositiveTS.VueInstance.$refs.posPaymentDialog.selectPaymentMethod('credit')
                }
                this.markXFieldAsSuccessful(lastXField);
                localStorage.setItem("lastXField", null)
                return;
              }
            }
          }
        }

        app.hideLoadingMessageDialog();
        localStorage.setItem("lastXField", null)
      }


      getXFieldSequencesFromLocalStorage() {
        return {
          XFieldSequenceNumber: parseInt(localStorage.getItem('XFieldSequenceNumber') || '0'),
          XFieldPaymentSequenceNumber: parseInt(localStorage.getItem('XFieldPaymentSequenceNumber') || '0'),
        }
      }
    }
  }
}
