const ctx: Worker = self as any; //Enables working with typescript and webworkers

onmessage = function(e) {
    console.log('Received' + e.data);
    switch (e.data.method) {
      case PositiveTS.Shared.Constants.Messaging.InitQueues:
        PositiveTS.QueueWorker.Utils.initQueues(e.data.payload.token,e.data.payload.dbVersion, e.data.payload.isOnline);
        break;
      case PositiveTS.Shared.Constants.Messaging.OnlineStatusChanged:
        PositiveTS.QueueWorker.Utils.isOnline = e.data.payload;
        break;
      default:
        console.warn(`unsupported message recieved: ${e.data.method}`)
    }
    
  }
module PositiveTS {
  export module QueueWorker {
    export module Messaging {
      export function postNotification(type:string, payload:any) {
        ctx.postMessage({type: type, payload: payload});
      }
    }
  }
}

