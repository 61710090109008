module PositiveTS {
  export class Utils {
    didInstanceCheck
    constructor() {
      this.didInstanceCheck = false
    }
    checkIfOtherTabsAreOpen():Promise<boolean> {
      return new Promise((resolve,reject) => {
        if ('BroadcastChannel' in self) {
          // BroadcastChannel API supported!
          const channel = new BroadcastChannel('positive_tabs_bus');
          channel.postMessage('ping');
          channel.onmessage = function(e) {

            if (location.origin == e.origin) {
              isOtherInstanceAlreadyOpen = true;
              console.debug('2 windows are open at: ' + location.origin);
            }

            console.debug('Received', e.data);
            if (e.data == "ping" && location.origin == e.origin) {
              channel.postMessage('pong');
            }
            if (e.data == "pong" && location.origin == e.origin && !this.didInstanceCheck) {
              console.error('other instance is already open!!!')
              resolve(true)
            }
            // channel.close();
          };

          setTimeout(() => {
            // channel.close();
            this.didInstanceCheck = true;
            resolve(false)
          },1000)


        }
        else {
          resolve(false);
        }
      })
    }

    //used to run code in eruda or vorlon
    //usage: 
    //posUtils.runAsync("window.mySales = await appDB.sales.toArray()")
    //console.log(window.mySales) //will print the sales array
    //
    //Another example (mulitple lines with backticks):
    // posUtils.runAsync(`
    //    let sales = await appDB.sales.toArray(); 
    //    let sale = sales[0]; 
    //    sale.invoiceSequnce += 1000;
    //    console.log(sale.invoiceSequence);
    //    await appDB.sales.put(sale);
    // `)
    runAsync(code) {
      return (async () => {
        try {
            return await eval(`(async () => { ${code} })()`);
        } catch (error) {
            console.error('Error during async eval:', error);
        }
      })();
    }

    isTouchDevice() {
      return ( 'ontouchstart' in window ) ||( navigator.maxTouchPoints > 0 ) ||( navigator.msMaxTouchPoints > 0 );
    }

    getIlDateAsDict(strDate) {
      let date:any = {}
      let dateParts = strDate.split("/");
      date.year = "20" + dateParts[2];
      date.month = (dateParts[1] - 1);
      date.day = dateParts[0];
      return date;
    }

    parseIlDate(strDate):Date {
      if (!strDate) {
        throw new Error("Invalid Date");
      }
      let dateParts = strDate.split("/");
      if (dateParts.length !== 3) {
        return new Date(strDate);
      }

      let year = dateParts[2];
      if (year.length === 2) {
        year = "20" + year;
      }
      let date = new Date(year, (dateParts[1] - 1), dateParts[0]);
      return date;
    }

    verifyBarcodeChecksum(barcode:string, checkLength = null) {

      if (checkLength != null && barcode.length != checkLength) {
        return false;
      }

      let multiplier = (barcode.length % 2 === 0) ? 3 : 1
      let sum = 0
      let barcodeToCheck = barcode.substr(0,barcode.length-1)
      for (let char of barcodeToCheck) {
        let digit = Number(char)
        if (isNaN(digit)) {
          return false; //barcode given is not a real barcode
        }
        sum += digit * multiplier
        multiplier = (multiplier === 1) ? 3 : 1
      }
      let remaining = sum%10
      return String(remaining === 0 ? 0 : 10-remaining) === barcode[barcode.length-1]
    }

    mapPromises(values, func) {
      var promises = [];
      $.each(values, function(index, value) {
        promises.push(func(value));
      });
      return Promise.all(promises);
    }
    isNullOrUndefined(value:any):boolean {
      if (typeof (value) === 'undefined') {
        return true;
      }
      if (value === null) {
        return true;
      }
      return false;
    }
    addZeroLeadingToString(str, length) {
      if (str.length >= length) {
        return str;
      }

      while (str.length < length) {
        str = "0" + str;
      }

      return str;
    }
    isBlank(value:any):boolean {
      return this.isNullOrUndefinedOrEmptyString(value);
    }
    isBlankLikeRails (obj) {
      let cache

      if((cache = typeof obj) !== 'boolean' && (cache !== 'number' || isNaN(obj)) && !obj){
          return true
      }

      if(cache == 'string' && obj.replace(/\s/g, '').length === 0){
          return true
      }

      if(cache == 'object') {
          if((cache = toString.call(obj)) == '[object Array]' && obj.length === 0){
              return true;
          }

          if(cache == '[object Object]') {
              for(cache in obj) {
                  return false
              }
              return true
          }
      }

      return false
    }
    isPresent(value:any):boolean {
      return !this.isBlankLikeRails(value);
    }
    isNumeric(value: any): boolean {
      const type = typeof value
      return (type === 'number' || type === 'string') && !isNaN(value - parseFloat(value))
    }
    isString(value: any) {
      return typeof value === 'string' || value instanceof String
    }
    isNullOrUndefinedOrEmptyString(value:any):boolean {
      return this.isNullOrUndefined(value) || String(value).trim() == '';
    }
    isMagneticCardValid(value:any):boolean {
      return /^[A-Z0-9]+$/i.test(`${value}`)
    }
    isDefined(value) {
      return !(posUtils.isNullOrUndefined(value));
    }
    isSameArrays(arr1, arr2) {
      return $(arr1).not(arr2).length == 0 && $(arr2).not(arr1).length == 0;
    }
    logTime(operationName, type, printStack?) {
      //TODO (SR): add debug flag check here
      if (type === 'start') {
        if (printStack) {
          posUtils.logStackTrace();
        }
        console.time(operationName);
      }
      if (type === 'end') {
        console.timeEnd(operationName);
      }
    }
    private logStackTrace() {
      var getStackTrace = function() {
        var err:Error = new Error();
        return err.message;
      };

      console.debug(getStackTrace());
    }
    genericCatchHandler(e) {
      if (e && e.stack && "message" in e) {
        console.error(e.message);
        console.error(e.stack);
      } else {
        console.log(e);
      }
    }
    ignoreEvent(event) {
      event.preventDefault();
      return;
    }
    urlExists(url) {
      return PositiveTS.Service.Ajax.promiseJqAjax(url, null, "HEAD");
    }
    isChetPei(hetPei) {
      var i = parseInt(hetPei);
      return (i >= 510000000 && i <= 589999999);
    }

    validateBirthDateString = function(s) {
      var sAry = s.split("/");
      if (sAry.length < 3) {
        return false;
      }
      if ( !moment( s, 'DD/MM/YYYY').isValid() ) {
        return false;
      }

      return true;
    };

    validateTz(str) {
      //INPUT VALIDATION

      // Just in case -> convert to string
      var IDnum = String(str);

      if (/^0+$/.test(IDnum)){
        return false;
      }

      // Validate correct input
      if ((IDnum.length > 9) || (IDnum.length < 2))
        return false;
      if (isNaN(parseInt(IDnum)))
        return false;

      // The number is too short - add leading 0000
      if (IDnum.length < 9) {
        while (IDnum.length < 9) {
          IDnum = '0' + IDnum;
        }
      }

      // CHECK THE ID NUMBER
      var mone = 0, incNum;
      for (var i = 0; i < 9; i++) {
        incNum = Number(IDnum.charAt(i));
        incNum *= (i % 2) + 1;
        if (incNum > 9)
          incNum -= 9;
        mone += incNum;
      }
      if (mone % 10 === 0) {
        return true;
      }
      else {
        return false;
      }
    }
    null2empty(str) {
      if (str) {
        return str;
      } else {
        return '';
      }
    }
    isDateValid(year, month, day) {
      var date = new Date(year, month, day);

      return date.getFullYear() === parseInt(year) &&
      date.getMonth() === parseInt(month) &&
      date.getDate() === parseInt(day)
    }
    getDateStr(saleDate:string, short = false) {
      if (saleDate == null) {
        return "";
      }

      let format = "DD/MM/YYYY HH:mm"
      if(short) {
        format = "DD/MM/YY HH:mm"
      }

      if (saleDate.length == 19) {
        //old format
        return moment(saleDate,"DD/MM/YYYY HH:mm:SS").format(format)
      }
      else {
        return moment(new Date(saleDate)).format(format)
      }
    }


    getMoment(saleDate) {
      if (saleDate.length == 19) {
        //old format
        return moment(saleDate,"DD/MM/YYYY")
      }
      else {
        return moment(new Date(saleDate))
      }
    }
    async clearMinusOneSales(background = false) {
      await Pinia.elalStore.cancelPNRItems();
      await appDB.sales.where('invoiceSequence').equals(-1).delete();
      if(!background){
        await app.promiseShowAlert({
          header: "",
          content: "מכירות נוקו",
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
        });

        if (jsonConfig.getVal(jsonConfig.KEYS.shekuloTovBooksService) && Service.ShekuloTovBooks.checkBarcodesLength()) {
          Pinia.globalStore.handleShekuloTovBarcodes({type: "clear"})
        }

        Pinia.globalStore.cleanMultipassPendingLoadBudget();
        posUtils.refreshPos();
      }
    }

    sendBadTranLog(){
        return PositiveTS.Service.BadTranLog.uploadBadTranLog()
        .then( ()=>{
          alert("בוצע");
        })
        .catch( ()=>{
          alert("שגיאה");
        })
    }
    isJson(str) {
      try {
        var json = JSON.parse(str);
        return true;
      }
      catch (e) {
        return false;
      }
    }
    isEmptyArray(arr) {
      return ((Object.prototype.toString.call(arr) === '[object Array]') && (arr.length === 0));
    }
    isArray(arr) {
      return (Object.prototype.toString.call(arr) === '[object Array]');
    }
    toInt(i, prec=1) {
      var s = i.toString();
      var ary = s.split(".");
      var dec;
      if (ary.length === 2) {
        dec = PositiveTS.Service.StrUtils.rpad(ary[1].substr(0, prec), prec, "0");
      } else {
        dec = PositiveTS.Service.StrUtils.rpad("", prec, "0");
      }

      return parseInt(ary[0] + dec);
    }
    fromInt(i, prec = 1) {
      return i / Math.pow(10, prec);
    }

    /*	This work is licensed under Creative Commons GNU LGPL License.

      License: http://creativecommons.org/licenses/LGPL/2.1/
      Version: 0.9
      Author:  Stefan Goessner/2006
      Web:     http://goessner.net/
    */

   json2xml(o, tab = "") {
    var toXml = function(v, name, ind) {
       var xml = "";
       if (v instanceof Array) {
          for (var i=0, n=v.length; i<n; i++)
             xml += ind + toXml(v[i], name, ind+"\t") + "\n";
       }
       else if (typeof(v) == "object") {
          var hasChild = false;
          xml += ind + "<" + name;
          for (var m in v) {
             if (m.charAt(0) == "@")
                xml += " " + m.substr(1) + "=\"" + v[m].toString() + "\"";
             else
                hasChild = true;
          }
          xml += hasChild ? ">" : "/>";
          if (hasChild) {
             for (var m in v) {
                if (m == "#text")
                   xml += v[m];
                else if (m == "#cdata")
                   xml += "<![CDATA[" + v[m] + "]]>";
                else if (m.charAt(0) != "@")
                   xml += toXml(v[m], m, ind+"\t");
             }
             xml += (xml.charAt(xml.length-1)=="\n"?ind:"") + "</" + name + ">";
          }
       }
       else {
          xml += ind + "<" + name + ">" + v.toString() +  "</" + name + ">";
       }
       return xml;
    }, xml="";
    for (var m in o)
       xml += toXml(o[m], m, "");
    return tab ? xml.replace(/\t/g, tab) : xml.replace(/\t|\n/g, "");
 }

    xml2json(xml:string, tab = "") {
      let xmlDoc;
      let parsedXml = (new DOMParser()).parseFromString(xml, "text/xml");
      var X = {
        toObj: function(xml) {
            var o = {};
            if (xml.nodeType==1) {   // element node ..
              if (xml.attributes.length)   // element with attributes  ..
                  for (var i=0; i<xml.attributes.length; i++)
                    o["@"+xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue||"").toString();
              if (xml.firstChild) { // element has child nodes ..
                  var textChild=0, cdataChild=0, hasElementChild=false;
                  for (var n=xml.firstChild; n; n=n.nextSibling) {
                    if (n.nodeType==1) hasElementChild = true;
                    else if (n.nodeType==3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) textChild++; // non-whitespace text
                    else if (n.nodeType==4) cdataChild++; // cdata section node
                  }
                  if (hasElementChild) {
                    if (textChild < 2 && cdataChild < 2) { // structured element with evtl. a single text or/and cdata node ..
                        X.removeWhite(xml);
                        for (var n=xml.firstChild; n; n=n.nextSibling) {
                          if (n.nodeType == 3)  // text node
                              o["#text"] = X.escape(n.nodeValue);
                          else if (n.nodeType == 4)  // cdata node
                              o["#cdata"] = X.escape(n.nodeValue);
                          else if (o[n.nodeName]) {  // multiple occurence of element ..
                              if (o[n.nodeName] instanceof Array)
                                o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                              else
                                o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                          }
                          else  // first occurence of element..
                              o[n.nodeName] = X.toObj(n);
                        }
                    }
                    else { // mixed content
                        if (!xml.attributes.length)
                          o = X.escape(X.innerXml(xml));
                        else
                          o["#text"] = X.escape(X.innerXml(xml));
                    }
                  }
                  else if (textChild) { // pure text
                    if (!xml.attributes.length)
                        o = X.escape(X.innerXml(xml));
                    else
                        o["#text"] = X.escape(X.innerXml(xml));
                  }
                  else if (cdataChild) { // cdata
                    if (cdataChild > 1)
                        o = X.escape(X.innerXml(xml));
                    else
                        for (var n=xml.firstChild; n; n=n.nextSibling)
                          o["#cdata"] = X.escape(n.nodeValue);
                  }
              }
              if (!xml.attributes.length && !xml.firstChild) o = null;
            }
            else if (xml.nodeType==9) { // document.node
              o = X.toObj(xml.documentElement);
            }
            else
              alert("unhandled node type: " + xml.nodeType);
            return o;
        },
        toJson: function(o, name, ind) {
            var json = name ? ("\""+name+"\"") : "";
            if (o instanceof Array) {
              for (var i=0,n=o.length; i<n; i++)
                  o[i] = X.toJson(o[i], "", ind+"\t");
              json += (name?":[":"[") + (o.length > 1 ? ("\n"+ind+"\t"+o.join(",\n"+ind+"\t")+"\n"+ind) : o.join("")) + "]";
            }
            else if (o == null)
              json += (name&&":") + "null";
            else if (typeof(o) == "object") {
              var arr = [];
              for (var m in o)
                  arr[arr.length] = X.toJson(o[m], m, ind+"\t");
              json += (name?":{":"{") + (arr.length > 1 ? ("\n"+ind+"\t"+arr.join(",\n"+ind+"\t")+"\n"+ind) : arr.join("")) + "}";
            }
            else if (typeof(o) == "string")
              json += (name&&":") + "\"" + o.toString() + "\"";
            else
              json += (name&&":") + o.toString();
            return json;
        },
        innerXml: function(node) {
            var s = ""
            if ("innerHTML" in node)
              s = node.innerHTML;
            else {
              var asXml = function(n) {
                  var s = "";
                  if (n.nodeType == 1) {
                    s += "<" + n.nodeName;
                    for (var i=0; i<n.attributes.length;i++)
                        s += " " + n.attributes[i].nodeName + "=\"" + (n.attributes[i].nodeValue||"").toString() + "\"";
                    if (n.firstChild) {
                        s += ">";
                        for (var c=n.firstChild; c; c=c.nextSibling)
                          s += asXml(c);
                        s += "</"+n.nodeName+">";
                    }
                    else
                        s += "/>";
                  }
                  else if (n.nodeType == 3)
                    s += n.nodeValue;
                  else if (n.nodeType == 4)
                    s += "<![CDATA[" + n.nodeValue + "]]>";
                  return s;
              };
              for (var c=node.firstChild; c; c=c.nextSibling)
                  s += asXml(c);
            }
            return s;
        },
        escape: function(txt) {
            return txt.replace(/[\\]/g, "\\\\")
                      .replace(/[\"]/g, '\\"')
                      .replace(/[\n]/g, '\\n')
                      .replace(/[\r]/g, '\\r');
        },
        removeWhite: function(e) {
            e.normalize();
            for (var n = e.firstChild; n; ) {
              if (n.nodeType == 3) {  // text node
                  if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) { // pure whitespace text node
                    var nxt = n.nextSibling;
                    e.removeChild(n);
                    n = nxt;
                  }
                  else
                    n = n.nextSibling;
              }
              else if (n.nodeType == 1) {  // element node
                  X.removeWhite(n);
                  n = n.nextSibling;
              }
              else                      // any other node
                  n = n.nextSibling;
            }
            return e;
        }
      };
      if (parsedXml.nodeType == 9) // document node
        xmlDoc = parsedXml.documentElement;
      var json = X.toJson(X.toObj(X.removeWhite(xmlDoc)), xmlDoc.nodeName, "\t");
      return JSON.parse("{\n" + tab + (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) + "\n}");
    }

    refreshPos() {
      if (jsonConfig.getVal(jsonConfig.KEYS.isAndroidWebviewRefresh) && session.isAndroid && typeof(Android) !== "undefined" && Android.refreshWebview) {
        Android.refreshWebview();
      } else {
        window.location.reload();
      }
    }

    async oneTimeVersionsClearIfNeeded() {
      let cleaningPerformed = localStorage.getItem(`oneTimeVersionsClear`)
      if(cleaningPerformed == null) {
        localStorage.setItem(`oneTimeVersionsClear`,moment().format("X"));
        await this.clearAllOldPosVersionsCache();
      }
    }

    async clearAllOldPosVersionsCache() {
      if (caches) {
        let cacheKeys = await caches.keys();
        cacheKeys.forEach(async (ck) => { if (!ck.endsWith(`${PositiveTS.Version.appVersion}/`)) { await caches.delete(ck); } })
      }
      else {
        console.warn('caches object not found. skipping cleanup')
      }
    }

    async cleanAndroidStorageRoutine(){
      app.showLoadingMessage(i18next.t('storagemanager.performingCleaningProcess'))
      try {
        if(session.isAndroid && Number(Android.getVersion()) >= 1.48) {
          let positiveDir = "/positive"
          let writeFileResult = false
          let currentVersionWritten = localStorage.getItem(`positive-version-written-${PositiveTS.Version.appVersion}`)
          if(currentVersionWritten == null){
            let ipAddressFile = Android.ReadFile(positiveDir,"ip.txt")
            if(ipAddressFile != ""){
              ipAddressFile = ipAddressFile.substring(0,ipAddressFile.indexOf("versions/") + "versions/".length) + PositiveTS.Version.appVersion + "/"
            }
            else{
              ipAddressFile = window.location.origin + "/versions/" + PositiveTS.Version.appVersion + "/"
            }
            writeFileResult = Android.WriteFile(positiveDir,"ip.txt",ipAddressFile)

            if(writeFileResult == true){
              localStorage.setItem(`positive-version-written-${PositiveTS.Version.appVersion}`,moment().format("X"))
              await this.clearAllOldPosVersionsCache()
            }
          }
        }
      }
      finally {
        app.hideLoadingMessage()
      }
    }

    isValidIsraelPhone (value: string) {
      return /^0\d([\d]{0,1})([-]{0,1})\d{7}$/.test(value)
    }

    isValidEmail (value: string) {
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
    }

    isValidIsraelMobilePhone (value: string) {
      return /^05\d{1}([-]{0,1})\d{7}$/.test(value)
    }

    isValidStringNoSpecialChars(value: string){
      return !(/[^A-Za-zא-ת\s]+/g.test(value))
    }

    shouldBeBlackTextDependsOnBackgroud(backgroundHexColor = '') {
      let hex = backgroundHexColor;

      if (!hex) {
        return true;
      }

      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
          hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
          return true;
      }
      // invert color components
      let r = (parseInt(hex.slice(0, 2), 16)),
          g = (parseInt(hex.slice(2, 4), 16)),
          b = (parseInt(hex.slice(4, 6), 16));
      // pad each with zeros and return
      return (r*0.299 + g*0.587 + b*0.114) > 186 ? true : false;
    }
    objectFromUrlSearchParams (url: string) {
      let object = {}
      const urlParams = new URLSearchParams(unescape(decodeURI(url)))

      for(var pair of urlParams.entries()) {
        object[pair[0]] = pair[1]
      }

      return object
    }

    jsonParse(str) {
      let json = null
      try {
        json = JSON.parse(str)
      }catch{
      }

      return json
    }

    toString (value) {
      if (posUtils.isString(value)){
        return value
      }

      return ''
    }
    
    jsonParseWasmCol(json) {
      try {
        return this.jsonParse(json.replace(/\'/g, '"'))  
      } catch {
        return null; 
      }      
    }

    objectAssignExistPropertiesOnly (object, source) {
      return _.assign(object, _.pick(source, _.keys(object)))
    }

    objectToUrlQueryParams (object = {}) {
      return Object.keys(object).map((key) => {
        return key + '=' + object[key]
      }).join('&')
    }

    removeEmojisFromText (string: string){
      if (typeof string == 'string'){
        string = string.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '')
      }

      return string
    }

    isDevelopment() {
      let url = new URL(window.location.href);

      return url.port == '3000' || url.hostname == 'localhost' || url.hostname == 'lvh.me';
    }

    isStaging() {
      let hostname = (new URL(window.location.href)).hostname;
      return hostname == 'staging.valu.co.il' || hostname == 'staging.pcrs.co.il';
    }

    isControlTenant(): boolean {
      return ['9'/** SBN */, '7'/** lametayel */, '6'/** layam */].includes(session.company.tenantID);
    }

    isZolStockTenant(): boolean {
      return ['11'].includes(session.company.tenantID) && !this.isDevelopment();
    }


    async promiseSetTimeout(ms):Promise<void> {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    }

    printToLoggerAndConsole(logFileName, message) {
      Service.Filelog.log(logFileName, message)
      console.log(message)
    }

    async getSaleByInvoiceSequence (invoiceSequence) {
      let sale = null
      try{
        sale = await appDB.sales.where('invoiceSequence').equals(invoiceSequence).first()
      }catch(error){
        console.error(error)
        sale = null
      }
      return sale
    }


    static async refundCibusDups(sales:Array<PositiveTS.Storage.Entity.Sale>) {
      let totalSumToRefund = sales.reduce((sum, sale) => {
        return sum + sale.totalAmount;
      },0);

      let cibusData = JSON.parse(sales[0].payments[0].data);

      cibusData.actionType = 'cancel_withdrawal';
      cibusData.amount = -totalSumToRefund;
      cibusData.orderId = "1111"

      const origJsonData = JSON.parse(sales[0].jsondata);


      const invoice = await Utils.createSale(totalSumToRefund, true, 10, 3, cibusData,
                                      PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT);

      let sale = await appDB.sales.where({invoiceSequence: invoice}).first();
      let saleJsonData = JSON.parse(sale.jsondata);
      saleJsonData.customer = origJsonData.customer;
      sale.jsondata = JSON.stringify(saleJsonData);
      sale.soldAt = sales[0].soldAt;
      sale.timestamp = sales[0].timestamp;
      sale.formatted_timestamp = sales[0].formatted_timestamp;
      sale.createdAt = sales[0].createdAt;
      sale.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;

      appDB.sales.put(sale);
    }

    static async createSale (price:number, isRefund: boolean = false,
                            invoiceType:number = 1, paymentMethod:number = 3,
                            paymentData:any = {}, syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT) {
      let payment = new PositiveTS.Storage.Entity.SalePayment();
      posVC.sale.createdAt = PositiveTS.DateUtils.fullFormat();
      posVC.sale.totalQuantity = "1";

      let item = (await Storage.Entity.Item.searchByCode("1000"))[0]
      item.itemBarcode = { size: 'null', color: 'null', barcode: 'null'};

      let saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item.item, item.itemBarcode);
      saleItem.unitPrice = price;
      saleItem.quantity = isRefund ? -1 : 1;
      saleItem.saleID = posVC.sale.id;
      saleItem.rowNumber = posVC.getRowNumber();
      await posVC.persistNewSaleItemWithoutGroups(saleItem, true);
      payment.amount = isRefund ? -price : price;
      payment.method = paymentMethod;
      payment.saleID = posVC.sale.id;
      payment.data = JSON.stringify(paymentData);
      posVC.sale.invoiceType = invoiceType;
      posVC.sale.totalVatableAmount = payment.amount;
      posVC.sale.totalAmount = posVC.sale.totalVatableAmount;
      posVC.salePayments.push(payment);
      posVC.sale.syncStatus = syncStatus;
      await PositiveTS.Service.FullSale.posVCSale.IncrementSeqAndPersist();
      const  saleInvoiceNumber = posVC.sale.invoiceSequence;


      posVC.cleanSale();
      await posVC.loadSale();
      posVC.afterSaleLoaded();

      return saleInvoiceNumber;
    }

    keep2Digits (number: number | string): string {
      if (typeof (number) === "string") {
          number = parseFloat(number);
      }
      return number.toFixed(2);
  }

    /** Gets Chrome version
     *
     * if the browser is not based on the Chrome engine, it will return 0 */
    getChromeVersion (): number {
      let raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
      return raw ? parseInt(raw[2], 10) : 0;
    }

    supportsVueDraggable(): boolean {
      const chromeVersion = this.getChromeVersion();
      if(!session.isAndroid || chromeVersion >= 103) {
        return true;
      } else {
        return false;
      }
    }

    disabledEscape(e) {
      if (e.key == 'Escape') {
          e.preventDefault()
      }
    }

    allowOrientationChange() {
      // For now we support only the M2_MAX, if you want to support more devices just add a new condition
      if(window.navigator.userAgent.includes('M2_MAX') || window.navigator.userAgent.includes('M2__MAX')){
        return true;
      }
      return false;
    }
    ensureDecimalPointsOn2DigitsOnly(num: string | number) {
      let lengthOfNonDecimalDigits = parseInt(typeof(num) === 'string' ? num : num.toString()).toString().length;
      // The "Format" is : [Length of non-decimal digits].[2 decimal digits right-after dot]
      let value = Number(num.toString().substring(0, lengthOfNonDecimalDigits + 3));
      return value;
    }
    ceilDecimalPoint(num: number) {
      return Math.ceil(num * 100) / 100
    }
    getSecondDecimal(number: number | string) {
      const decimalString = Number(number).toString().split('.')[1];
      if (decimalString && decimalString.length >= 2) {
          return parseInt(decimalString[1]);
      }
      return 0;
    }
    isNumberBetween(number, value1, value2) {
      const min = Math.min(value1, value2);
      const max = Math.max(value1, value2);
      return number >= min && number <= max;
    }    
    escapeStringForHTML(string: string): string {
      const element = document.createElement('div');
      element.innerText = string;
      return element.innerHTML;
    }
    sanitizeHtmlDefaultOptions() {
      let allowed_attrs = ['style', 'name', 'class', 'id', 'data-*', 'ltr', 'rtl', 'dir'];
      let options = {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['b', 'div', 'input', 'img', 'i', 'span', "button"]), 
        allowedAttributes: {
          "div": allowed_attrs, "i": allowed_attrs, "b": allowed_attrs, "span": allowed_attrs,
          "button": allowed_attrs.concat(['onclick']),
          "img": allowed_attrs.concat(['src', 'alt', 'width', 'height']),
          "input": allowed_attrs.concat(['type', 'value', 'checked', 'disabled', 'readonly', 'placeholder']),
        }
      }
      return options;
    }
    sanitizeHtml(rawValue: string) {
      return sanitizeHtml(rawValue, this.sanitizeHtmlDefaultOptions());
    }
    sanitizeTableViewRow(row: Array<string | Array<string>>): Array<string| string[]> {
      
      let sanitizedRowArray = row.map(cell => {
        if (typeof cell === 'string') {
          cell = this.sanitizeHtml(cell)
        } else {
          cell[0] = this.sanitizeHtml(cell[0])
        }
        return cell
      });
      return sanitizedRowArray;
    }
  }
 }
declare var posUtils: PositiveTS.Utils;
declare var isOtherInstanceAlreadyOpen: boolean;
posUtils = new PositiveTS.Utils();
isOtherInstanceAlreadyOpen = false;
