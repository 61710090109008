module PositiveTS {
  export module Components {
    export module transmitTaxInvoiceDialog {
      export function create() {
        const component = {
          template: JST.transmitTaxInvoiceDialog(),
          data: () => ({
            resolveFunc: null,
            sale: null,
            invoiceType: "sale",
            customer: { id: "", name: "", type: "" },
            saleErrors: "",
            errors: {},
            loading: false,
            transmitResponse: null,
            isManual: true,
            isFromSearch: false,
            showConnectButton: false,
            supportedInvoiceTypes: [
              PositiveTS.Storage.Entity.Sequence.TYPE_DEBIT_INVOICE,
              PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE,
              PositiveTS.Storage.Entity.Sequence.TYPE_TAX_INV,
            ]
          }),
          methods: {
            cleanData() {
              Object.assign(this, this.$options.data());
            },
            cancel() {
              if (!this.isManual) {
                this.printInvoice(true);
              }
              return this.close("cancel");
            },
            close(resolveResult) {
              this.$el.close();
              this.resolveFunc(resolveResult);
              this.cleanData();
            },
            async connect() {
              await Pinia.componentsStore.openComponent({ componentName: "taxAuthorityConnect", args: [] })
              this.showConnectButton = false;
            },
            open(sale, isManual = true, isFromSearch = false, invoiceType = "sale") {
              this.invoiceType = invoiceType;
              this.sale = sale;
              this.isManual = isManual;
              this.isFromSearch = isFromSearch;
              if (this.invoiceType == "sale") {
                this.prepareSaleData();
              } else {
                this.prepareInvoiceData();
              }
              if (!this.$el.open) {
                this.$el.showModal();
              }
              return new Promise((resolve) => {
                this.resolveFunc = resolve
              });
            },
            prepareSaleData() {
              if (!this.supportedInvoiceTypes.includes(this.sale.invoiceType)) {
                this.saleErrors = i18next.t("transmitTaxInvoiceDialog.invoiceTypeNotSupported")
              }
              let jsondata = JSON.parse(this.sale.jsondata);
              this.customer.type = "none";
              let saleItemTotalWithoutVat = 0;
              this.sale.items.forEach((saleItem) => {
                if (!saleItem.noVat) {
                  saleItemTotalWithoutVat += saleItem.priceNetoAfterDiscounts
                }
              });
              if (saleItemTotalWithoutVat == 0) {
                this.saleErrors = i18next.t("transmitTaxInvoiceDialog.cantTransmitSaleWithoutVAT")
              }
              if (jsondata.tax_authority) {
                this.saleErrors = i18next.t("transmitTaxInvoiceDialog.saleTransmitted", { confirmation_number: jsondata.tax_authority.confirmation_number })
              }
              if (this.sale.syncStatus !== 2 && this.isManual) {
                this.saleErrors = i18next.t("transmitTaxInvoiceDialog.syncSaleToTransmit")
              }

              if (jsondata.customer) {
                if (jsondata.customer.clubName === "HAKAFA") {
                  this.customer.id = jsondata.customer.s_id_number;
                  this.customer.name = jsondata.customer.s_first_name + " " + jsondata.customer.s_last_name;
                  this.customer.type = "hakafa";
                } else {
                  this.customer.name = jsondata.customer.s_first_name + " " + jsondata.customer.s_last_name;
                  this.customer.type = "club";
                }
              }
            },
            prepareInvoiceData() {
              if (this.sale.total_vat == 0) {
                this.saleErrors = i18next.t("transmitTaxInvoiceDialog.cantTransmitSaleWithoutVAT")
              }
              this.customer.type = "hakafa";
              this.customer.id = this.sale.hakafa_customer.tz;
              this.customer.name = this.sale.dedicated_to_text;
            },
            async transmit() {
              if (!this.validateCustomer()) return;
              this.loading = true;
              try {
                const response = await this.transmitInvoice();
                this.handleTransmitResponse(response);
              } catch (error) {
                console.error(error);
              } finally {
                this.loading = false;
              }
            },
            validateCustomer() {
              const validationRules = [
                { key: 'id', message: i18next.t("transmitTaxInvoiceDialog.pleaseEnterValidId"), validator: this.isValidId },
                { key: 'name', message: i18next.t("transmitTaxInvoiceDialog.pleaseEnterValidName"), validator: value => value.trim().length > 0 }
              ];
              return this.applyValidationRules(validationRules);
            },
            applyValidationRules(rules) {
              let isValid = true;
              rules.forEach(({ key, message, validator }) => {
                const value = this.customer[key];
                if (!validator(value)) {
                  this.errors[key] = message;
                  isValid = false;
                } else {
                  delete this.errors[key];
                }
              });
              return isValid;
            },
            isValidId(value) {
              const isStringValid = typeof value === 'string' && value.trim().length > 0 && value.trim().length <= 9;
              const isNumberValid = typeof value === 'number' && value >= 0 && value <= 999999999;
              return isStringValid || isNumberValid;
            },
            async transmitInvoice() {
              return await Posimod.Service.FetchReq.makeJobRequest({
                url: "/tax_authority", method: "post", data: {
                  invoice_number: this.sale.invoiceSequence || this.sale.invoice_number,
                  customer: this.customer,
                  invoice_type: this.invoiceType,
                }
              }, true);
            },
            async handleTransmitResponse(response) {
              this.transmitResponse = response.result;
              if (response.result.success) {
                if (this.invoiceType == "sale") {
                  this.updateSaleData(response.result);
                } else {
                  this.updateInvoiceData(response.result);
                }
                this.printInvoice(!this.isManual);
                this.close();
              } else {
                // We fetch the updated sale from the queue to see if it has any errors
                if (this.sale.id && !this.isManual) {
                  let sale = await appDB.sales.get(this.sale.id);
                  this.sale.syncStatus = sale.syncStatus;
                  if (sale.syncStatus == PositiveTS.Storage.Entity.Sale.SYNC_STATUS_FAILED) {
                    this.saleErrors = i18next.t("transmitTaxInvoiceDialog.saleSyncError")
                  }
                }
              }
            },
            updateSaleData(transmitResult) {
              const jd = JSON.parse(this.sale.jsondata);
              jd.tax_authority = { confirmation_number: transmitResult.confirmation_number, customer: this.customer };
              this.sale.jsondata = JSON.stringify(jd);
              if (!this.isFromSearch) {
                Service.FullSale.persist(this.sale, this.sale.items, this.sale.payments);
              }
            },
            updateInvoiceData(transmitResult) {
              this.sale.tax_confirmation_number = transmitResult.confirmation_number;
              if (PositiveTS.VueInstance.$refs['hakafaMerakezInvoicesDialog']) {
                PositiveTS.VueInstance.$refs['hakafaMerakezInvoicesDialog'].selectedInvoice.tax_confirmation_number = transmitResult.confirmation_number;
              }
            },
            printInvoice(isSource = false) {
              if (this.invoiceType == "sale") {
                Printing.Invoice.printInvoice(this.sale, saleItemHelper.unflattenSaleItems(this.sale.items), this.sale.payments, isSource);
              } else {
                Printing.Invoice.printMerakezInvoice(this.sale)
              }
            },
            handleStringError(errorString) {
              switch (errorString) {
                case "sale_transmitted_already":
                  return i18next.t("transmitTaxInvoiceDialog.saleTransmitted");
                case "sale_not_found":
                  return i18next.t("transmitTaxInvoiceDialog.saleNotFound");
                case "login_again_bo":
                  this.showConnectButton = true;
                  return i18next.t("transmitTaxInvoiceDialog.loginAgainBo");
                case "unknown_error":
                  return i18next.t("transmitTaxInvoiceDialog.unknownError");
                default:
                  if (this.sale.syncStatus != PositiveTS.Storage.Entity.Sale.SYNC_STATUS_SYNCED_SUCCESFULLY && !this.isManual) {
                    return i18next.t("transmitTaxInvoiceDialog.saleSyncError");
                  }
                  return errorString;
              }
            },

            buildErrorMessageFromArray(errorArray) {
              const errorMessage = errorArray.map(errorItem => {
                if (errorItem.param) {
                  return i18next.t("transmitTaxInvoiceDialog.errors." + errorItem.param);
                }
                return errorItem?.message || errorItem?.msg || "";
              }).join("\n");

              return i18next.t("transmitTaxInvoiceDialog.transmitError") + "\n" + errorMessage;
            }
          },
          computed: {
            transmitResponseMessage() {
              if (this.transmitResponse === null) {
                return "";
              }

              if (this.transmitResponse.success) {
                return i18next.t("transmitTaxInvoiceDialog.transmitSuccess");
              }

              if (typeof this.transmitResponse.error === 'string') {
                return this.handleStringError(this.transmitResponse.error);
              }

              return this.buildErrorMessageFromArray(this.transmitResponse.error);
            },


          }
        }

        VueApp.component('transmit-tax-invoice-dialog', component);
      }
    }
  }
}
