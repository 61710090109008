module PositiveTS {
export module Storage {
export module Entity {
export class Sale extends IDBEntity {
	parentSaleID:string
	parentSaleCompanyID:string
	parentSaleStoreID:string
	parentSalePosDeviceID:string
	parentSalePosNumber:string
	parentSaleInvoiceSequence:string
	parentSaleInvoiceType:string
	parentSaleDelivery:string
	tenantID:string
	companyID:string
	storeID:string
	storeName:string
	storeAddress:string
	storeRegistrationNum:string
	storePhone:string
	storeFreeText:string
	posPhysicalID:string
	posDeviceID:string
	posNumber:string
	invoiceSequence:number
	invoiceType:number
	zNumber:number
	totalAmount:number
	totalDiscount:number
	totalQuantity:string
	cashierEmployeeID:string
	cashierEmployeeName:string
	createdAt:string
	discountID:string
	discountName:string
	discountPercent:string
	saleDiscountAmount:number
	saleDiscountAllowedWithOtherPromotions:boolean
	promotionCode:string
	discountApprovedByEmployeeID:string
	discountType:string
	customerSeqID:string
	customerID:string
	customerPhone:string
	customerName:string
	syncStatus:number
	syncLastMessage:string
	syncLastMessageTimestamp:string
	salespersonEmployeeID :string
	salespersonEmployeeName :string
	vat:string
	isInHold:boolean
	clubMemberPromotionType:number
	totalVatableAmount:number
	jsondata:string
	orderNumber:number
	isDelivery:boolean
	orderTime: string
	deliveryStatus: number
	deliverySyncStatus: number
	forceAllowCredit //not persisted - just for פתיחת חשבונית לזיכוי מהאפליקציה
	soldAt //not persisted - only for credit invoice
	atmTransactionId:string
	isWithdrawal:boolean
	items:Array<SaleItem>
	payments:Array<SalePayment>
	roundAmount: number
	flightLegId: number
	dalpakId: number
	openDate: Date
	syncToSyncServerFailed: boolean
	diners: number
	amountWithoutSplit: number
	dalpaksExtraDataFilled: boolean
	futureOrderId: number
	tipAmount: number
	movedFromDalpak: string
	saleRemarks: string
	formatted_timestamp: string
	private _useNewPromotions:boolean
	dalpakDiscountRemoved: boolean
	syncError: string
	upsaleOpened: boolean
	hasUpdatedGroupItems: boolean



	static SYNC_STATUS_NEW 				= -1;
	static SYNC_STATUS_WAITING_TO_BE_SENT = 1;
	static SYNC_STATUS_SYNCED_SUCCESFULLY = 2;
	static SYNC_STATUS_FAILED 			= 3;

	static ERP_SYNC_STATUS_NEW 			= 1;
	static ERP_SYNC_STATUS_TRANSMITTED 	= 2;
	static ERP_SYNC_STATUS_ERROR 			= 3;

	static NULL_INVOICE_SEQUENCE 	= -1;
	static NULL_Z_REPORT 			= -1;

	static OPEN_DELIVERY = 1;
	static ASSIGNED_DELIVERY = 2;
	static ASSIGNED_DELIVERY_CASH = 3;
	static TA_PAID = 4;
	static DELIVERED = 5;
	static CREDITED = 6;
	static CANCELED_DELIVERY = 7;

	static PRE_SPLITTED_SALE_INVOICE_SEQUENCE = -2;

	constructor() {
		let meta = {
			name: 'Sale',
			internal: true,
			fields: {
				parentSaleID: "TEXT",
				parentSaleCompanyID: "TEXT",
				parentSaleStoreID: "TEXT",
				parentSalePosDeviceID: "TEXT",
				parentSalePosNumber: "TEXT",
				parentSaleInvoiceSequence: "TEXT",
				parentSaleInvoiceType: "TEXT",
				parentSaleDelivery: "TEXT",
				tenantID: "TEXT",
				companyID: "TEXT",
				storeID: "TEXT",
				storeName: "TEXT",
				storeAddress: "TEXT",
				storeRegistrationNum: "TEXT",
				storePhone: "TEXT",
				storeFreeText: "TEXT",
				posPhysicalID: "TEXT",
				posDeviceID: "TEXT",
				posNumber: "TEXT",
				invoiceSequence: "INT",
				invoiceType: "INT",
				zNumber: "INT",
				totalAmount: "FLOAT",
				totalDiscount: "FLOAT",
				totalQuantity: "TEXT",
				cashierEmployeeID: "TEXT",
				cashierEmployeeName: "TEXT",
				createdAt: "TEXT",
				discountID: "TEXT",
				discountName: "TEXT",
				discountPercent: "TEXT",
				saleDiscountAmount: "FLOAT",
				saleDiscountAllowedWithOtherPromotions: "BOOL",
				promotionCode: "TEXT",
				discountApprovedByEmployeeID: "TEXT",
				discountType: "TEXT",
				customerSeqID: "TEXT",
				customerID: "TEXT",
				customerPhone: "TEXT",
				customerName: "TEXT",
				syncStatus: "INT",
				syncLastMessage: "TEXT",
				syncLastMessageTimestamp: "TEXT",
				salespersonEmployeeID : "TEXT",
				salespersonEmployeeName : "TEXT",
				vat: "TEXT",
				isInHold: "BOOL",
				clubMemberPromotionType: "INT",
				totalVatableAmount: "FLOAT",
				jsondata: "TEXT",
				orderNumber: "INT",
				isDelivery: "BOOL",
				orderTime: "TEXT",
				deliveryStatus: "INT",
				deliverySyncStatus: "INT",
				atmTransactionId: "TEXT",
				isWithdrawal: "BOOL",
				roundAmount: "FLOAT",
				flightLegId: "INT",
				dalpakId: "INT",
				openDate: "TEXT",
				formatted_timestamp: "TEXT",
				syncToSyncServerFailed: 'BOOL',
				diners: 'INT',
				amountWithoutSplit: "FLOAT",
				dalpaksExtraDataFilled: 'BOOL',
				upsaleOpened: 'BOOL',
				futureOrderId: 'INT',
				tipAmount: 'FLOAT',
				dalpakDiscountRemoved: "BOOL",
				saleRemarks: "TEXT",
				syncError: "TEXT",
				hasUpdatedGroupItems: "BOOL"
			},
			unique: ['tenantID','companyID','storeID','posPhysicalID','invoiceType','invoiceSequence','isInHold'],
			money: ['totalAmount', 'totalDiscount']
		}
		super(meta)
		this._useNewPromotions = null;
	}

	get useNewPromotions():boolean {
		if (this._useNewPromotions == null) {
			let jd = JSON.parse(this.jsondata);
			this._useNewPromotions = (jd == null) ? session.pos.useNewPromotions: jd.useNewPromotions || false;
		}
		return this._useNewPromotions;
	}

	static import(sale):Sale {
		let model = new Sale();
		model.importFromObject(sale);
		
		if (sale.items != null) {
			model.items = sale.items.map(item => SaleItem.import(item));
		}
		if (sale.payments != null) {
			model.payments = sale.payments.map(pay => SalePayment.import(pay));
		}
		return model;
	}

	async persist (tx = null, ignoreValidations = false) {
		
		return;
	};

	async isCurrentTimestampValid() {
		let ts = new Date().getTime()+3600*1000*24;
		let cnt = await appDB.sales.where('timestamp').above(ts).count()
	  return cnt == 0;
	};

	static cleanSaleOnHold():Promise<number> {
		return appDB.sales.filter(sale => Boolean(sale.isInHold) == true).delete();
  }

	static async fetchBySyncStatuses  (syncStatuses) {
		let sales = await appDB.sales.where('syncStatus').anyOf(syncStatuses).toArray()
		return sales;
	};

	static async fetchPastSales(page?:number, perPage?:number):Promise<Sale[]> {
		if(page === undefined || perPage === undefined) {
			return   (await appDB.sales.where('invoiceSequence').above(0).sortBy('timestamp')).reverse().map(sale => Sale.import(sale))
		} else {
			return(await appDB.sales.where('invoiceSequence').above(0).sortBy('timestamp')).reverse().slice(page*perPage, (page*perPage)+perPage).map(sale => Sale.import(sale));
		}
	}

	static async fetchByInvoiceType(invoiceType:number) {
		let sales = await appDB.sales.toArray();
		return sales.filter(sale => sale.invoiceType == invoiceType);
	}

	static async getPastSalesAmount() {
		return (await appDB.sales.count()) - 1;
	}

	initJsondata() {
		let _jsondata = JSON.parse( this.jsondata);
	
		if (!_jsondata) {
			_jsondata = {};
		}
			
		_jsondata.separateItemLines = session.pos.separateItemLinesOn;
		_jsondata.useNewPromotions = Boolean(session.pos.useNewPromotions);
		
		if(jsonConfig.getVal(jsonConfig.KEYS.isSelfServiceSuperMarketPos)){
			_jsondata.selfServiceSupermarketSale = true
		}
		if(jsonConfig.getVal(jsonConfig.KEYS.isIsrairPos)){
			let flight: Storage.Entity.Flight = Pinia.flightsStore.currentFlight
			if (flight) {
				_jsondata.flightPassData ={
					name: '',
					date: flight.take_off_time,
					flightNumber: flight.code,
				};
			} else {
				console.warn("no current flight was found in a Israir POS");
			}
		}
		Sale.initElalSaleSequenceIfNeeded(_jsondata);
			//_jsondata.useNewCustomerClubMethod = true;
		this.jsondata = JSON.stringify( _jsondata );
	}
	static initElalSaleSequenceIfNeeded(_jsondata){
		if(Pinia.elalStore.isOn && posVC.sale){
			let jd = JSON.parse(posVC.sale.jsondata);
			if(!_jsondata.elalSaleSequenceNumber && jd.elalSaleSequenceNumber > 0){
				_jsondata.elalSaleSequenceNumber = jd.elalSaleSequenceNumber
				Pinia.elalStore.createNewElalSaleSequence();
			}
		}
	}
	fetchSaleItemAndPaymentsAndChildrensBySaleIDs(saleIDs) { //TODO:re-implememnt
		var aThis = this;

		return new Promise((resolve,reject) => {

			if (saleIDs.length == 0) {
				return reject(new Error('At least 1 sale ID must be supplied'));
			}

			let salesToReturn = {};

			aThis.fetchSaleItemAndPaymentsBySaleIDs(saleIDs)
			.then((salesArray) => {
				

				let getSaleCredits = (sale:Storage.Entity.Sale, callback) => {
					sale.getSaleCredits()
					.then(saleCredits => {
						if (saleCredits.length > 0) {
							callback(null, { parentSaleID: sale.id, childrens: saleCredits });
						} else {
							callback(null, { parentSaleID: sale.id, childrens: null });
						}

					}).catch(err => {
						callback(err, null);
					});
				};

				let sales = {}
				for (let sale of salesArray) {
					sales[sale.id] = sale;
				}

				async.mapSeries(salesArray, getSaleCredits, (err, childrensAndParent) => {
					let childrensIDs = [];
					for (let i = 0; i < childrensAndParent.length; i++) {
						sales[childrensAndParent[i].parentSaleID].childrens = childrensAndParent[i].childrens;

						if (childrensAndParent[i].childrens != null) {
							for (let j = 0; j < childrensAndParent[i].childrens.length; j++) {
								childrensIDs.push(childrensAndParent[i].childrens[j].id);
							};
						}
					};

					if (childrensIDs.length > 0) {
						aThis.fetchSaleItemAndPaymentsBySaleIDs(childrensIDs)
						.then(childrens => {

							for (var childrens_index in childrens) {
								var foundFlag = false;

								for (var sales_index in sales) {

									if (foundFlag) {
										break;
									}

									for (var sale_childrens_index = 0; sale_childrens_index < sales[sales_index].childrens.length; sale_childrens_index++) {

										if (sales[sales_index].childrens[sale_childrens_index].id == childrens[childrens_index].id) {
											sales[sales_index].childrens[sale_childrens_index] = childrens[childrens_index];
											foundFlag = true;
											break;
										}
									};
								};
							};

							
							resolve(sales);
						},(err) => {
							reject(err);
						});
					} else {
						resolve(sales);
					}

				});
			}).catch(err => {
				reject(err);
			});

		})
		
	};

	async fetchSaleItemAndPaymentsBySaleIDs(saleIDs:string[]):Promise<Sale[]> {
		let sales = await appDB.sales.where('id').anyOf(saleIDs).toArray();

		//we sort the result by timestamp mainly for the use of the sales queue
		return sales.map(sale => Sale.import(sale)).sort((a,b) => a.timestamp - b.timestamp);
	};

	validateSaleStateAndTotals  (saleItems, salePayments, isFinalSaleCredit = false):Promise<any> {
		var aThis = this;
		aThis['items'] = saleItems
	  	var stateTotals = Helper.SaleHelper.calcuateSaleTotals(aThis, saleItems, salePayments);

		// For credit sales or credit receipt do not check vs database
	    // 	if (aThis.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_CREDIT_INVOICE
			// || (saleItems[0].quantity < 0 && aThis.invoiceType == PositiveTS.Storage.Entity.Sequence.TYPE_RECEIPT)) {


		var isValid = Helper.SaleHelper.validateSaleTotals(stateTotals, isFinalSaleCredit);

		if (isValid.valid === true) {
			return Promise.resolve(stateTotals);
		} else {
			return Promise.reject(i18next.t(isValid.message));
		}


	};

	async  addRoundAmountToSale(roundedAmount:number) {
		try {
			this.roundAmount  = session.fixedFloat(this.roundAmount + roundedAmount);
			await appDB.sales.put(this);
		} catch (err) {
			this.roundAmount = 0;
			throw err
		}
	};

	static async updateCashierToOpenSale(employeeId, employeeName) {
		try {
			let currentSale = await appDB.sales.where({invoiceSequence:-1}).first();
			if(posUtils.isNullOrUndefinedOrEmptyString(currentSale) ) {
				return ;
			}
			await appDB.sales.update(currentSale.id, { cashierEmployeeID: employeeId, cashierEmployeeName: employeeName});
		} catch (err) {
			console.error(err);
		}
	}

	preCloseSaleValidation(saleItems:SaleItem[], salePayments:SalePayment[], sequence:Sequence) {
		let aThis = this;

		if (saleItems === undefined || saleItems === null || saleItems.length === 0 || salePayments === undefined || salePayments === null ) {
			throw new Error(i18next.t('noSaleItems'));
		}

		saleItems.forEach(item => { 
			if (item.saleID !== aThis.id) {
				throw new Error("Critical error - Items not matching current sale"); 
			}
		})
		salePayments.forEach(pay => { 
			if (pay.saleID !== aThis.id) { 
				throw new Error("Critical error - Payments not matching current sale"); 
			}
		})

		//check if amount in credit card data equals the amount on the payment
		if(sequence.type == Storage.Entity.Sequence.TYPE_DEBIT_INVOICE || sequence.type == Storage.Entity.Sequence.TYPE_RECEIPT) {
			let creditCardPayments = salePayments.filter(payment => payment.method == 1);
			if(creditCardPayments.length > 0) {
				let creditCardPaymentsAmount = creditCardPayments.reduce((total, current) => session.fixedFloat(total + current.amount, 2), 0);
				if(!session.pos.isEmv && creditCardPaymentsAmount < 0) {
					return ;
				}
				let creditCardPaymentsDataAmount = creditCardPayments.reduce((total, payment) => {
					let paymentData = JSON.parse(payment.data);
					let totalPaymentDataTotals = paymentData.reduce((paymentTotal, data) => {
						let dataAmount;
						if (session.pos.isEmv) {
							dataAmount = session.fixedFloat(data.Amount, 2)/100;

							if (PositiveTS.Service.MultiCurr.getInstance().isMultiCurr() && data.creditCurrencyFullNumber){
								dataAmount = data.creditLeadCurrencyAmount;
								return paymentTotal + dataAmount;
							}
						} 
						else {
							dataAmount = data.amount;
						}
						return session.fixedFloat(paymentTotal + dataAmount, 2);
					},0);
					return session.fixedFloat(total + totalPaymentDataTotals, 2);
				}, 0);
				if(Math.abs(creditCardPaymentsAmount) != Math.abs(creditCardPaymentsDataAmount)) {
					throw new Error(i18next.t("closeSaleCreditCardAmountsError"));
				}
			}
		}
	}

	async closeSale(sequence:Sequence, saleItems:SaleItem[], salePayments:SalePayment[], isFinalSaleCredit = false, markToBeSent = true):Promise<Sale> {
		let aThis = this;

		this.preCloseSaleValidation(saleItems,salePayments, sequence);

		try {
			aThis.invoiceType 				= sequence.type;
			let totals = await aThis.validateSaleStateAndTotals(saleItems, salePayments, isFinalSaleCredit)


			//this is the final place we touch sale items and payments - they are not to be changed from now on...
			await appDB.transaction("rw",appDB.sales,appDB.sequences,appDB.localItems,async () => {
				let promises = []
				aThis.invoiceSequence			= sequence.sequence;
				aThis.invoiceType 				= sequence.type;
	
				let _jsondata = JSON.parse( aThis.jsondata);
	
				if (!_jsondata) { _jsondata = {}; }
				_jsondata.separateItemLines = session.pos.separateItemLinesOn;
				_jsondata.useNewCustomerClubMethod = true;
				if (Service.Pickup.hasItemsPickupOnSale(saleItems)){
					_jsondata.pickup = Service.Pickup.getPickupObject(_jsondata, sequence.sequence);
				}
				aThis.jsondata = JSON.stringify( _jsondata );
				aThis.totalAmount   		 = totals.totalAmount;
				aThis.totalVatableAmount = Boolean(_jsondata.isSplitSale) && aThis.totalVatableAmount ? aThis.totalVatableAmount : totals.totalVatableAmount
				aThis.totalDiscount 		 = totals.totalDiscount;
				aThis.totalQuantity 		 = totals.totalQuantity;
				aThis.tipAmount 		 = totals.tipAmount;
				aThis.saleDiscountAmount = totals.totalSaleDiscount;
				aThis.amountWithoutSplit = Service.SplitSalePayment.calculateAmountWintoutSplit(aThis, saleItems, totals);
				if (markToBeSent) {
					aThis.syncStatus = PositiveTS.Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
					aThis.syncLastMessage			= 'Sale added to queue successfully';
					aThis.syncLastMessageTimestamp	= PositiveTS.DateUtils.fullFormat();
				}

				if (session.pos.hasFlights && Pinia.flightsStore.leg) {
					aThis.flightLegId = Pinia.flightsStore.leg.id;
				}
				
				promises.push(Service.FullSale.persist(aThis,saleItems,salePayments), false)
				promises.push(Service.FullSale.updateInventory(aThis,saleItems))
				promises.push(appDB.sequences.put(sequence))
				await Promise.all(promises)
			})

			return aThis;
			
		} 
		catch(err) {

			if (err) {
				Service.Logger.error(err);
			}
					
			throw new Error(i18next.t("failedToCloseSale"));	
		}
	
	};

	async getSaleCredits() {

		let aThis = this;
		return (await (appDB.sales
		.filter(sale => sale.parentSaleInvoiceSequence == aThis.invoiceSequence.toString() && sale.parentSalePosDeviceID == aThis.posDeviceID)
		.toArray()))
		.map(sale => Sale.import(sale));
	};

	async getMaxSaleTimestamp (){
		let result = await appDB.sales.where('timestamp').above(0).last();
		return result.timestamp;
	}

	get hasDiscount() {
		return PositiveTS.Helper.SaleHelper.doesSaleHasDiscount(this)
	}

	get hasPromotion() {
		return PositiveTS.Helper.SaleHelper.doesSaleHasPromotion(this)
	}
	
}}}}
