module PositiveTS {
  export module Shared {
    export module Constants {
      export const remoteRoot = '/';
      export const DeliveriesRemote = 'sales/set_delivery_status';
      export const ItemsRemote = 'pos_items';
      export const EmployeesRemote = 'pos_employees';
      export const EmployeeHoursRemote = 'pos_employee_hours';
      export const DepartmentsRemote = 'pos_departments';
      export const SuspiciousActivityRemote = 'pos_suspicious_activity_logs';
      export const PromotionsRemote = 'pos_promotions';

      export module Item {
        export const GENERIC_ITEM = "1000"
      }

      export module Employee {
        export const GENERIC_EMPLOYEE = "1000"
      }

      export module Sale {
        export const  SYNC_STATUS_NEW 				= -1;
        export const  SYNC_STATUS_WAITING_TO_BE_SENT = 1;
        export const  SYNC_STATUS_SYNCED_SUCCESFULLY = 2;
        export const  SYNC_STATUS_FAILED 			= 3;
        export const  NULL_INVOICE_SEQUENCE 	= -1;
        export const  NULL_Z_REPORT 			= -1;
      }
      export module Sequence {
        export const  TYPE_DEBIT_INVOICE		= 1;
        export const  TYPE_CREDIT_INVOICE	= 2;
        export const  TYPE_CREDIT_VOUCHER	= 3;
        export const  TYPE_Z_REPORT			= 4;
        export const  TYPE_RECEIPT			= 5;
        export const  TYPE_Z_MASTER_REPORT			= 6;
        export const  TYPE_TAX_INV = 7;
        export const  TYPE_TAX_CREDIT_INV = 8;
        export const  TYPE_SHIPMENT_INV = 9;
        export const  TYPE_CREDIT_SHIPMENT_INV = 10;
        export const  TYPE_PROFORMA = 20;
        export const  TYPE_PUNCH_CARD_INVOICE = 21;
      }

      export module Messaging {
        export const OfflineSalesChanged = 'OfflineSalesChanged';
        export const InitQueues = 'SalesQueue.init'
        export const OnlineStatusChanged = 'OnlineStatusChanged'
      }

      export module SyncStatuses {
        export const SYNC_STATUS_NEW = -1
        export const SYNC_STATUS_WAITING_TO_BE_SENT = 1
        export const SYNC_STATUS_SYNCED_SUCCESFULLY = 2
        export const SYNC_STATUS_FAILED = 3
      }

      export module SuspiciousActions {
        export const OPEN_DRAWER = 1;
        export const HOLD_SALE = 2;
        export const ERASE_SALE = 3;
        export const ERASE_ROW = 4;
        export const PERFORMA_PRINTED = 5;
        export const POS_LOADED = 6;
        export const PRICE_CHANGED = 7;
        export const SALE_MINUS = 8
        export const SALE_DISCOUNT = 9;
        export const SALE_ITEM_DISCOUNT = 10;
        export const SUSPICIOUS_CLUB_MEMBER_SALE = 11;
        export const GENERAL_ITEM = 12;
        export const MULTIPLE_SAMEDAY_CUSTOMER_SALES = 13;
        export const CASH_CREDIT = 14;
        export const CARD_CREDIT = 15;
        
        export const FIRST_SALE_AFTER_Z = 16;
        export const SALE_AMOUNT_ABOVE_X = 17;
        export const NO_SALE_WAS_MADE_IN_X_MINUTES = 18;
        export const EMP_OPENED_STORE = 19;
        export const over_all_sales_past_x_amount = 20;
        export const SELF_SERVICE_IS_POS_OFFLINE = 23;
        export const SELF_SERVICE_PINPAD_CONNECTION_PROBLEM = 24;
        export const PRODUCT_EXPIRED_DUE_TO_TEMP = 25;
        export const VENDING_DOOR_OPEN = 26;
        export const VENDING_STUCK = 27;
        export const POWER_DETECTOR_FAULTY = 28;
        export const ELEVATOR_MINOR_FAULT = 29;
        export const ELEVATOR_MAJOR_FAULT = 30;
        export const TEMP_CONTROLLER_FAULTY = 31;
        export const VENDING_TEMP_ABOVE_X = 32;
        
      }

      export module CashierStatement {
        export const TYPE_START_DAY = 'enter';
        export const TYPE_END_DAY = 'Z';
        export const TYPE_MID_DAY = 'exit';
        export const TYPE_ADD_TO_POS = 'add';
        export const TYPE_REMOVE_FROM_POS = 'remove';
        export const SYNC_STATUS_AWAITING_Z = 4;
      }

      export module EmailReportType {
        export const Z_REPORT = 1;
        export const SALES_REPORT = 2;
      }

      export module MultipassPolicePoints {
        export const CLUB_CODE = "multipassPolice";
      }

    }
  }
}
