module PositiveTS {
export module Components {
    export module ItemWithGroupsDialog {

      const _defaultMenuColor = '#0061a7';
      const _defaultItemColor = '#40c3d9';
      const _title = "פריט אב";
      const refInnerName = 'itemWithGroupsInner'

      interface MenuButton {
        name:string
        id?: number
        code?: string
        isItem?: boolean
        hasPicture?: boolean
        hasDefaultPicture?: boolean
        priceZarhan: number
        level: number
        color: string
      }

      function initData(dialogSelector) {
        return {
          currentLevel: 0,
          initialzied: true,
          loading: true,
          addingInProgress: false,
          itemGroups: [],
          currentGroupIndex: 0,
          groupIndexes: [],
          rootItem: {},
          itemBeforeChanges: {},
          buttons: [{name: "טוען...",id:undefined,color:undefined}],
          deleteImg: `${(<any>window).images_path}delete-new.png`,
          giftImg: `${(<any>window).images_path}pos/gift.png`,
          title: '',
          _defaultMenuColor:_defaultMenuColor,
          _defaultItemColor:_defaultItemColor,
          dialogSelector: `#${dialogSelector}`,
          dialogSelectorId: dialogSelector,
          selectedSaleItem: null,
          isEditMode: false,
          vibratingError: null,
          toggleItemGroupsById: {},
          itemGroupsErrors: {},
          dialogClass: '',
          dialogWidth: '',
          dialogHeight: '',
          dialogStyle: {},
        }
      }

      export function create() {
         let sharedMethods = {
            hideVibratingError() {
              this.vibratingError = null;
            },
            async showVibratingError(error) {
              this.hideVibratingError();
              await this.$nextTick();

              this.vibratingError = error;
            },

            deleteRowLastChildrenByMenuButton (button: MenuButton) {
              let itemToDelete = _.findLast(this.rootItem.children, c => c.itemCode == button.code)
              if (itemToDelete){
                this.deleteRow(itemToDelete)
              }
            },

            async editPreparationInstructionsForChildItem (button: MenuButton) {
              let childrens = this.rootItem.children.filter(c => c.itemCode == button.code && c.hasPreparationInstructions)
              let number = 0
              for (let c of childrens){
                number++
                await Pinia.componentsStore.openComponent( {componentName:"prepInstructionsDialog", args: [c, number]})
              }
            },
            async editPreparationInstructionsForRootItem() {
              await Pinia.componentsStore.openComponent( {componentName:"prepInstructionsDialog", args: [this.rootItem]});
            },

            dalpakTableViewMobileItemsGroupTitle (itemGroup: Storage.Entity.ItemGroup) {
              let quantityItemsToSelected = itemGroup.maxItemsToChoose || 0
              let itemsSelectedQuantity = this.groupSaleItemsCount(itemGroup)

              return i18next.t('itemWithGroupsDialog.dalpakTableViewMobileItemsGroupTitle', {
                itemsSelectedQuantity,
                quantityItemsToSelected
              })
            },

            getImageItemGroupItem (itemGroupItem: Storage.Entity.ItemGroupItem) {
              let item = (new PositiveTS.Storage.Entity.Item()).findByIdWithoutPromise(itemGroupItem.itemId)
              return this.getItemImage(item)
            },
            
            showVibratingErrorItemGroup (itemGroup: Storage.Entity.ItemGroup, error: string){
              this.itemGroupsErrors[itemGroup.id] = error
              this.toggleItemGroupsById[itemGroup.id] = true
              
            },

            deleteVibratingErrorItemGroup (itemGroup: Storage.Entity.ItemGroup) {
              delete this.itemGroupsErrors[itemGroup.id]
            },
            
            checkItemGroupIsFinish (itemGroup: Storage.Entity.ItemGroup) {
              let error = null

              this.deleteVibratingErrorItemGroup(itemGroup)
              const itemGroupItemsChosen = this.groupSaleItemsCount(itemGroup)

              if (itemGroup.minItemsToChoose > itemGroupItemsChosen){
                let itemText = itemGroup.minItemsToChoose == 1 ? i18next.t('itemWithGroupsDialog.oneItem') : i18next.t('itemWithGroupsDialog.xItems', {count: itemGroup.minItemsToChoose})
                error = i18next.t('itemWithGroupsDialog.minimalChoiceNotComitted', {itemText})
              }

              if (error){
                this.showVibratingErrorItemGroup(itemGroup, error)
              }

              return error === null
            },

            toggleItemGroup (itemGroup: Storage.Entity.ItemGroup) {
              let result = this.checkItemGroupIsFinish(itemGroup)
              if (result){
                this.toggleItemGroupsById[itemGroup.id] = !this.toggleItemGroupsById[itemGroup.id]
              }
            },

            getImageItemByButton (button: MenuButton) {
              if (button.hasDefaultPicture){
                return this.defaultImageUrl
              } 
              
              return `/catalog-images/items/${this.tenantID}/${this.companyID}/${button.code}`
            },

            showOutOfStockMessage(button:MenuButton):boolean {
              if (jsonConfig.getVal(jsonConfig.KEYS.allowOutOfStockItems)) {
                if (this.outOfStockItemCodesByCode[button.code]) {
                  let message = jsonConfig.getVal(jsonConfig.KEYS.outOfStockItemMessage) || i18next.t('outOfStockItemsDialog.outOfStockMessage')

                    if (this.showSelfServiceAndDalpakTableView) {
                      this.showVibratingError(message);
                    } else {
                      app.showAlertDialog({
                        header: i18next.t('error'),
                        content: message,
                        continueButtonText: i18next.t("ok"),
                        hideCancelButton: true
                      }, null, null);
                    }


                  return true;
                }
              }
              return false;
            },

            async addItemFromButton(button:MenuButton, amountToAdd, autoSelectIfApplicable, autoSelectQuantity):Promise<boolean> 
            {
              let item = await new Storage.Entity.Item().findByIdNew(button.id)
              let tmpItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(item, {size:'null',color:'null',barcode: item.code});
              let itemWithInstructions = await posVC.addPrepInstructionsIfNeeded(tmpItem)
              if(itemWithInstructions.userCancelled) {
                return;
              }
              tmpItem = itemWithInstructions.item
              if (!this.isTableViewMobile) {
                tmpItem = await this.addParitTosafot(tmpItem, button, amountToAdd)
              }

              // if clicked on cancel on child item
              if (tmpItem == null) {
                return null;
              }

              await this.getQuantity(tmpItem, autoSelectIfApplicable, autoSelectQuantity)
              tmpItem.parentItemId = Number(this.rootItem.item.id)
              if(!session.pos.isCaveret) {
                tmpItem.originalUnitPrice = button.priceZarhan
              }
              tmpItem.unitPrice = button.priceZarhan
              tmpItem.level = this.rootItem.level + 1;
              tmpItem.itemGroupId = this.currentGroup.id;
              tmpItem.isDeposit = tmpItem.item.isDeposit;
              if (tmpItem.children && tmpItem.children.length > 0) { //bug fix for paid additions in prep instructions
                for (let child of tmpItem.children) {
                  child.level = tmpItem.level + 1;
                }
              }
              if(amountToAdd) {
                tmpItem.quantity = amountToAdd;
                this.rootItem.children.push(tmpItem);
              } else {
                this.rootItem.children.push(tmpItem);
              }

              if(!session.pos.isCaveret) {
                this.checkIfNeedChangePriceForFreeItems(this.currentGroup, this.currentGroupIndex)
              }
              return (tmpItem.item.disableItemGroupEditing && amountToAdd != null && tmpItem.item.isDeposit);
            },

            validateButton(button:MenuButton):boolean {
              if (!button.id) {
                return false;
              }

              this.hideVibratingError();

              if (this.maxGroupItemsSelected(this.currentGroup)) {
                if(!this.rootItem.item.disableItemGroupEditing) {
                  if (this.showSelfServiceAndDalpakTableView) {
                    this.showVibratingError(i18next.t('itemWithGroupsDialog.maximuItemsSelectedMessageShort'));
                  } else {
                    app.showErrorAlertDialog(i18next.t('itemWithGroupsDialog.maximuItemsSelectedMessage'))
                  }
                }
                return false;
              }

              if (this.showOutOfStockMessage(button)) {
                return false;
              }
              return true
            },

            tableMobileSetGroupAndValidateButton(itemGroup: Storage.Entity.ItemGroup,  button: MenuButton):boolean {
              this.currentGroupIndex = itemGroup.sortOrder
              const needToChooseOneItem = itemGroup.maxItemsToChoose === 1

              this.deleteVibratingErrorItemGroup(itemGroup);
              let maxGroupItemsSelected = this.maxGroupItemsSelected(this.currentGroup)

              if (maxGroupItemsSelected && needToChooseOneItem) {
                let itemExist = this.rootItem.children.find(item => item.itemGroupId == itemGroup.id)
                this.deleteRow(itemExist)

                if (itemExist.itemCode == button.code) {
                  return false;
                }

                maxGroupItemsSelected = false
              }

              if (maxGroupItemsSelected) {
                 
                if(!this.rootItem.item.disableItemGroupEditing) {
                  this.showVibratingErrorItemGroup(itemGroup, i18next.t('itemWithGroupsDialog.maximuItemsSelectedMessageShort'));
                }
                return false;
              }

              if (this.showOutOfStockMessage(button)) {
                return false;
              }

              return true
            },

            async tableMobileItemGroupClicked (itemGroup: Storage.Entity.ItemGroup, button: MenuButton, amountToAdd = null,
              autoSelectIfApplicable = false, autoSelectQuantity = 0) 
            {
              

              if (!this.tableMobileSetGroupAndValidateButton(itemGroup, button)) {
                return;
              }

              try {
                await this.addItemFromButton(button, amountToAdd, autoSelectIfApplicable, autoSelectQuantity);
              } catch (error) {
                console.error(error)
              }
            },



            async groupItemClicked(button:MenuButton, autoSelectIfApplicable = false, autoSelectQuantity = 0, amountToAdd = null) {
             
              if (!this.validateButton(button)) {
                return;
              }

              try {

                let canItemFinish = await this.addItemFromButton(button, amountToAdd, autoSelectIfApplicable, autoSelectQuantity);

                if (this.maxGroupItemsSelected(this.currentGroup)) {
                  this.nextGroup(this.currentGroupIndex);
                }

                let canFinish = this.canFinishGroupItemsSelectedAllGroups()
                if (canFinish || canItemFinish) {
                  this.finish();
                } else {
                  this.showDialog();
                }
              } catch(err) {
                console.error(err)
              }
            },


            cleanData() {
              let ignore = ['dialogSelector', 'dialogSelectorId']
              let result = initData(this.dialogSelector);
              for (let prop in result) {
                if (ignore.includes(prop)) {
                  continue;
                }
                this[prop] = result[prop];
              }
            },

            itemGroupItemSelectedChildrenHasGroups (itemGroup: Storage.Entity.ItemGroup, returnBoolean = false) {
              let result = null
              if (this.rootChildrenItemByGroup[itemGroup.id]){
                result = this.rootChildrenItemByGroup[itemGroup.id].find(child => {
                  return child.hasGroups
                })
              }

              return returnBoolean ? Boolean(result) : result
            },
            
            async loadGroups(saleItem:Storage.Entity.SaleItem, groupIndex = 0, amountToAdd = null) {
              try {
                let itemGroups = await PositiveTS.Service.MenuAndItemGroup.getGroupsAndItemsByItemId({ ItemId: saleItem.item.id, lang: this.currentLang });
                this.itemGroups = itemGroups;
                saleItem.children = saleItem.children || [];
                saleItem.level = saleItem.level || 0;
                this.rootItem = saleItem;
                this.itemBeforeChanges = _.cloneDeep(saleItem)
                // I do this to clear the itemBeforeChanges so I dont have nested itemBeforeChanges, 
                // the children are parsed to avoid circular reference with the parent
                this.itemBeforeChanges.children = this.itemBeforeChanges.children.map(child => {
                  child._parent = {}
                  return child
                })
                this.itemBeforeChanges.itemBeforeChanges = {}
                this.itemBeforeChanges.originalItem = {}
                this.itemBeforeChanges._parent = {}
                this.groupIndexes = itemGroups.map(ig => {return ig.sortOrder})

                this.selectGroup(itemGroups[groupIndex], groupIndex, amountToAdd, true)
              }catch (error){
                console.error(error)
              }
            },

            setLoadingState() {
              this.buttons = [{name: i18next.t('itemWithGroupsDialog.loading'),id:undefined}]
              this.loading = true;
            },
            open(saleItem, groupIndex = 0, amountToAdd = null, isEditMode = false) {
              return new Promise((resolve,reject) => {
                this.initDialogSize()
                this.$el.showModal();
                $(document).unbind('keypress');
                this.resolveFunc = resolve;
                this.rejectFunc = reject;
                this.isEditMode = isEditMode
                this.loading = true
                this.loadGroups(saleItem, groupIndex, amountToAdd)
                .then(() => {
                  this.itemGroups.forEach(itemGroup => {
                    this.toggleItemGroupsById[itemGroup.id] = true
                  })
                  this.loading = false;
                  this.setTitle()
      
                })      
              })
            },
            showDialog() {
              this.$el.showModal();
            },
            setTitle() {
              this.title = i18next.t('itemWithGroupsDialog.groupTitle', {groupName: this.currentGroup.name, itemDescription: this.rootItem.itemDescription });
            },
            async cancel() {
              let cancelAndQuit = true

              if(!this.isEditMode) {
                let result = await app.showAlertDialog({
                  header: i18next.t('message'),
                  content:  i18next.t('itemWithGroupsDialog.cancelMessage') ,
                  continueButtonText:  i18next.t('itemWithGroupsDialog.confirm'),
                  cancelButtonTetx:  i18next.t('itemWithGroupsDialog.cancel')
                });

                cancelAndQuit = result == 'continue'
              }

              if(cancelAndQuit) {
                this.resolveFunc(null)
                this.close()
              }
            },

            getItemWithGroupsInnerByItemGroup (itemGroup: Storage.Entity.ItemGroup) {
              let itemWithGroupsInner = this.$refs[`${refInnerName}${itemGroup.id}`]
              if (itemWithGroupsInner){
                itemWithGroupsInner = itemWithGroupsInner[0]
              }

              return itemWithGroupsInner
            },

            canCloseAndSaveTableMobileView () {          
              for (let group of this.itemGroups) {
        
                if (!this.canMoveToNextGroup(group)) {
                  this.checkItemGroupIsFinish(group)
                  if (this.$refs[`itemGroup${group.id}`]){
                    this.$refs[`itemGroup${group.id}`][0].scrollIntoView()
                  }
                  return false
                }else {
                  let itemWithGroupsInner = this.getItemWithGroupsInnerByItemGroup(group)
                  if (itemWithGroupsInner){
                    if (!itemWithGroupsInner.canCloseAndSaveTableMobileView()){
                      return false
                    }
                  }
                }
              }

              return true;
            },

            finishTableMobileView () {
              if(!this.canCloseAndSaveTableMobileView()) {
                return
              }
              
              if (this.rootItem.bonPrintedAt && this.rootItem.level == 0) {
                this.rootItem.originalItem = _.cloneDeep(this.itemBeforeChanges)
                let {lastPrintedItemsHashStrings} = JSON.parse(posVC.sale.jsondata)
                let itemsBeforeChangesHash = lastPrintedItemsHashStrings[this.rootItem.rowNumber]
                let itemsAfterChangesHash = this.hashItemChildrenString(this.rootItem)
                this.rootItem.hasChanges = false
                
                itemsAfterChangesHash.forEach((item, index) => {
                  this.rootItem.children[index].hasUpdated = false
                  let itemIndex = itemsBeforeChangesHash.indexOf(item)

                  if(itemIndex != -1) {
                    this.itemBeforeChanges.children.splice(itemIndex, 1)
                    itemsBeforeChangesHash.splice(itemIndex, 1)
                  }
                  else {
                    this.rootItem.children[index].hasUpdated = true
                    this.rootItem.hasChanges = true
                    posVC.sale["hasUpdatedGroupItems"] = true
                  }
                })

                if(itemsBeforeChangesHash.length > 0) {
                  this.rootItem.hasChanges = true
                  posVC.sale["hasUpdatedGroupItems"] = true
                }

                if(this.rootItem.hasChanges) {
                  this.rootItem.itemBeforeChanges = this.itemBeforeChanges
                }
              }

              if (this.isInner){
                this.$parent.finishTableMobileView()
                return
              }

              this.resolveFunc(this.rootItem)
              this.close()
            },

            async finish() {
              if(!await this.canCloseAndSave()) {
                return
              }
              if(this.rootItem.bonPrintedAt && this.rootItem.level == 0) {
                this.rootItem.originalItem = _.cloneDeep(this.itemBeforeChanges)
                let {lastPrintedItemsHashStrings} = JSON.parse(posVC.sale.jsondata)
                let itemsBeforeChangesHash = lastPrintedItemsHashStrings[this.rootItem.rowNumber]
                let itemsAfterChangesHash = this.hashItemChildrenString(this.rootItem)
                this.rootItem.hasChanges = false
                
                itemsAfterChangesHash.forEach((item, index) => {
                  this.rootItem.children[index].hasUpdated = false
                  let itemIndex = itemsBeforeChangesHash.indexOf(item)

                  if(itemIndex != -1) {
                    this.itemBeforeChanges.children.splice(itemIndex, 1)
                    itemsBeforeChangesHash.splice(itemIndex, 1)
                  }
                  else {
                    this.rootItem.children[index].hasUpdated = true
                    this.rootItem.hasChanges = true
                    posVC.sale["hasUpdatedGroupItems"] = true
                  }
                })

                if(itemsBeforeChangesHash.length > 0) {
                  this.rootItem.hasChanges = true
                  posVC.sale["hasUpdatedGroupItems"] = true
                }

                if(this.rootItem.hasChanges) {
                  this.rootItem.itemBeforeChanges = this.itemBeforeChanges
                }
              }
              this.resolveFunc(this.rootItem)
              this.close()
            },
            hashItemChildrenString(item) {
              return item.children.map(i => {
                return  `${i.itemCode}$$${i.hasPreparationInstructions ? i.selectedPreparationInstructions : null}###${i.children && i.children.length > 0 ? this.hashGrandChildren(i.children) : null }`  
              })

            },
            hashGrandChildren(childrenItems) {
              let stringToReturn = ''
              childrenItems.forEach((child,index) => {
                stringToReturn += `${child.itemCode}$$${child.hasPreparationInstructions ? child.selectedPreparationInstructions : null}${(index + 1) == childrenItems.length ? '' : '&&&'}`
              })

              return stringToReturn
            },
      
            close() {
              this.$el.close();
              $(document).unbind('keypress');
              $(document).keypress(posVC.onKeyPress);
              this.cleanData();
            },
      
            getButtonsForGroup(itemGroup:Storage.Entity.ItemGroup): Array<MenuButton> {
              return itemGroup.itemGroupItems.map((igi) => {
                return this.convertItemGroupItemToMenuButton(igi)
              })
            },

            convertItemGroupItemToMenuButton (itemGroupItem: Storage.Entity.ItemGroupItem): MenuButton {
              return {
                name: itemGroupItem.itemDesc,
                code: itemGroupItem.itemCode,
                hasPicture: itemGroupItem.hasPicture || this.defaultImageUrl,
                hasDefaultPicture: !itemGroupItem.hasPicture && this.defaultImageUrl,
                id: itemGroupItem.itemId,
                priceZarhan: itemGroupItem.priceZarhan,
                level: 1,
                isItem: true,
                color: itemGroupItem.color,
              }
            },

            showNextButton() {
              if (this.groupIndexes.length == 1) {
                return false
              }
              let index = this.groupIndexes.indexOf(this.currentGroupIndex)
              return (index > -1 && index < (this.groupIndexes.length-1))
            },
      
            showPrevButton() {
              if (this.groupIndexes.length == 1) {
                return false
              }
              let index = this.groupIndexes.indexOf(this.currentGroupIndex)
              return (index > 0)
            },
      
            nextGroup(groupIndex) {
              this.hideVibratingError();

              let index = this.groupIndexes.indexOf(this.currentGroupIndex)
              if (index < (this.itemGroups.length-1)) {
                this.selectGroup(this.itemGroups[index+1],index+1);
              }
              else {
                //TODO: close if all validations are met...
              }
            },
      
            prevGroup(groupIndex) {
              this.hideVibratingError();

              if (!this.showPrevButton()) {
                return;
              }

              let index = this.groupIndexes.indexOf(this.currentGroupIndex)
              this.selectGroup(this.itemGroups[index-1],index-1);
            },
      
            async canCloseAndSave() {
              for (let index = 0; index < this.itemGroups.length; index++) {
                let group = this.itemGroups[index]

                if (!this.canMoveToNextGroup(group)) {
                  if (this.showSelfServiceAndDalpakTableView) {
                    let itemText = group.minItemsToChoose == 1 ? i18next.t('itemWithGroupsDialog.oneItem') : i18next.t('itemWithGroupsDialog.xItems', {count: group.minItemsToChoose})
                    this.showVibratingError(i18next.t('itemWithGroupsDialog.minimalChoiceNotComitted', {itemText}));
                  } else {
                    await app.showAlertDialog({
                      header: i18next.t('error'),
                      content: i18next.t('itemWithGroupsDialog.cannotAddItemMessage', {minItemsToChoose:group.minItemsToChoose, groupName:group.name }),
                      hideCancelButton: true
                    });
                  }

                  this.selectGroup(group, index, null, true);

                  return false;
                }
              }
              return true;
            },
      
            itemSelected(saleItem: Storage.Entity.SaleItem) {
              this.selectedSaleItem = saleItem;
            },
      
            async deleteRow(itemToDelete: Storage.Entity.SaleItem) {
              this.hideVibratingError();
              
              if(posUtils.isDefined(itemToDelete.bonPrintedAt)) {
                let employee = await app.showManagerApprovalDialog([Storage.Entity.Employee.CAN_EDIT_ITEMS_WITH_BON_PRINT]);

                if(!employee || (employee && (!employee.canEditItemsWithBonPrint && !employee.isManagerDiscountConfirm)) ) {
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('itemWithGroupsDialog.employeeCannotEditItemWithBonPrint'),
                    hideCancelButton: true
                  });

                  return
                }
              }

              this.rootItem.children = this.rootItem.children.filter(item => item != itemToDelete)
              this.selectedSaleItem = null;
              this.checkIfNeedChangePriceForFreeItems(this.currentGroup, this.currentGroupIndex)
            },
            itemDescription(saleItem) {
              let itemGroup = this.itemGroups.filter((group) => {return group.id == saleItem.itemGroupId})[0];
              if (itemGroup == null) {
                return saleItem.itemDescription;
              }
              else {
                return `${itemGroup.name}: ${saleItem.itemDescription}`
              }
            },
      
            addParitTosafot(saleItem, button, amountToAdd = null) {
              return new Promise((resolve,reject) => {
                if (saleItem.hasGroups) {
                  saleItem.unitPrice = button.priceZarhan
                  saleItem.level = this.rootItem.level + 1;
                  saleItem.itemGroupId = this.currentGroup.id;
Pinia.componentsStore.openComponent( {componentName:"itemWithGroupsDialogInner", args: [saleItem, 0, amountToAdd]})
                  .then((saleItemWithChildren) => {
                    resolve(saleItemWithChildren)
                  })
                  .catch((err) => {
                    console.log(err)
                    resolve(null)
                  })
                }
                else {
                  resolve(saleItem)
                }
      
              })
            },
      
            editParitTosafot(saleItem) {
              return new Promise((resolve,reject) => {
                if (saleItem != null && saleItem.hasGroups) {
                  let originalItem = $.extend(true,{},saleItem)
Pinia.componentsStore.openComponent( {componentName:"itemWithGroupsDialogInner", args: [saleItem, 0, null, true]})
                  .then((saleItemWithChildren) => {
                    if (saleItemWithChildren == null) {
                      //restore original item...
                      saleItem = originalItem;
                      resolve()
                    }
                  })
                  $(PositiveTS.VueInstance.$refs.itemWithGroupsDialogInner.dialogSelector).dialog({
                  title: i18next.t('itemWithGroupsDialog.chooseAddon', {itemDescription:saleItem.itemDescription}),
                  position: {
                    my: "center",
                    at: "center",
                    of: window
                  }})

                }
                else {
                  resolve(null)
                }          
              })
      
            },
      
            getQuantity(saleItem:Storage.Entity.SaleItem, autoSelectIfApplicable = false, autoSelectQuantity = 0) {
              if (autoSelectIfApplicable) {
                if (saleItem.hasWeight) {
                  saleItem.quantity = autoSelectQuantity;
                  return Promise.resolve(autoSelectQuantity)  
                }
                else {
                  return posVC.getIncrementQuantity(saleItem)
                }
                
              }
              else {
                return posVC.getIncrementQuantity(saleItem)
              }
            },
      
            groupSaleItems(group) {
              return this.rootItem.children.filter((item) => { return item.itemGroupId == group.id});
            },
            groupSaleItemsCount(itemGroup: Storage.Entity.ItemGroup) {
              return _.sumBy(this.rootItem.children, (item) => Number(this.isSaleItemBelongsToItemGroup(item, itemGroup)))
            },

            saleItemsCountByMenuButton (button: MenuButton) {
              return _.sumBy(this.rootItem.children, (item) => Number(item.itemCode == button.code))
            },

            isSaleItemBelongsToItemGroup (saleItem: Storage.Entity.SaleItem, itemGroup: Storage.Entity.ItemGroup) {
              return saleItem.itemGroupId == Number(itemGroup.id)
            },

            buttonSelectedQuantityOnGroup (button: MenuButton, itemGroup: Storage.Entity.ItemGroup) {
              let selectedQuantity = 0
              const saleItemSelected = this.rootItem.children.find((item) => button.code == item.itemCode && this.isSaleItemBelongsToItemGroup(item, itemGroup))
              if (saleItemSelected){
                selectedQuantity = saleItemSelected.quantity
              }
              return selectedQuantity;
            },

            canMoveToNextGroup(group) {
              let childrenOfCurrentGroup = this.groupSaleItems(group)
              return ((childrenOfCurrentGroup.length === 1 && childrenOfCurrentGroup[0].hasWeight && 
                childrenOfCurrentGroup[0].quantity >= group.minItemsToChoose) || (group.minItemsToChoose <= this.getGroupItemSelectedAmount(childrenOfCurrentGroup)));
            },
            
            minGroupItemsSelected(group) {
              let childrenOfCurrentGroup = this.groupSaleItems(group);
              return ((childrenOfCurrentGroup.length === 1 && childrenOfCurrentGroup[0].hasWeight && 
                childrenOfCurrentGroup[0].quantity >= group.minItemsToChoose) || (group.minItemsToChoose <= this.getGroupItemSelectedAmount(childrenOfCurrentGroup)));
            },

            maxGroupItemsSelected(group) {
              let childrenOfCurrentGroup = this.groupSaleItems(group);
              return ((childrenOfCurrentGroup.length === 1 && childrenOfCurrentGroup[0].hasWeight && 
                childrenOfCurrentGroup[0].quantity == group.maxItemsToChoose) || (group.maxItemsToChoose == this.getGroupItemSelectedAmount(childrenOfCurrentGroup)));
            },

            checkIfNeedChangePriceForFreeItems (group, groupIndex) {
              if (group.numberOfFreeItems > 0){
                let orders = Boolean(group.freeItemsExpensiveFirst) ? 'desc' : 'asc'
                let childrenOfCurrentGroup = this.rootItem.children.filter((item) => item.itemGroupId == group.id)
                
                //reset the counter of freeItemsUsed
                if (this.rootItem?.freeItemsUsed && this.rootItem?.freeItemsUsed[groupIndex]){
                  this.rootItem.freeItemsUsed[groupIndex] = 0
                }

                // reset the price to original and then start to calc
                for (let item of childrenOfCurrentGroup){
                  item.unitPrice = item.originalUnitPrice
                }

                childrenOfCurrentGroup = _.orderBy(childrenOfCurrentGroup, 'unitPrice', orders)

                for (let indexItem in childrenOfCurrentGroup){
                  let item = childrenOfCurrentGroup[indexItem]
                  if (this.rootItem?.freeItemsUsed == undefined){
                    this.rootItem.freeItemsUsed = {}
                  }

                  if (this.rootItem?.freeItemsUsed[groupIndex] == undefined){
                    this.rootItem.freeItemsUsed[groupIndex] = 0
                  }

                  if (this.rootItem.freeItemsUsed[groupIndex] == group.numberOfFreeItems){
                    break
                  }

                  this.rootItem.freeItemsUsed[groupIndex] += 1
                  item.unitPrice = 0
                }
              }
            },
      
            getGroupItemSelectedAmount(group) {
              if(group && group.length > 0) {
                let r = group.reduce((total, thisOne) => { return total + thisOne.quantity}, 0);
                return r
              }
              return 0;
            },

            canFinishGroupItemsSelected (itemGroup: Storage.Entity.ItemGroup) {
              const lastItemGroups = _.last(this.itemGroups)
              const currentGroupIslast = lastItemGroups.id == this.currentGroup.id

              if (jsonConfig.getVal(jsonConfig.KEYS.addItemWithGroupIfLastGroupGotMaxAndOtherGroupsGotMin) && currentGroupIslast && lastItemGroups.id != itemGroup.id){
                //if is last group just check if has min items choosed
                if (!this.minGroupItemsSelected(itemGroup)) {
                  return false;
                }
              }else{
                if (!this.maxGroupItemsSelected(itemGroup)) {
                  return false;
                }
              }

              return true;
            },

            tableMobileViewCanFinishGroupItemsSelectedAllGroups () {
              for (let group of this.itemGroups) {
                if (!this.canFinishGroupItemsSelected(group)){
                  return false;
                }

                let itemWithGroupsInner = this.getItemWithGroupsInnerByItemGroup(group)
                if (itemWithGroupsInner) {
                  //not select any grand child for first time just select
                  if (itemWithGroupsInner?.saleItem?.children == undefined){
                    return false
                  }

                  if (!itemWithGroupsInner.tableMobileViewCanFinishGroupItemsSelectedAllGroups()){
                    return false
                  }
                }
              }

              return true;
            },

            canFinishGroupItemsSelectedAllGroups() {
              for (let group of this.itemGroups) {
                if (!this.canFinishGroupItemsSelected(group)){
                  return false;
                }
              }
      
              return true;
            },

            async validateIfMinimumItemsWereChosen() {
            
              let currenGroup = this.itemGroups[this.currentGroupIndex]
              let errorMessage = i18next.t('itemWithGroupsDialog.minimumItemsValidationMessage', {minItemsToChoose:currenGroup.minItemsToChoose, groupName: currenGroup.name})
              if(this.numberOfItemsSelectedInCurrentGroup < currenGroup.minItemsToChoose) {

                if (this.showSelfServiceAndDalpakTableView) {
                  let itemText = currenGroup.minItemsToChoose == 1 ? i18next.t('itemWithGroupsDialog.oneItem') : i18next.t('itemWithGroupsDialog.xItems', {count: currenGroup.minItemsToChoose})
                  this.showVibratingError(i18next.t('itemWithGroupsDialog.minimalChoiceNotComitted', {itemText}));
                } else {
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: errorMessage,
                    hideCancelButton: true
                  });
                }

                return false
              }

              return true
            },

            async selectGroup(itemGroup:Storage.Entity.ItemGroup, groupIndex:number, amountToAdd = null, skipMinimumRequirementValidation = false) {
              let goToPreviousGroup = groupIndex < this.currentGroupIndex

              if(!skipMinimumRequirementValidation && !goToPreviousGroup) {
                let minimumItemSelectedInThisGroup =  await this.validateIfMinimumItemsWereChosen()

                if(!minimumItemSelectedInThisGroup) {
                  return
                }
              }

              this.buttons = this.getButtonsForGroup(itemGroup);
              this.currentGroupIndex = groupIndex;
              this.setTitle()
              if(amountToAdd != null && this.buttons.length == 1 && itemGroup.maxItemsToChoose == itemGroup.minItemsToChoose) {
                this.groupItemClicked(this.buttons[0],true,itemGroup.maxItemsToChoose, amountToAdd*itemGroup.minItemsToChoose)          
              }
              else if(this.buttons.length == 1 && itemGroup.maxItemsToChoose == itemGroup.minItemsToChoose) {
                this.groupItemClicked(this.buttons[0],true,itemGroup.maxItemsToChoose, itemGroup.minItemsToChoose)
              }
              else if (((this.buttons.length == 1 && itemGroup.maxItemsToChoose == this.buttons.length && 
                  itemGroup.minItemsToChoose == this.buttons.length) 
                || (this.buttons.length == 1 && itemGroup.maxItemsToChoose == itemGroup.minItemsToChoose)) 
                && !this.maxGroupItemsSelected(itemGroup)) {
                this.groupItemClicked(this.buttons[0],true,itemGroup.maxItemsToChoose)
              }
      
            },
            
            initDialogSize() {
                let isMobile = Pinia.globalStore.mobileLayout;
                let itemWithDialogXL = jsonConfig.getVal(jsonConfig.KEYS.itemWithDialogXL)
                let dialogClass = 'positive-dialog';                  

                let width = itemWithDialogXL && !isMobile ? '95%' : isMobile ? '100%' : '90%';
                let height: string | number = itemWithDialogXL ? 1200 : 650;

                if (this.showSelfServiceAndDalpakTableView) {
                  dialogClass += ` hide-dialog-titlebar self-service-items-with-groups-dialog`
                }
                if(!isMobile) {
                  dialogClass += `  rounded-xl `
                }else{
                  dialogClass += ` top-0`
                }

                if (this.isItemsCountSideBarActive) {
                  dialogClass += ` hide-boxshadow`
                }

                if (this.showSelfServiceAndDalpakTableView) {
                  if (this.simpleSelfService) {
                    width = '69.5%';
                    height = window.innerHeight / 1.63;
                  } else {
                    if (this.mobileLayout){
                      width = '100%';
                      height = window.innerHeight;
                    }else{
                      width = '77.5%';
                      height = window.innerHeight / 1.5;
                    }
                  }
                }
              this.dialogClass = dialogClass;
              this.dialogWidth = width;
              this.dialogHeight = `${height}px`;
            },
            menuItemStyle(button:MenuButton) {
              if (button.hasPicture && Pinia.globalStore.usePictures) {
                return "rgba(0,0,0,0)";
              }
              var defaultColor = _defaultItemColor;
              
              return posUtils.isNullOrUndefinedOrEmptyString(button.color) ? defaultColor :  button.color;
            },
            menuItemBorderStyle(button:MenuButton) {
              if (button.hasPicture && Pinia.globalStore.usePictures) {
                return "rgba(0,0,0,0)";
              }
              let res =  PositiveShared.Utils.LightenDarkenColor(this.menuItemStyle(button),-50)
              return res;
            },
            addCurrencySign(price, removeSpace = false) {
              return Service.MultiCurr.getInstance().addCurrencySign(price, removeSpace);
            },
            getItemImage(item) {
              if (item && item.hasPicture){
                return `/catalog-images/items/${this.tenantID}/${this.companyID}/${item.code}`;
              }
              
              return this.defaultItemImageUrl;
            },
            getGrandchildrenGrouped(item) {
              if (!item.children) {
                return {};
              }

              return _.groupBy(item.children, childItem => `${childItem.itemCode}|${childItem.unitPrice}`);
            },
          }

          let sharedComputed = {
            currentGroup() {
              if (!this.itemGroups) {
                return null;
              }
              return this.itemGroups[this.currentGroupIndex]
            },
            itemSelectQuantityTitle () {
              return i18next.t('itemWithGroupsDialog.selfServiceGroupTitle', {
                itemsSelectedQuantity: this.numberOfItemsSelectedInCurrentGroup,
                quantityItemsToSelected: this.currentGroup?.maxItemsToChoose || 0})
            },
            currentGroupImage() {
              return this.getItemImage(this.currentGroupParentItem);
            },
            defaultImageUrl() {
              return this.showSelfServiceAndDalpakTableView ? this.defaultImages[Storage.Entity.ItemMenuItem.DEFAULT_IMAGES_TAGS.SELF_SERVICE_CHILD_ITEM] : null;
            },
            defaultItemImageUrl() {
              return this.showSelfServiceAndDalpakTableView ? this.defaultImages[Storage.Entity.ItemMenuItem.DEFAULT_IMAGES_TAGS.SELF_SERVICE_ITEM] : null;
            },
            currentGroupParentItem() {
              if (this?.currentGroup?.parentItemId) {
                return (new Storage.Entity.Item()).findByIdWithoutPromise(this?.currentGroup?.parentItemId)
              }

              return null;
            },
            isDalpakimNewInstractionsScreen() {
              return jsonConfig.getVal(jsonConfig.KEYS.dalpakimNewInstractionsScreen)
            },
            showSelfServiceAndDalpakTableView() {
              return this.simpleSelfService || this.isDalpakimNewInstractionsScreen;
            },

            isTableViewMobile () {
              return this.isDalpakimNewInstractionsScreen && this.mobileLayout;
            },
            isItemsCountSideBarActive() {
              return  this.isDalpakimNewInstractionsScreen;
            },
            menuButtonItemWrapperClasses() {
              if (this.simpleSelfService) {
                let divClass = null;

                if (this.buttons && this.buttons.length < 5) {
                  divClass = 'big-items';
                }

                return divClass;
              }

              let buttonsNumber = this.buttons ? this.buttons.length : 0;
              

              switch (buttonsNumber) {
                case 1:
                case 2:
                  return 'big-items';
              }

              return null;
            },
            menuButtonItemClasses() {
              if (this.simpleSelfService) {
                let divClass = null;

                if (this.buttons && this.buttons.length < 5) {
                  divClass = 'big-item';
                }

                return divClass;
              }
              let buttonsNumber = this.buttons ? this.buttons.length : 0;
              switch (buttonsNumber) {
                case 1:
                case 2:
                  return 'big-item';
              }

              return null;
            },
            rootChildrenItemByGroup() {
              if (!this.rootItem?.children) {
                return {};
              }

              return _.groupBy(this.rootItem.children, 'itemGroupId');
            },
            currentGroupRootChildrenItems() {
              return this.rootChildrenItemByGroup[this.currentGroup?.id] || [];
            },
            currentGroupChildrenByItemCode() {
              return _.groupBy(this.currentGroupRootChildrenItems, 'itemCode');
            },
            allGroupsChildrenItemsGroupedByUniqIdentifiers() {
              let groupedItems = _.groupBy(this.rootItem.children, childItem => {
                let childrenCodes = [];

                if (childItem.children) {
                  childrenCodes = childItem.children.map(c => c.itemCode).sort();
                }

                return `${childItem.itemGroupId}|${childItem.itemCode}|${childItem.unitPrice}|${childrenCodes.join(',')}`;
              });

              return groupedItems;
            },
            childrenHasPreparationInstructionsByItemCode() {
              if (!this.rootItem?.children) {
                return {};
              }

              return _.keyBy(this.rootItem.children.filter(i => i.hasPreparationInstructions), 'itemCode');
            },

            numberOfItemsSelectedInCurrentGroup () {
              if (this.currentGroup == null) {
                return 0;
              }
              return this.rootItem.children.filter((item) => { return item.itemGroupId == this.currentGroup.id})
                      .reduce((total, child) => total + (child.quantity), 0);
            },
          }
          
          let component = {
              template: JST.itemWithGroupsDialog({domId: 'item-with-groups-dialog', title:_title}),
              components: {scrollable: Scrollable.getComponent()},
              async created () {
                if (this.isInner && this.isTableViewMobile){
                  await this.loadGroups(this.saleItem, 0)
                  this.itemGroups.forEach(itemGroup => {
                    this.toggleItemGroupsById[itemGroup.id] = true
                  })
                }
              },
              props: {
                isInner: {
                  type: Boolean,
                  default: false,
                },
                saleItem: {
                  type: Object,
                  default: null,
                }
              },
              methods: {
                ...sharedMethods
              },
              setup(){
                const languageManagerStore = Pinia.useLanguageManagerStore();
                const {currentLang} = Pinia.storeToRefs(languageManagerStore);

                const globalStore = Pinia.useGlobalStore();

                const {isPunchCardMode,isLTR,outOfStockItemCodesByCode,itemWithDialogXL,simpleSelfService,usePictures,mobileLayout,defaultImages} = Pinia.storeToRefs(globalStore);
                const globalStoreProps = {showCancelButton:()=>!isPunchCardMode,isLTR,outOfStockItemCodesByCode,itemWithDialogXL,simpleSelfService,usePictures,mobileLayout,defaultImages} 

                return {...globalStoreProps,currentLang}
              },
              data() {
                return initData("item-with-groups-dialog")
              },
              
              computed: {
                ...sharedComputed,
                isComponent () {
                  return this.showScrollArrowButtons ? 'scrollable' : 'div'
                },
                
                currencySign() {
                  return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign();
                },
                showPrice() {
                  return Boolean(jsonConfig.getVal(jsonConfig.KEYS.displayPricesOnButtons))
                },
                showScrollArrowButtons() {
                  return jsonConfig.getVal(jsonConfig.KEYS.showScrollArrowButtons);
                },
                tenantID() {
                  return session.pos.tenantID;
                }, 
                companyID() {
                  return session.pos.companyID;
                },
                sprateBtnImgText(){
                  return jsonConfig.getVal(jsonConfig.KEYS.sprateBtnImgText);
                },
                fullBtnImgText(){
                  return jsonConfig.getVal(jsonConfig.KEYS.fullBtnImgText);
                },
              }
          }
          
          //for when adding parit im tosafor (level 2)
          let innerComponent = {
              template: JST.itemWithGroupsDialog({domId: 'item-with-groups-dialog-inner', title:_title}),
              components: {scrollable: Scrollable.getComponent()},
              props: {
                isInner: {
                  type: Boolean,
                  default: false,
                },
                saleItem: {
                  type: Object,
                  default: null,
                }
              },
              methods: {
                ...sharedMethods
              },
              data: () => {
                return initData("item-with-groups-dialog-inner")
              },
              setup(){
                const languageManagerStore = Pinia.useLanguageManagerStore();
                const {currentLang} = Pinia.storeToRefs(languageManagerStore);

                const globalStore = Pinia.useGlobalStore();
                const {
                  outOfStockItemCodesByCode,
                  itemWithDialogXL,
                  simpleSelfService,
                  usePictures,
                  mobileLayout,
                  defaultImages
                } = Pinia.storeToRefs(globalStore);

                const globalStoreProps = {
                  outOfStockItemCodesByCode,
                  itemWithDialogXL,
                  simpleSelfService,
                  usePictures,
                  mobileLayout,
                  defaultImages
                } 
                return {...globalStoreProps,currentLang}
              },
              
              computed: {
                ...sharedComputed,
                isComponent () {
                  return this.showScrollArrowButtons ? 'scrollable' : 'div'
                },
                
                currencySign() {
                  return PositiveTS.Service.MultiCurr.getInstance().getPosCurrencySign();
                },
                showPrice() {
                  return Boolean(jsonConfig.getVal(jsonConfig.KEYS.displayPricesOnButtons))
                },
                showScrollArrowButtons() {
                  return jsonConfig.getVal(jsonConfig.KEYS.showScrollArrowButtons);
                },
                tenantID() {
                  return session.pos.tenantID;
                }, 
                companyID() {
                  return session.pos.companyID;
                },
                sprateBtnImgText(){
                  return jsonConfig.getVal(jsonConfig.KEYS.sprateBtnImgText);
                },
                fullBtnImgText(){
                  return jsonConfig.getVal(jsonConfig.KEYS.fullBtnImgText);
                },

                
                showCancelButton: () => !Pinia.globalStore.isPunchCardMode,
              },

          }

          let result = VueApp.component('item-with-groups-dialog',component)
          VueApp.component('item-with-groups-dialog-inner',innerComponent) //for when adding parit im tosafor (level 2)
          return result;
      }
    }
}
}
