module PositiveTS {
  export module Components {
    export module PreparationInstructionsDialog {

      const _defaultMenuColor = '#0061a7';
      const _defaultItemColor = '#40c3d9';
      const _title = "הוראות הכנה";


      interface MenuButton {
        name:string
        id?: string
        isDefault: boolean
        type: number
        isGeneric: boolean
        color?: string
        sortOrder?: number,
        translations?: Array<{name: string}>
      }

      interface SelectedInstruction {
        isOpen:boolean
        text:string
      }

      enum InstructionType {
        REGULAR = 1,
        KISHUR = 2
      }

      function initData(dialogSelector, dialogWidth = '90%', dialogHeight = 650) {
        return {
          title: '',
          initialized: true,
          currentLevel: 0,
          loading: true,
          addingInProgress: false,
          prepInsturctions: [],
          selectedInstructions: [],
          selectedInstructionsTranslations: [],
          additions: [],
          selectedAdditions: [],
          theRootItem: {},
          buttons: [{ name: "טוען...", id: undefined, color: undefined }],
          deleteImg: `${(<any>window).images_path}delete-new.png`,
          giftImg: `${(<any>window).images_path}pos/gift.png`,
          _defaultMenuColor: _defaultMenuColor,
          _defaultItemColor: _defaultItemColor,
          dialogWrapper: dialogSelector,
          dialogSelector: `#${dialogSelector}`,
          dialogSelectorId: dialogSelector,
          dialogWidth: dialogWidth,
          dialogHeight: dialogHeight,
          width: '',
          height: '',
          selectedSaleItem: null,
          dialogClass: null,
        }
      }

      const preparationInstructionsMixin = {
        methods: {
          async open(saleItem, number = 0) {
            this.theRootItem = saleItem;
            this.loadPrepInstructions(this.theRootItem);

            if (this.isItemWithOnlyGenericPrepInstructions) {
              $(document).unbind('keypress');
              await this.$nextTick();
              let result = await this.openSelfServiceGerericInstructionDialog(true);
              $(document).keypress(posVC.onKeyPress);

              return result == null ? null : this.theRootItem;
            }

            return new Promise((resolve, reject) => {
              this.initDialogCss()
              $(document).unbind('keypress');
              this.resolveFunc = resolve;
              this.rejectFunc = reject;
              this.loading = false;

              let title = `הוראות הכנה לפריט${number > 1 ? ` ${number}` : ''}: ${saleItem.itemDescription}`;
              this.title = title;
              this.$el.showModal();
            })
          },
          async openSelfServiceGerericInstructionDialog(hasNoPrepInstructions = false) {
            let text = null;

            if (hasNoPrepInstructions) {
              text = this.selectedInstructions[0]?.text;
            }

            let result = await this.openSelfServiceGenericIntractionDialog(hasNoPrepInstructions, text, !this.useIndependentDialog);

            if (hasNoPrepInstructions) {
              let prepInstructionsArray = []

              if (!posUtils.isBlank(result)) {
                prepInstructionsArray = [result];
              }
              if(this.multiLangEnabled){
                this.theRootItem.selectedPreparationInstructionsTranslations = JSON.stringify(prepInstructionsArray.map((inst) => { return { [this.currentLang]: { name: inst } } }));
              }else{
                this.theRootItem.selectedPreparationInstructions = JSON.stringify(prepInstructionsArray);
              }

            } else {
              if (!posUtils.isBlank(result)) {
                this.selectedInstructions.push({ text: result + " ", isOpen: false });
                if(this.multiLangEnabled){
                  this.selectedInstructionsTranslations.push({ text: { original:  result + " " }, isOpen: false });
                }
              }
            }

            return result;
          },
          async prepInstClicked(button: MenuButton) {
            if (!button.id) {
              return;
            }
          
            const lastInst = this.selectedInstructions.at(-1); // Get the last element, if any
          
            // Handle generic button interaction
            if (button.isGeneric) {
              const dialogArgs = [
                i18next.t('prepInstructions.dialogHeader'),
                i18next.t('prepInstructions.dialogDescription'),
                false,
                Components.TextInputDialog.keyboardTypes.simpleKeyboard,
                "text",
                "",
                null,
                true
              ];
              const res = await Pinia.componentsStore.openComponent({ componentName: "textInputDialog", args: dialogArgs });
              if (res.success) {
                this.selectedInstructions.push({ text: res.result + " ", isOpen: false });
              }
            } else {
              // Handle specific button interaction based on the type
              this.handleInstructionType(button, lastInst);
            }
          },
          
          handleInstructionType(button: MenuButton, lastInst: SelectedInstruction | undefined) {
            // Determine the new text and whether there's an open line
            let newText, newTextTranslatedObject;
            if (this.multiLangEnabled) {
              newTextTranslatedObject = Object.keys(button.translations).reduce((acc, key) => {
                acc[key] = `${button.translations[key].name || button.name} `;
                return acc;
              }, {});
              newText = `${button?.translations[this.defaultLang]?.name || button.name} `;
            } else {
              newText = `${button.name} `;
              newTextTranslatedObject = {}; // Default to an empty object when multiLang is not enabled
            }
            const isOpenLineExist = lastInst?.isOpen;
          
            // Handle KISHUR type button interaction
            if (button.type === InstructionType.KISHUR) {
              if (!isOpenLineExist) {
                this.selectedInstructions.push({ text: newText, isOpen: true });
                this.translateInstructionIfNeeded(button, newTextTranslatedObject, true);
              } else {
                lastInst!.text += newText; // Append to the open line
                if (this.multiLangEnabled) {
                  this.appendTranslations(newTextTranslatedObject);
                }
              }
            } 
            // Handle REGULAR type button interaction
            else if (button.type === InstructionType.REGULAR) {
              if (!isOpenLineExist) {
                this.selectedInstructions.push({ text: newText, isOpen: false });
                this.translateInstructionIfNeeded(button, newTextTranslatedObject, false);
              } else {
                lastInst!.text += newText; // Append and close the open line
                lastInst!.isOpen = false;
                if (this.multiLangEnabled) {
                  this.appendTranslations(newTextTranslatedObject);
                }
              }
            }
          },
          
          appendTranslations(newTextTranslatedObject) {
            // Assuming the last element in selectedInstructionsTranslations corresponds to lastInst
            let lastTranslation = this.selectedInstructionsTranslations[this.selectedInstructionsTranslations.length - 1];
            Object.keys(newTextTranslatedObject).forEach((key) => {
              if (lastTranslation.text[key]) {
                lastTranslation.text[key] += newTextTranslatedObject[key] || newTextTranslatedObject['original'];
              } else {
                lastTranslation.text[key] = newTextTranslatedObject[key] || newTextTranslatedObject['original'];
              }
            });
          },
          
          translateInstructionIfNeeded(button: MenuButton, newTextObject, isOpen: boolean) {
            if (!this.multiLangEnabled) {
              return;
            }
            button.translations["original"] = { name: button.name };
            this.selectedInstructionsTranslations.push({
              text: newTextObject,
              isOpen: isOpen,
            });
          },
          setLoadingState() {
            this.buttons = [{ name: "טוען...", id: undefined }]
            this.loading = true;
          },
          cancel() {
            if (this.selfServiceGenericIntractionDialog && this.selfServiceGenericIntractionDialog.isOpen()) {
              this.selfServiceGenericIntractionDialog.cancel();
              return;
            }

            this.resolveFunc(null)
            this.close()
          },
          finish(skipChanges = false) {
            if (skipChanges) {
              this.theRootItem.selectedPreparationInstructions = JSON.stringify([]);
              if(this.multiLangEnabled){
                this.theRootItem.selectedPreparationInstructionsTranslations = JSON.stringify([])
              }
            } else {
              this.theRootItem.selectedPreparationInstructions = JSON.stringify(this.selectedInstructions.filter(si => !si.isOpen).map(si => si.text.trim()))
              if(this.multiLangEnabled){
                this.theRootItem.selectedPreparationInstructionsTranslations = JSON.stringify(this.selectedInstructionsTranslations)
              }
            }

            this.resolveFunc(this.theRootItem)
            this.close()
          },
          close() {
            this.$el.close();
            $(document).unbind('keypress');
            $(document).keypress(posVC.onKeyPress);
            this.cleanData();
          },
          deleteRow(index) {
            this.selectedInstructions.splice(index, 1)
            if(this.multiLangEnabled){
              this.selectedInstructionsTranslations.splice(index, 1)
            }
          },
          cleanData() {
            let ignore = ['dialogSelector', 'dialogSelectorId']
            let result = initData(this.dialogSelector);
            for (let prop in result) {
              if (ignore.includes(prop)) {
                continue;
              }
              this[prop] = result[prop];
            }
          },
          async addAddition(addition: Storage.Entity.ItemDirectAddition) {
            try {
              if (jsonConfig.getVal(jsonConfig.KEYS.allowOutOfStockItems)) {
                if (this.outOfStockItemCodesByCode[addition.additionItemCode]) {
                  let message = jsonConfig.getVal(jsonConfig.KEYS.outOfStockItemMessage) || i18next.t('outOfStockItemsDialog.outOfStockMessage')
                  app.showAlertDialog({
                    header: i18next.t('error'),
                    content: message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  }, null, null);
                  return;
                }
              }

              let item = <Storage.Entity.Item>(await (new Storage.Entity.Item()).findByIdNew(addition.additionItemId));
              //let saleItem = new Storage.Entity.SaleItem();

              if (item != null) {
                let saleItem = (new Storage.Entity.SaleItem()).importFromItemAndBarcode(item, { size: 'null', color: 'null', barcode: item.code });

                saleItem.parentItemId = addition.itemId;
                saleItem.quantity = 1;
                // saleItem.unitPrice = .priceZarhan
                saleItem.level = this.theRootItem.level + 1;
                saleItem.itemGroupId = -1; //direct group
                this.theRootItem.children.push(saleItem);
                this.selectedAdditions = this.theRootItem.children.filter(child => child.itemGroupId == -1)
                // this.selectedAdditions.push(saleItem);
              }
            }
            catch (err) {
              console.log(err)
            }

          },
          deleteAddition(selectedAddition: Storage.Entity.SaleItem) {
            this.theRootItem.children = this.theRootItem.children.filter(child => child.id != selectedAddition.id)
            this.selectedAdditions = this.theRootItem.children.filter(child => child.itemGroupId == -1)
          },
          getSelectedInstructions(saleItem: Storage.Entity.SaleItem) {
            if (posUtils.isNullOrUndefinedOrEmptyString(saleItem.selectedPreparationInstructions)) {
              return [];
            }
                return JSON.parse(saleItem.selectedPreparationInstructions).map((inst) => { return { text: inst, isOpen: false } })
          },
          getSelectedInstructionsTranslations(saleItem: Storage.Entity.SaleItem) {
            if(!this.multiLangEnabled){
              return [];
            }
            if (posUtils.isNullOrUndefinedOrEmptyString(saleItem.selectedPreparationInstructions)) {
              return [];
            }
            return JSON.parse(saleItem.selectedPreparationInstructionsTranslations)
          },
          initDialogCss() {

            let isMobile = this.mobileLayout;

            let dialogClass = 'positive-dialog';
            let width = isMobile ? '100%' : this.dialogWidth;
            let height = this.dialogHeight;

            if (this.showSelfServiceAndDalpakTableView) {
              dialogClass = dialogClass + ' self-service-preperation-instruction bg-transparent'

              if(!isMobile) {
                dialogClass += `  rounded-xl `
              }else{
                dialogClass += ` top-0`
              }

              if (this.isSideBarActive) {
                dialogClass += ` hide-boxshadow`
              }

              if (this.simpleSelfService) {
                width = '69.5%';
                height = window.innerHeight / 1.63;
              } else {
                width = '77.5%';
                height = window.innerHeight / 1.5;
              }
            }

            if (isMobile) {
              width = '100%';
              height = window.innerHeight;
            }
            this.dialogClass = dialogClass;
            this.width = width;
            this.height = `${height}px`;
          },
          createInstructionButtons(instructions: Array<Storage.Entity.PreparationInstruction>): Array<MenuButton> {
            let translations:PositiveTS.Service.Translation.TranslationObj;
            let arrRest = instructions.map((inst) => {
              if(this.multiLangEnabled){
                translations = posUtils.jsonParseWasmCol(inst.translations)
              }else{
                translations = {};
              }
              return {
                id: inst.id,
                name: inst.name,
                type: inst.instructionType,
                isDefault: inst.isDefault,
                isGeneric: false,
                color: inst.color || null,
                sortOrder: inst.sortOrder || null,
                translations: translations as Array<{name: string}>
              }
            })

            if (!this.showSelfServiceAndDalpakTableView) {
              arrRest.push(this.genericOrderButton);
            }

            return arrRest;
          },
          loadPrepInstructions(saleItem: Storage.Entity.SaleItem) {

            let instructions = Storage.Entity.PreparationInstruction.getInstructionsForSaleItem(saleItem)
            this.prepInsturctions = instructions;
            this.buttons = this.createInstructionButtons(instructions);
            this.selectedInstructions = this.getSelectedInstructions(saleItem);
            this.selectedInstructionsTranslations = this.getSelectedInstructionsTranslations(saleItem);
            this.theRootItem.children = this.theRootItem.children || [];
            this.selectedAdditions = this.theRootItem.children.filter(child => child.itemGroupId == -1)
            this.additions = Storage.Entity.ItemDirectAddition.getAdditionsForItem(saleItem);
          },
          async openSelfServiceGenericIntractionDialog(hasNoPrepInstructions: boolean, text: string, useIndependentDialog: boolean) {
            let args = [hasNoPrepInstructions, text, useIndependentDialog];
            if (this.useIndependentDialog) {
              return await Pinia.componentsStore.openComponent({ componentName: "selfServiceGenericPrepInstructionsIndependentDialog", args });
            } else {
              return await Pinia.componentsStore.openComponent({ componentName: "selfServiceGenericPrepInstructionsInnerDialog", args });
            }
          },
          prepareInstructions(buttons) {
            return buttons.map(button => {
              let buttonText = this.multiLangEnabled && button.translations[this.currentLang] && Object.keys(button.translations[this.currentLang]).length > 0
                ? button.translations[this.currentLang].name
                : button.name;
              return {
                ...button,
                displayText: buttonText
              };
            });
          }
        },
        computed: {
          defaultImages(){
            return Pinia.globalStore.defaultImages
          },
          simpleSelfService(){
            return Pinia.globalStore.simpleSelfService
          },
          mobileLayout(){
            return Pinia.globalStore.mobileLayout
          },
          outOfStockItemCodesByCode(){
            return Pinia.globalStore.outOfStockItemCodesByCode
          },
          multiLangEnabled(){
            return Pinia.languageManagerStore.multiLangEnabled
          },
          currentLang(){
            return Pinia.languageManagerStore.currentLang
          },
          defaultLang(){
            return Pinia.languageManagerStore.defaultLang
          },
          itemImage() {
            if (this.theRootItem?.itemRef?.hasPicture) {
              return `/catalog-images/items/${session.pos.tenantID}/${session.pos.companyID}/${this.theRootItem.itemRef.code}`;
            }

            return this.defaultItemImageUrl;
          },
          defaultItemImageUrl() {
            return this.simpleSelfService ? this.defaultImages[Storage.Entity.ItemMenuItem.DEFAULT_IMAGES_TAGS.SELF_SERVICE_ITEM] : null;
          },
          genericOrderButton() {
            return { id: "-1", name: 'הוראה כללית', type: 1, isDefault: true, isGeneric: true, color: null, sortOrder: -1 };
          },
          buttonsByType() {
            return _.groupBy(this.buttons, 'type')
          },
          kishurInstructionsButtons() {
            return this.prepareInstructions(this.buttonsByType[InstructionType.KISHUR] || []);
          },
          regularInstructionsButtons() {
            return this.prepareInstructions(this.buttonsByType[InstructionType.REGULAR] || []);
          },
          showSelfServiceAndDalpakTableView() {
            return this.simpleSelfService || this.isDalpakimNewInstractionsScreen;
          },
          isPreparationsInstructionsNotes() {
            return !jsonConfig.getVal(jsonConfig.KEYS.selfServiceCancelPreparationsInstructionsNotes)
          },
          isDalpakimNewInstractionsScreen() {
            return jsonConfig.getVal(jsonConfig.KEYS.dalpakimNewInstractionsScreen)
          },
          isSideBarActive() {
            return this.isDalpakimNewInstractionsScreen;
          },
          selfServiceGenericIntractionDialog() {
            return this.useIndependentDialog ?
              PositiveTS.VueInstance.$refs.selfServiceGenericPrepInstructionsIndependentDialog :
              PositiveTS.VueInstance.$refs.selfServiceGenericPrepInstructionsInnerDialog;
          },
          useIndependentDialog() {
            return this.simpleSelfService || (this.showSelfServiceAndDalpakTableView && this.isItemWithOnlyGenericPrepInstructions);
          },
          isItemWithOnlyGenericPrepInstructions() {
            return this.showSelfServiceAndDalpakTableView && this.buttons.length == 0;
          }
        },
      }

      export function create() {
        let component = {
          template: JST.prepInstructionsDialog({ domId: 'prep-instructions-dialog', title: _title }),
          mixins: [preparationInstructionsMixin],
          data: () => {
            return initData("prep-instructions-dialog")
          },
          computed: {
            domId: () => '#prep-instructions-dialog',
            showCancelButton() {
              return !Pinia.globalStore.isPunchCardMode;
            },
          }
        }

        //for when adding parit im tosafor (level 2)
        let innerComponent = {
          template: JST.prepInstructionsDialog({ domId: 'prep-instructions-dialog-inner', title: _title }),
          mixins: [preparationInstructionsMixin],
          data: () => {
            return initData("prep-instructions-dialog-inner", '80%', 600)
          },

          computed: {
            domId: () => '#prep-instructions-dialog-inner',
            showCancelButton: () => true,
          },
        }

        VueApp.component('prep-instructions-dialog', component)
        VueApp.component('prep-instructions-dialog-inner', innerComponent) //for when adding parit im tosafor (level 2)
      }
    }
  }
}
