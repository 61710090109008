
import { defineStore } from 'pinia';

const state: {
    isPOSReady: boolean,
    selectedItems: PositiveTS.Storage.Entity.SaleItem[],
    expandedView: boolean,
    selectModeActive: boolean,
    activeMenu: PositiveTS.Types.MenuButton[],
    defaultMenu: PositiveTS.Types.MenuButton[],
    defaultMenuName: string,
    activeMenuName: string,
    isSaleTA: boolean,
    autoStore: { step: number, config: Object },
    isPaymentPageActive: boolean,
    selfServiceCartTagId: string,
} = {
    isPOSReady: false,
    selectedItems: [],
    expandedView: false,
    selectModeActive: false,
    activeMenu: [],
    defaultMenu: [],
    defaultMenuName: '',
    activeMenuName: '',
    isSaleTA: false,
    isPaymentPageActive: false,
    autoStore: {
        step: 0,
        config: {
        },
    },
    selfServiceCartTagId: 'self-service-cart',
}

const getters = {
    isSelected: state => (item: PositiveTS.Storage.Entity.SaleItem) => state.selectedItems.includes(item),
    selectedCount: state => state.selectedItems.length,
    autoStoreStep: state => state.autoStore.step,
    selfServiceCartTag: state => document.querySelector(`#${state.selfServiceCartTagId}`),
}

const actions = {
    setIsPaymentPageActive(payload) {
        this.isPaymentPageActive = payload
    },
    selectItem(selectedItem) {
        this.selectModeActive = true;
        if (this.selectedItems.some(saleItem => saleItem.id === selectedItem.id)) {
            this.selectedItems = this.selectedItems.filter(saleItem => saleItem.id !== selectedItem.id);
        } else {
            this.selectedItems.push(selectedItem);
        }
        if (this.selectedItems.length == 0) {
            this.selectModeActive = false;
        }
    },
    resetItems() {
        this.selectedItems = [];
        this.selectModeActive = false;
    },
    setExpandedView(expandedView: boolean) {
        this.expandedView = expandedView;
    },
    setSelectMode(selectModeActive: boolean) {
        this.selectModeActive = selectModeActive;
    },
    setActiveMenu({ items, name }: { items: PositiveTS.Types.MenuButton[], name: string }) {

        if (name === this.activeMenuName) {
            this.activeMenu = this.defaultMenu;
        } else {
            this.activeMenu = items;
        }

        if (name === this.activeMenuName) {
            this.activeMenuName = this.defaultMenuName;
        } else {
            this.activeMenuName = name;
        }
    },
    setDefaultMenu(defaultMenu: PositiveTS.Types.MenuButton[]) {
        this.defaultMenu = defaultMenu;
        this.activeMenu = defaultMenu;
    },
    setDefaultMenuName(defaultMenuName: string) {
        this.defaultMenuName = defaultMenuName;
    },
    setIsSaleTA(isSaleTA: boolean) {
        this.isSaleTA = isSaleTA;
    },
    setAutoStoreConfig(autoStoreConfig: any) {
        this.autoStore.config = autoStoreConfig;
    },
    setAutoStoreStep(step: number) {
        this.autoStore.step = step;
    },
    setAutoStoreError(error: any) {
        this.autoStore.error = error;
    },
    setIsTA(isTA: boolean) {
        this.setIsSaleTA(isTA);
        PositiveTS.Service.SittingTA.setIsTA(isTA);
    },
    setIsPOSReady(isPOSReady: boolean) {
        this.isPOSReady = isPOSReady;
    },
    enterEditMode(item) {
        this.selectItem(item);
    },
    leaveEditMode() {
        this.setSelectMode(false);
        this.resetItems();
    },
    toggleExpandedView() {
        this.setExpandedView(!this.expandedView);
    },
    toggleSelectMode() {
        this.setSelectMode(!this.selectModeActive);
    },
    async decrementQuantity(selectedItem: PositiveTS.Storage.Entity.SaleItem) {

        let posVCSaleItems
        let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection) && !jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)

        if (!allowMultipleSaleItemsSelection && selectedItem != null) {

          Pinia.globalStore.saleItemSelected(selectedItem)
            await PositiveTS.VueInstance.$nextTick();
        }

        if (allowMultipleSaleItemsSelection) {
            posVCSaleItems = posVC.fetchArrayPosVCSaleItems(Pinia.globalStore.multipleSelectedSaleItems)
        } else {
            posVCSaleItems = [posVC.saleItemForSelectedRow()]
        }

        if (PositiveTS.Service.SmartVoucher.isLoadCardSale(posVC.saleItems, posVC.Specialitems)) {
            console.error('Specialitem count can only be one');
            return;
        }

        let managerEmployee
        if (PositiveTS.Service.ManagerApproval.isReducingQuantity()) {
            managerEmployee = managerEmployee || await app.showManagerApprovalDialog([PositiveTS.Storage.Entity.Employee.IS_MANAGEER_MINUS_CONFIRM]);
        }
        for (const saleItem of posVCSaleItems) {
            if (saleItem.isPNRItem) {
                continue;
            }
            let tmpItem = saleItem.clone()
            let originalQuantity = saleItem.quantity;
            let newQuantity = saleItem.quantity;
            // Allow negative quantity only if parameterAllowNegativeSale is allowed and it is not a self service supermaket pos
            if (newQuantity == 1) {
                if (session.pos.parameterAllowNegativeSale && !jsonConfig.getVal(jsonConfig.KEYS.isSelfServiceSuperMarketPos)) {

                    if (session.pos.parameterAllowNegativeQty) {

                        newQuantity = -1;
                        tmpItem.quantity = newQuantity - originalQuantity
                        if (posVC.canSaleItemBePersisted(tmpItem) === true) {
                            saleItem.quantity = newQuantity
                            if (session.pos.useMinPriceForNegItem == true) {
                                if (saleItem.item.minUnitPrice && saleItem.item.minUnitPrice > 0 && saleItem.item.minUnitPrice < saleItem.unitPrice) {
                                    saleItem.priceBeforeChangedToMin = saleItem.unitPrice;
                                    saleItem.unitPrice = saleItem.item.minUnitPrice;
                                }
                            }
                            await posVC.persistSaleItem(saleItem);

                            if (!allowMultipleSaleItemsSelection) {
                              Pinia.globalStore.saleItemSelected(saleItem)
                            }

                        }

                    } else {
                        managerEmployee = managerEmployee || await app.showManagerApprovalDialog([PositiveTS.Storage.Entity.Employee.IS_MANAGEER_MINUS_CONFIRM])

                        newQuantity = -1;
                        saleItem.discountApprovedByEmployeeID = managerEmployee.employeeID;
                        tmpItem.quantity = newQuantity - originalQuantity
                        if (posVC.canSaleItemBePersisted(tmpItem) === true) {
                            saleItem.quantity = newQuantity
                            if (session.pos.useMinPriceForNegItem == true) {
                                if (saleItem.item.minUnitPrice && saleItem.item.minUnitPrice > 0 && saleItem.item.minUnitPrice < saleItem.unitPrice) {
                                    saleItem.priceBeforeChangedToMin = saleItem.unitPrice;
                                    saleItem.unitPrice = saleItem.item.minUnitPrice;
                                }
                            }

                            await posVC.persistSaleItem(saleItem);
                            if (!allowMultipleSaleItemsSelection) {
                              Pinia.globalStore.saleItemSelected(saleItem)
                            }
                        }
                    }
                } else {
                    return;
                }
            } else {
                newQuantity = saleItem.quantity - 1;
                tmpItem.quantity = newQuantity - originalQuantity
                if (posVC.canSaleItemBePersisted(tmpItem) === true) {
                    saleItem.quantity = newQuantity
                    await posVC.persistSaleItem(saleItem);

                    if (!allowMultipleSaleItemsSelection) {
                      Pinia.globalStore.saleItemSelected(saleItem)
                    }
                }
            }
        }
    },
    async incrementQuantity(selectedItem: PositiveTS.Storage.Entity.SaleItem) {
        if (PositiveTS.Service.SmartVoucher.isLoadCardSale(posVC.saleItems, posVC.Specialitems)) {
            console.error('Specialitem count can only be one');
            return;
        }

        let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection) && !jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)

        if (!allowMultipleSaleItemsSelection && selectedItem != null) {
          Pinia.globalStore.saleItemSelected(selectedItem)
            await PositiveTS.VueInstance.$nextTick();
        }

        let saleItem: PositiveTS.Storage.Entity.SaleItem;
        if (allowMultipleSaleItemsSelection) {
            saleItem = posVC.fetchPosVCSaleItem(selectedItem)
        }
        else {
            saleItem = posVC.saleItemForSelectedRow();
        }
        if (saleItem.isPNRItem) {
            console.error('PNR Item count can only be one');
            app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('elal.errors.cannotChangeQuantityPNRItem'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
            });
            return;
        }
        let tmpItem = saleItem.clone()
        let originalQuantity = saleItem.quantity;
        let newQuantity = saleItem.quantity;




        newQuantity = originalQuantity + 1;

        // Don't allow 0 quantity - in case previous quantity was -1....
        if (newQuantity == 0) {
            newQuantity = 1;
            if (session.pos.useMinPriceForNegItem == true) {
                if (saleItem.priceBeforeChangedToMin > 0) {
                    saleItem.unitPrice = saleItem.priceBeforeChangedToMin;
                }
            }
        }

        tmpItem.quantity = newQuantity - originalQuantity
        if (posVC.canSaleItemBePersisted(tmpItem) === true) {
            saleItem.quantity = newQuantity
            await posVC.persistSaleItem(saleItem);
        }
    },
    async setManualQuantitiy({ selectedItem, quantityToUpdate }: { selectedItem: PositiveTS.Storage.Entity.SaleItem, quantityToUpdate: number }) {

        if (PositiveTS.Service.SmartVoucher.isLoadCardSale(posVC.saleItems, posVC.Specialitems)) {
            console.error('Specialitem count can only be one');
            return;
        }

        let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection) && !jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)

        if (!allowMultipleSaleItemsSelection && selectedItem != null) {
          Pinia.globalStore.saleItemSelected(selectedItem)
            await PositiveTS.VueInstance.$nextTick();
        }

        let saleItem: PositiveTS.Storage.Entity.SaleItem;
        if (allowMultipleSaleItemsSelection) {
            saleItem = posVC.fetchPosVCSaleItem(selectedItem)
        }
        else {
            saleItem = posVC.saleItemForSelectedRow();
        }
        if (saleItem.isPNRItem) {
            console.error('PNR Item count can only be one');
            app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('elal.errors.cannotChangeQuantityPNRItem'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
            });
            return;
        }
        let tmpItem = saleItem.clone()
        let originalQuantity = saleItem.quantity;

        let newQuantity = quantityToUpdate;

        // Don't allow 0 quantity - in case previous quantity was -1....
        if (newQuantity == 0) {
            newQuantity = 1;
            if (session.pos.useMinPriceForNegItem == true) {
                if (saleItem.priceBeforeChangedToMin > 0) {
                    saleItem.unitPrice = saleItem.priceBeforeChangedToMin;
                }
            }
        }

        tmpItem.quantity = newQuantity - originalQuantity
        if (posVC.canSaleItemBePersisted(tmpItem) === true) {
            saleItem.quantity = newQuantity
            await posVC.persistSaleItem(saleItem);
        }
    },
    async duplicateItem(selectedItem: PositiveTS.Storage.Entity.SaleItem) {
        if (PositiveTS.Service.SmartVoucher.isLoadCardSale(posVC.saleItems, posVC.Specialitems)) {
            console.error('Specialitem count can only be one');
            return;
        }

        let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)
        let dalpakTablesView = jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView)

        if (allowMultipleSaleItemsSelection && !dalpakTablesView) {
            console.error('Not implemented, for now its only for self service use, if you want to use it in other places please implement multi select');
            return;
        }
        if (selectedItem.isPNRItem) {
            console.error('Cannot duplicate PNR Item');
            app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('elal.errors.cannotDuplicatePNRItem'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
            });
            return;
        }

        let saleItem: PositiveTS.Storage.Entity.SaleItem = selectedItem

        if (!allowMultipleSaleItemsSelection && selectedItem != null) {
          Pinia.globalStore.saleItemSelected(selectedItem)
            await PositiveTS.VueInstance.$nextTick();
        }


        const duplicateSaleItemFunction = (saleItem) => {
            let newSaleItem = (new PositiveTS.Storage.Entity.SaleItem())
            saleItem = saleItem.clone()
            saleItem._new = true;
            newSaleItem.importFromObject(saleItem.exportToObject());

            return newSaleItem;
        }

        let newSaleItem: any = duplicateSaleItemFunction(saleItem);
        newSaleItem.rowNumber = posVC.getRowNumber();


        if (saleItem.children) {
            newSaleItem.children = saleItem.children.map(saleItemChild => {

                let childItem = duplicateSaleItemFunction(saleItemChild)

                if (saleItemChild.children) {
                    childItem.children = saleItemChild.children.map(duplicateSaleItemFunction);
                }

                return childItem;
            });
        }

        if (!posUtils.isBlank(newSaleItem.children)) {
            newSaleItem.persistWithChildren(PositiveTS.Promotions.NewPromotionsEngine.checkIfHasSomeItemGroupsPromotion());
        }

        posVC.saleItems.push(newSaleItem);
        await posVC.parameterRequireSalespersonHandler(newSaleItem);
        await posVC.afterSaleItemPersisted(newSaleItem)

        if (document.querySelector(".order-items") && jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS)) {
            document.querySelector(".order-items").scrollTop = document.querySelector(".order-items").scrollHeight
        }

    },
    async editItemWWithPrepInstructions(): Promise<any> {
        try {
            let saleItem: PositiveTS.Storage.Entity.SaleItem
            let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)
            let dalpakTablesView = jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView)

            if (allowMultipleSaleItemsSelection && Pinia.globalStore.multipleSelectedSaleItems.length > 1 && !dalpakTablesView) {
                app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('pos.selectOnlyOneGroupItemToEdit'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                })
                return
            }

            if (allowMultipleSaleItemsSelection && !dalpakTablesView) {
                saleItem = posVC.fetchPosVCSaleItem(Pinia.globalStore.multipleSelectedSaleItems[0])
            } else {
                saleItem = posVC.saleItemForSelectedRow()
            }

            if (saleItem == null) {
                console.error('Row is not associated with any sale items');
                return Promise.resolve();
            }
            let itemCopy: PositiveTS.Storage.Entity.SaleItem = _.cloneDeep(saleItem);//$.extend(true,{},saleItem);
            let editedItem = await Pinia.componentsStore.openComponent({ componentName: "prepInstructionsDialog", args: [saleItem, 0, null, true] });

            if (editedItem === null) {
                Object.assign(saleItem, itemCopy); //if the user cancelled - revert back to the original item
                return;
            }
            return posVC.afterSaleItemPersisted(editedItem)
        } catch (err) {
            console.error(err);
            return Promise.reject(err);
        }
    },
    async editItemWithGroups(): Promise<any> {
        let saleItem: PositiveTS.Storage.Entity.SaleItem
        let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)
        let dalpakTablesView = jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView)

        if (allowMultipleSaleItemsSelection && Pinia.globalStore.multipleSelectedSaleItems.length > 1 && !dalpakTablesView) {
            app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('pos.selectOnlyOneGroupItemToEdit'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
            })
            return
        }

        if (allowMultipleSaleItemsSelection && !dalpakTablesView) {
            saleItem = posVC.fetchPosVCSaleItem(Pinia.globalStore.multipleSelectedSaleItems[0])
        } else {
            saleItem = posVC.saleItemForSelectedRow()
        }

        if (saleItem == null) {
            console.error('Row is not associated with any sale items');
            return Promise.resolve();
        }
        if (saleItem.isPNRItem) {
            console.error('Cannot edit PNR Item');
            app.showAlert({
                header: i18next.t('error'),
                content: i18next.t('elal.errors.cannotEditPNRItem'),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
            });
            return Promise.resolve();
        }
        if (saleItem.level == 2) { //this should never happen but just in case
            console.error('Cannot edit grand child item');
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            let itemCopy: PositiveTS.Storage.Entity.SaleItem = _.cloneDeep(saleItem);//$.extend(true,{},saleItem);
            if (saleItem.level == 1) {
                let parentItem = saleItem.parent;
                if (!parentItem) {
                    return reject('No Parent found for item')
                }

                let index = parentItem.children.findIndex(item => item.rowNumber == saleItem.rowNumber)
                Pinia.componentsStore.openComponent({ componentName: "itemWithGroupsDialog", args: [saleItem, 0, null, true] })
                    .then((rootItem: PositiveTS.Storage.Entity.SaleItem) => {
                        if (rootItem === null) {
                            itemCopy.children = itemCopy.children.map(child => { // when  you cancel the edit the parent is kept for some reason so I clear it
                                child._parent = null
                                return child
                            })
                            Object.assign(saleItem, itemCopy); //if the user cancelled - revert back to the original item
                            parentItem.children[index] = itemCopy;
                            return resolve();
                        }
                        else {

                            parentItem.children[index] = rootItem;
                            PositiveTS.Storage.Entity.SaleItem.flattenItems([rootItem]).forEach(item => item._new = true)
                            parentItem.persistWithChildren(PositiveTS.Promotions.NewPromotionsEngine.checkIfHasSomeItemGroupsPromotion());


                            posVC.afterSaleItemPersisted(parentItem)
                            resolve();

                        }
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    })
            }
            else {
                Pinia.componentsStore.openComponent({ componentName: "itemWithGroupsDialog", args: [saleItem, 0, null, true] })
                    .then((rootItem: PositiveTS.Storage.Entity.SaleItem) => {

                        if (rootItem === null) {
                            itemCopy.children = itemCopy.children.map(child => { // when  you cancel the edit the parent is kept for some reason so I clear it
                                child._parent = null
                                return child
                            })
                            Object.assign(saleItem, itemCopy); //if the user cancelled - revert back to the original item
                            return resolve();
                        }
                        PositiveTS.Storage.Entity.SaleItem.flattenItems([rootItem]).forEach(item => item._new = true)
                        rootItem.persistWithChildren(PositiveTS.Promotions.NewPromotionsEngine.checkIfHasSomeItemGroupsPromotion());

                        posVC.afterSaleItemPersisted(rootItem)
                        resolve();

                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    })
            }

        })

    },
    async confirmMultipleSaleItemsDelete() {
        let saleItemsToDelete = this.selectedItems
        let numberOfSaleItemsToDelete = saleItemsToDelete.length

        if (numberOfSaleItemsToDelete == 0) {
            await app.promiseShowAlert({
                header: i18next.t("confirmSaleItemDeleteHeader"),
                content: i18next.t("noItemsSelectedToDelete"),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
            }, null, null)

            return
        }

        let hasMultipleSelections = numberOfSaleItemsToDelete > 1
        let confirmItemsDelete = true

        if (hasMultipleSelections) {
            confirmItemsDelete = await app.promiseShowAlert({
                header: i18next.t("confirmSaleItemDeleteHeader"),
                content: i18next.t('confirmMultipleSaleItemsDelete', { numberOfSaleItems: numberOfSaleItemsToDelete }),
                continueButtonText: i18next.t("ok"),
            }, null, null) == 'continue'
        }

        if (confirmItemsDelete) {
            for (let saleItem of saleItemsToDelete) {
                posVC.confirmSaleItemDelete(saleItem, hasMultipleSelections)
                this.selectItem(saleItem)
            }
        }
    },
    selectDiscountHandler() {
        let dalpakTablesView = jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView)

        let allowMultipleSaleItemsSelection = jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)
        if (allowMultipleSaleItemsSelection && !dalpakTablesView) {

            let allItemsCanGetDiscount = this.selectedItems.every(selectedSaleItem => Pinia.globalStore.canMakeItemDiscount(selectedSaleItem))
            if (!allItemsCanGetDiscount) {
                app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('pos.cannotAddDiscountOnNonDiscountableItems'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                });

                return
            }
        }
        Pinia.globalStore.setMultipleSaleItemSelections(this.selectedItems)

        posVC.selectDiscountForSaleItem()

        this.resetItems()

    },
    async moveDalpak() {
        let saleItems = [];
        let dalpakToMoveTo = await Pinia.componentsStore.openComponent({ componentName: "selectDalpakFromListDialog" });

        if (dalpakToMoveTo) {
            saleItems = this.selectedItems
            await PositiveTS.Service.Dalpak.moveItemsFromCurrentDalpak(saleItems, dalpakToMoveTo);
            this.resetItems()
        }
    },
}

const posStore = defineStore('pos', {
    state: () => ({ ...state }),
    getters: { ...getters },
    actions: { ...actions }
});

export default posStore;
