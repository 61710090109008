module PositiveTS {
export module Helper {
export module ReportsHelper {

	export async function backupSales(){
		try {	
			let sales = await appDB.sales.where('invoiceSequence').above(0).toArray()
			//in case backup failed before - delete the current sales from the backup before backing them up again
			await appDB.backupSales.bulkDelete(sales.map(sale => sale.id))
			await appDB.backupSales.bulkAdd(sales);
			
			let timestamp = new Date().getTime();
			await appDB.backupSales.where('zNumber').equals(-1).modify({zNumber: timestamp})
			let monthAgoTimestamp = moment(new Date()).subtract(1,'months').toDate().getTime();
			let deleteCount = await appDB.backupSales.where('invoiceSequence').belowOrEqual(0).or('timestamp').below(monthAgoTimestamp).delete();

			console.debug(`deleted ${deleteCount} sales`);
	}
		catch(err) {
			console.error(err);
			throw new Error(i18next.t("zReport.dbOrDiskError")); //TODO: check if this closes the z report
	}
	}

	export function convertServerReportVouchersListToZReportLocalVouchersList(serverVouchersList) {
		var localVouchersList = {};

		for (var i = 0; i < serverVouchersList.length; i++) {
			localVouchersList[serverVouchersList[i]['voucher_type_id']] = serverVouchersList[i]['amount'];
		}

		return localVouchersList;
	}
	export function convertServerReportPaymentsListToZReportLocalPaymentList(serverPaymentsList) {
		var paymentTypeModel = new PositiveTS.Storage.Entity.PaymentType();

		return paymentTypeModel.getCalcuatedPaymentTypesObject()
		.then(function(payments:any) {

			// Add amount field to all payments
			for (var index in payments) {
				payments[index].amount = 0;
				payments[index].count = 0;
			}

			payments[99] = {amount: 0, displayName: i18next.t('zxReport.producingVouchers'), isInXZ: 1, seq: 99, count: 0};

			for (var i = 0; i < serverPaymentsList.length; i++) {
				if(serverPaymentsList[i]['payment_type_id'] == Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL) {
					continue ;
				}
				payments[Number(serverPaymentsList[i]['payment_type_id'])].amount = serverPaymentsList[i]['amount'];
				payments[Number(serverPaymentsList[i]['payment_type_id'])].count = serverPaymentsList[i]['count'];
				
			}

			return payments;
		});
	}
	export function convertServerReportToZReport (serverReport, localZReport) {
		var zReport = new Storage.Entity.ZReport();

		var reportData = JSON.parse(serverReport.data);

		return convertServerReportPaymentsListToZReportLocalPaymentList(reportData['z_report']['payments'])
		.then(async function (payments) {
			zReport.zNumber 							= serverReport.zNumber;
			zReport.tenantID 							= serverReport.tenant_id;
			zReport.companyID 						= serverReport.company_id;
			zReport.storeID 							= serverReport.store_id;
			zReport.posPhysicalID 				= session.pos.physicalID;
			zReport.creditCardSummary = serverReport.credit_card_types_summary || reportData['z_report']['credit_card_summary'];
			zReport.isPrimary 				    = reportData['z_report']['is_primary'];
			zReport.primaryZReportNumber 	= reportData['z_report']['primary_z_report_number'] || serverReport.zNumber;
			zReport.posDeviceID 				  = reportData['z_report']['pos_device_id'];
			zReport.createdAt 					  = reportData['z_report']['created_at'];
			zReport.totalSales 					  = reportData['z_report']['total_sales'];
			zReport.creditSales 					= reportData['z_report']['credit_sales'];
			zReport.averageSale						= reportData['z_report']['average_sale'] || 0;
			zReport.averageItemPrice			= reportData['z_report']['average_item_price'] || 0;
			zReport.totalSittingDiners					  = reportData['z_report'].totalSittingDiners || 0;
			zReport.totalDeliveriesDiners				  = reportData['z_report'].totalDeliveriesDiners || 0;
			zReport.totalSittingDinersSalesAmount	  = reportData['z_report'].totalSittingDinersSalesAmount || 0;
			zReport.totalDeliveriesDinersSalesAmount = reportData['z_report'].totalDeliveriesDinersSalesAmount || 0;
			zReport.salesAbove5k					= reportData['z_report']['sales_above_5k'] || 0;
			zReport.totalCreditQty        = reportData['z_report']['total_credit_qty'] || 0;
			zReport.totalCreditAmount     = reportData['z_report']['total_credit_amount'] || 0;
			zReport.totalReturnQty        = reportData['z_report']['total_return_qty'] || 0;
			zReport.totalHakafaTamash     = reportData["z_report"]["totalHakafaTamash"] || 0;
			zReport.tamashTotalAmount     = reportData["z_report"]["tamashTotalAmount"] || 0;
			zReport.tamashCount           = reportData["z_report"]["tamashCount"] || 0;
			zReport.totalHakafa           = reportData["z_report"]['totalHakafa'] || 0;
			zReport.tenbisTotalAmount     = reportData["z_report"].tenbisTotalAmount || 0;
			zReport.tenbisCount           = reportData["z_report"]["tenbisCount"] || 0;
			zReport.mishlohaTotalAmount     = reportData["z_report"].mishlohaTotalAmount || 0;
			zReport.mishlohaCount           = reportData["z_report"]["mishlohaCount"] || 0;
			zReport.mishlohaCashTotalAmount     = reportData["z_report"]["mishlohaCashTotalAmount"] || 0;
			zReport.mishlohaCashCount           = reportData["z_report"]["mishlohaCashCount"] || 0;
			zReport.goodiTotalAmount     = reportData["z_report"].goodiTotalAmount || 0;
			zReport.goodiCount           = reportData["z_report"]["goodiCount"] || 0;
			zReport.dtsTotalAmount     = reportData["z_report"].dtsTotalAmount || 0;
			zReport.dtsCount           = reportData["z_report"]["dtsCount"] || 0;
			zReport.paiditTotalAmount     = reportData["z_report"].paiditTotalAmount || 0;
			zReport.paiditCount     = reportData["z_report"].paiditCount || 0;
			zReport.yaadTotalAmount     = reportData["z_report"].yaadTotalAmount || 0;
			zReport.yaadCount           = reportData["z_report"]["yaadCount"] || 0;
			zReport.cibusTotalAmount      = reportData["z_report"].cibusTotalAmount || 0;
			zReport.cibusCount            = reportData["z_report"]["cibusCount"] || 0;
			zReport.valuTotalAmount       = reportData["z_report"].valuTotalAmount || 0;
			zReport.valuCount             = reportData["z_report"]["valuCount"] || 0;
			zReport.totalReturnAmount     = reportData['z_report']['total_return_amount'] || 0;
			zReport.totalDiscountsAmount  = reportData['z_report']['total_discounts_amount'] || 0;
			zReport.totalItems 						= reportData['z_report']['total_items'] || 0;
			zReport.averageQtyPerCustomer = reportData['z_report']['average_qty_per_customer'] || 0;
			zReport.debitSales 					  = reportData['z_report']['debit_sales'];
			zReport.totalPayments 				= Number(reportData['z_report']['total_payments']);
			zReport.totalVat 							= Number(reportData['z_report']['total_vat']);
			zReport.syncStatus 						= PositiveTS.Storage.Entity.ZReport.SYNC_STATUS_SYNCED_SUCCESFULLY;
			zReport.syncLastMessage 			=i18next.t('successfullySent');
			zReport.syncLastMessageTimestamp 	= reportData['z_report']['created_at'];
			zReport.confirmationNumber 			= "";
			zReport.invoicesList 				= JSON.stringify(reportData['z_report']['invoices_list']);
			zReport.hakafaCount 				= reportData['z_report']['hakafaCount'] || 0;
			zReport.shipmentAndTaxInvs = JSON.stringify(reportData['z_report']['shipment_and_tax_invs'] || []);
			zReport.withdrawalInvs = JSON.stringify(reportData['z_report']['withdrawalInvs'] || []);
			zReport.payments 					= JSON.stringify(payments);
			zReport.emvTransmitReport			= JSON.stringify(reportData['z_report'].emvTransmitReport);
			zReport.gatewayTransmitReport			= reportData['z_report']["gatewayTransmitReport"] ? JSON.stringify(reportData['z_report']["gatewayTransmitReport"]) : '{}';
			zReport.deliveryApiTotals			= reportData['z_report'].deliveryApiTotals;
			

			var voucherConvertedList = convertServerReportVouchersListToZReportLocalVouchersList(reportData['z_report']['vouchers']);
			zReport.vouchers 					= JSON.stringify(voucherConvertedList);
			zReport.withdrawnCash				= reportData['z_report']['withdrawnCash'] || 0;
			zReport.tipsCount					= reportData['z_report']['tipsCount'] || 0;
			zReport.tipsAmount						= reportData['z_report']['tipsAmount'] || 0;
			zReport.withoutTransmit				= reportData['z_report']['withoutTransmit'] || 0;
			zReport.withdrawnCount				= reportData['z_report']['withdrawnCount'] || 0;
			zReport.cashMovedToSafe				= reportData['z_report']['cashMovedToSafe'] || 0;
			zReport.enterStatement				= reportData['z_report']['enterStatement'] || 0;
			zReport.zStatement					= reportData['z_report']['zStatement'] || 0;
			zReport.roundedAmount = reportData['z_report']['roundedAmount'] || 0;
			zReport.roundedCount = reportData['z_report']['roundedCount'] || 0;
			zReport.grandTotal = reportData['z_report']['grandTotal'] || 0;
			zReport.totalHakafaDebtPayments = reportData['z_report']['total_hakafa_debt_payments'] || 0;
			zReport.totalReceipts = reportData['z_report']["total_receipts"] || 0;
			zReport.deliveriesCount = reportData['z_report']["deliveriesCount"] || 0;
			zReport.deliveriesSum = reportData['z_report']["deliveriesSum"] || 0;
			zReport.externalOrdersAmount				= reportData['z_report']['externalOrdersAmount'] || 0;
			zReport.salesDiscountReport = reportData['z_report']['salesDiscountReport']
			zReport.currenciesSummary = reportData['z_report']['currencies_summary'] || '{}';
			if (localZReport?.primaryCategoryStats){
				zReport.primaryCategoryStats = localZReport.primaryCategoryStats
			}
			if (localZReport?.itemDetails){
				zReport.itemDetails = localZReport.itemDetails
			}
			
			return zReport;
		});
	}
	export function loadZReportsFromServer () {
		return PositiveTS.Service.ZReport.getReports(session.pos.deviceID)
		.then(function (serverReports:any) {
			if(serviceZMasterSlave.isZMasterSlave && serviceZMasterSlave.role == serviceZMasterSlave.ROLES.master) {
				let masterReports = {};
				for(let zNumber in serverReports) {
					if(serverReports[zNumber].is_primary) {
						masterReports[zNumber] = serverReports[zNumber];
					}
				}
				serverReports = masterReports;
			}
			return posUtils.mapPromises(serverReports, function (serverReport) {
				return convertServerReportToZReport(serverReport, {})
				.then(function (reportEntity) {
					return reportEntity;
				});
			});
		});
	}
	export function createNewZReportObjectFromSession ():Storage.Entity.ZReport {
		var zReport = new Storage.Entity.ZReport();
		zReport.zNumber 					= -1;
		zReport.tenantID 					= session.pos.tenantID;
		zReport.companyID 					= session.pos.companyID;
		zReport.storeID 					= session.pos.storeID;
		zReport.posPhysicalID 				= session.pos.physicalID;
		zReport.posDeviceID 				= session.pos.deviceID;
		zReport.createdAt 					= PositiveTS.DateUtils.fullFormat();
		zReport.syncStatus 					= PositiveTS.Storage.Entity.ZReport.SYNC_STATUS_NEW;
		zReport.syncLastMessage 			= '';
		zReport.syncLastMessageTimestamp	= PositiveTS.DateUtils.fullFormat();

		return zReport;
	}
	async function getSalesForNewZReport ():Promise<Array<Storage.Entity.Sale>> {
		let sales = await getSalesForNewZReportNoImport();
		return sales.map(sale => Storage.Entity.Sale.import(sale))
	}

	export async function getSalesForNewZReportNoImport():Promise<Array<Storage.Entity.Sale>> {
		let sales = await appDB.sales.where('zNumber').equals(Storage.Entity.Sale.NULL_Z_REPORT)
											.and(sale => sale.invoiceSequence > -1).toArray();
		return sales;
	}
	

	export function showZReportError(errorMsg:string, callback?) {
		if(PositiveTS.Service.ZReportAll.isRemoteAllZActive){
			
			if(errorMsg == i18next.t('zReportCreatingErrorNoSales')){
				socketManager.channelStore.trigger('z_report_info',
				{from: session.pos.deviceID, status: Service.ZReportAll.STATUS.FINISHED, message: i18next.t('ZReportAllProgressDialog.noSales')});
				
			}else{
				socketManager.channelStore.trigger('z_report_info',
			 	{from: session.pos.deviceID, status: Service.ZReportAll.STATUS.ERROR, message: errorMsg}
			 );
			}
			
		}
		app.showAlert({
			header: i18next.t('zReport.title'),
			content: errorMsg,
			continueButtonText: i18next.t("ok"),
			hideCancelButton: true
		}, function () {
			if (typeof callback === "function") {
				callback();
			}
		}, null);
	}

	export function initOtherPaymentMethodsIfNeeded(payments) {
		if (! payments[Storage.Entity.SalePayment.METHOD_PRAXELL]) {
			payments[Storage.Entity.SalePayment.METHOD_PRAXELL] = {amount: 0, displayName: i18next.t('zReport.paraxelCardLoading'), isInXZ: 1, seq: Storage.Entity.SalePayment.METHOD_PRAXELL};
		}

		if (! payments[Storage.Entity.SalePayment.METHOD_VALU]) {
			payments[Storage.Entity.SalePayment.METHOD_VALU] = {amount: 0, displayName: i18next.t('zReport.insideCardLoading'), isInXZ: 1, seq: Storage.Entity.SalePayment.METHOD_VALU};
		}

		if (! payments[Storage.Entity.SalePayment.METHOD_GPP]) {
			payments[Storage.Entity.SalePayment.METHOD_GPP] = {amount: 0, displayName: i18next.t('zReport.cellularCard'), isInXZ: 1, seq: Storage.Entity.SalePayment.METHOD_GPP};
    	}

		if (! payments[Storage.Entity.SalePayment.METHOD_MULTIPASS]) {
			payments[Storage.Entity.SalePayment.METHOD_MULTIPASS] = {amount: 0, displayName: i18next.t('zReport.multipassCardsLoading'), isInXZ: 1, seq: Storage.Entity.SalePayment.METHOD_MULTIPASS};
		}

		if (! payments[Storage.Entity.SalePayment.METHOD_VALUE_CARD]) {
			payments[Storage.Entity.SalePayment.METHOD_VALUE_CARD] = {amount: 0, displayName: i18next.t('zReport.valuCardLoading'), isInXZ: 1, seq: Storage.Entity.SalePayment.METHOD_VALUE_CARD};
		}

		if (! payments[Storage.Entity.SalePayment.METHOD_OTOT]) {
			payments[Storage.Entity.SalePayment.METHOD_OTOT] = {amount: 0, displayName: i18next.t('zReport.ototLoading'), isInXZ: 1, seq: Storage.Entity.SalePayment.METHOD_OTOT};
		}

		if(! payments[Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL]) {
			payments[Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL] = {amount: 0, displayName: i18next.t('zReport.cashWIthdrawal'), isInXZ: true, seq:Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL}
		}
	}

	function isDataMehtod(method:number) {
		let pay = Storage.Entity.SalePayment;
		return [pay.METHOD_CHECK,
			pay.METHOD_CREDIT,
			pay.METHOD_CREDIT_VOUCHER,
			pay.METHOD_GPP,
			pay.METHOD_MULTIPASS,
			pay.METHOD_PRAXELL,
			pay.METHOD_VALU,
			pay.METHOD_VALUE_CARD,
			pay.METHOD_VOUCHER,
			pay.METHOD_OTOT].indexOf(method) >= 0;
	}

	export async function createReportPaymentsJson (salePayments:Storage.Entity.SalePayment[]) {
		let payments:any = await (new PositiveTS.Storage.Entity.PaymentType()).getCalcuatedPaymentTypesObject()
		initOtherPaymentMethodsIfNeeded(payments);

		// Add amount and count fields to all payments
		for (let index in payments) {
				payments[index].amount = 0;
			payments[index].count = 0;
		}

		let cashWithdrawals = await Service.Withdrawal.getWithdrawalsXZ();
		payments[Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL].amount = cashWithdrawals.sum;
		payments[Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL].count = cashWithdrawals.count;

		let nonHakafaPayments = salePayments.filter( (row)=> {
				return [Storage.Entity.Sequence.TYPE_SHIPMENT_INV, Storage.Entity.Sequence.TYPE_TAX_INV,
								Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV,
               	Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV ].indexOf( row.invoiceType ) === -1});

		for (let salePayment of nonHakafaPayments) {
			
				// if this is Combined Z, the total is already calculated, and no .data exists
				if (salePayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER) {
					var creditVoucherData = JSON.parse(salePayment.data);

          if (creditVoucherData) {
					for (let currCreditVoucher of creditVoucherData) {

						let creditVoucherType = currCreditVoucher.creditVoucherType;

              if (creditVoucherType == PositiveTS.Storage.Entity.SalePayment.CREDIT_VOUCHER_TYPE_DEBIT) {
                payments[salePayment.method].amount += currCreditVoucher.amount;
              } else if (creditVoucherType == PositiveTS.Storage.Entity.SalePayment.CREDIT_VOUCHER_TYPE_CREDIT) {
                payments[PositiveTS.Storage.Entity.SalePayment.METHOD_CREDIT_VOUCHER_CHANGE].amount -= currCreditVoucher.amount;
              }
            }
          } else {
            // if this is Combined Z, the total is already calculated, and no .data exists
            payments[salePayment.method].amount += salePayment.amount;
          }


				}
			else if ( salePayment.method == Storage.Entity.SalePayment.METHOD_PRAXELL || 
								salePayment.method == Storage.Entity.SalePayment.METHOD_VALU || 
								salePayment.method == Storage.Entity.SalePayment.METHOD_MULTIPASS || 
								salePayment.method == Storage.Entity.SalePayment.METHOD_VALUE_CARD || 
								salePayment.method == Storage.Entity.SalePayment.METHOD_GPP || 
								salePayment.method == Storage.Entity.SalePayment.METHOD_OTOT) {
				let creditVoucherData = JSON.parse(salePayment.data);

          // Add ammount to total
          // if creditVoucherData, not exists, the amount is already in correct sign
          // calculated by the combined Z on the server
          if (creditVoucherData) {
						payments[salePayment.method].amount -= creditVoucherData[0].amount;
          } else {
            payments[salePayment.method].amount += salePayment.amount;
          }
			}
			else {
        payments[salePayment.method].amount += salePayment.amount;
      }

			//count the number of transactions for each payment type
			if (salePayment.countForPriZ != null) {
				payments[salePayment.method].count += salePayment.countForPriZ;
			}
			else {
				if (isDataMehtod(salePayment.method)) {
					let data = JSON.parse(salePayment.data)
					for (let pd of data) {
						payments[salePayment.method].count++;
					}
				}
				else {
					payments[salePayment.method].count++;
					}
				}
				// clean floating point extra zeros if exists
				payments[salePayment.method].amount = session.fixedFloat( payments[salePayment.method].amount );


			}
			payments[PositiveTS.Storage.Entity.SalePayment.METHOD_CASH].amount -= payments[PositiveTS.Storage.Entity.SalePayment.METHOD_CHANGE].amount;
			payments[PositiveTS.Storage.Entity.SalePayment.METHOD_CASH].amount = session.fixedFloat( payments[PositiveTS.Storage.Entity.SalePayment.METHOD_CASH].amount );

			return payments;
	}

	export function createReportVouchersJson (salePayments) {

		var vouchers = {};
      for (var i = 0; i < salePayments.length; i++) {
        var salePayment = salePayments[i];

				let isNotShipment = ([Storage.Entity.Sequence.TYPE_SHIPMENT_INV, Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV].indexOf(salePayment.invoiceType) === -1)
				let isMethodVoucher = salePayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER || salePayment.method == PositiveTS.Storage.Entity.SalePayment.METHOD_VOUCHER_DISCOUNT

        if (isNotShipment && isMethodVoucher) {
          var voucherData = JSON.parse(salePayment.data);

            for (var voucherIndex = 0; voucherIndex < voucherData.length; voucherIndex++) {
              var currVoucher = voucherData[voucherIndex];
							if (currVoucher.voucher_type_id == Service.Hakafa.VOUCHER_TYPE_ID) {
								continue; //don't count the hakafa voucher in the Z's vouchers
							}
              if (vouchers[currVoucher.voucher_type_id] === null || vouchers[currVoucher.voucher_type_id] === undefined) {
                vouchers[currVoucher.voucher_type_id] = 0;
              }

              vouchers[currVoucher.voucher_type_id] += currVoucher.amount;
            }


        }
      }

		return vouchers;
	}

	export async function calculateZReport(salePayments:PositiveTS.Storage.Entity.SalePayment[], isZ = true) {
		salePayments = salePayments.filter( (row => {return row.invoiceType !== PositiveTS.Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE && 
															row.invoiceType !== PositiveTS.Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_INVOICE && 
															row.invoiceType !== PositiveTS.Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_REFUND_INVOICE }))
		let payments = await createReportPaymentsJson(salePayments)
		
		let vouchers =  createReportVouchersJson(salePayments);

		let currenciesSummary = !isZ ? calculateCurrenciesSummary(salePayments) : undefined;

		return { payments: payments, vouchers: vouchers, 
				salePayments: salePayments, currenciesSummary: currenciesSummary,
				isStoreXReport: false };
	}

	function calculateCurrenciesSummary(payments: Storage.Entity.SalePayment[]) {
		let MultiCurr = Service.MultiCurr.getInstance();
		if (MultiCurr.isMultiCurr() === false) {
			return {};
		}
		let calculator = new PositShared.CalcCurrenciesSummary(MultiCurr.getPosCurrency(), i18next)
		let currenciesSummary =  calculator.calcCash(payments);
		return currenciesSummary;
	}

	export function calcuateZReportTotalAmount(zReport) {

		let payments = JSON.parse(zReport.payments);

		let totalPayments = 0;

		for (let method in payments) {
			if (posUtils.isNullOrUndefined(payments[method].amount)) {
				payments[method].amount = 0;
			}

			if(payments[method].isInXZ == 1 && Number(method) != Storage.Entity.SalePayment.METHOD_CASH_WITHDRAWAL) {
				totalPayments += payments[method].amount;
			}
		}

		return totalPayments;
	}

	export function ensureAllEmpHasTimeReportComplete(){
		return new Promise(function(resolve, reject) {

			PositiveTS.Service.EmployeeTimeReport.getEmployeeWithUnclosedTimeSheet()
			.then(function(employeeWithNoExitTimeSheet){
				if (employeeWithNoExitTimeSheet.length > 0) {
					app.showAlert({
						header: "",
						content: "העובדים הבאים ביצעו כניסה ולא ביצעו יציאה: "
						+ employeeWithNoExitTimeSheet.join(",") + "\n - "
						 					+ " האם להמשיך? ",
						continueButtonText: i18next.t("ok"),
						cancelButtonText: i18next.t('cancel')
					}, function () {
						resolve();
					}, function(){
						reject(new Error('userCanceled'));
					});
				} else {
					resolve();
				}

			})

		});
	}
	export async function isZreportPreRequesitsValid():Promise<{valid:boolean, error:string}> {

		let sales = await getSalesForNewZReport();

			let offlineSales = sales.filter(sale => sale.syncStatus != 2)
			if (offlineSales.length > 0) {
				return {valid: false, error: "offlineSalesExist" } ;
			}

			if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
				if (Service.Dalpak.isPrimaryPosOrSinglePos()) {
					
					let openedDalpaks = await Service.Dalpak.getAllDalpaksWithOpenedSales();

					if (posUtils.isNullOrUndefined(openedDalpaks)) {
						return {valid: false, error: "cantGetDalpaks" } ;
					}

					if (openedDalpaks.length > 0) {
						return {valid: false, error: "dalpaksAreOpen" } ;
					}
				}
			} 
			
			if (jsonConfig.getVal(jsonConfig.KEYS.prohibitZWhenSalesOnHold)) {
				let inHoldSales = await Service.HoldSale.salesInHoldCollection()

				if(inHoldSales.length > 0){
					return {valid: false, error: "salesOnHoldExist" } ;
				}	
			}

			if (sales.length < 1) {
				if (serviceZMasterSlave.isZMasterSlave &&
					serviceZMasterSlave.role == serviceZMasterSlave.ROLES.master) {
					return {valid: true, error: ""};
				} else {
					return {valid: false, error: "noSalesError" };
				}
			}
				
			return { valid: true, error: ""};
			// Error: no sales to add to the z report, unable to create z report
	}


	async function getZStats(sales:Array<Storage.Entity.Sale>, zReport:Storage.Entity.ZReport):Promise<Array<Storage.Entity.Sale>> {
		
		let totalVat = 0;
		let relevantSales = [];
		let invoicesList = [];
		let shipmentAndTaxInvs = [];
		let withdrawalInvs = [];
		let totalSaleAmount = 0;
		let salesAbove5k = 0;
		let totalSittingDiners = 0;
		let totalSittingDinersSalesAmount = 0;
		let totalDeliveriesDiners = 0;
		let totalDeliveriesDinersSalesAmount = 0;
		let saleCount = 0
		let creditSales = 0;
		let debitSales = 0;
		let totalItems = 0;
		let totalCreditQty = 0;
		let totalCreditAmount = 0;
		let totalDiscounts = 0;
		let totalReceipts = 0;
		let totalHakafaDebtPayments = 0;
		let roundedAmount = 0;
		let roundedCount = 0;

		for (let sale of sales) {

			if (sale.invoiceSequence && sale.invoiceSequence > 0) {

				relevantSales.push(sale);

				if ([Storage.Entity.Sequence.TYPE_PUNCH_CARD_INVOICE].indexOf(sale.invoiceType) > -1) {
					continue;
				}

				if([Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_INVOICE, 
					Storage.Entity.Sequence.TYPE_CASH_WITHDRAWAL_REFUND_INVOICE].indexOf(sale.invoiceType) > -1) {
						withdrawalInvs.push({
							invoice_number: sale.invoiceSequence,
							invoice_type: sale.invoiceType
						});
					continue;
				}

				if(!posUtils.isNullOrUndefined(sale.roundAmount) && sale.roundAmount != 0) {
					roundedAmount += sale.roundAmount;
					roundedCount++;
				}

				if (Storage.Entity.Sequence.isDebitMethod(sale.invoiceType)) {
					debitSales++;
					if (sale.invoiceType == Storage.Entity.Sequence.TYPE_RECEIPT) {
						totalReceipts += sale.totalAmount;
					}
					else {
						if (sale.totalAmount >= 5000) {
							salesAbove5k++
						}
						// totalItems += Number(sale.totalQuantity)
						totalSaleAmount += sale.totalAmount;
						totalDiscounts += sale.totalDiscount;
					}
				} else {
					creditSales++;
					// totalCreditQty += Number(sale.totalQuantity);
					totalCreditAmount += sale.totalAmount;
				}

				if ([Storage.Entity.Sequence.TYPE_SHIPMENT_INV, 
					Storage.Entity.Sequence.TYPE_CREDIT_SHIPMENT_INV].indexOf(sale.invoiceType) > -1) {
					shipmentAndTaxInvs.push({
						invoice_number: sale.invoiceSequence,
						invoice_type: sale.invoiceType
					});
				}
				else {
					saleCount++

					totalVat += SaleHelper.calculateVat(sale.totalVatableAmount != null ? sale.totalVatableAmount : sale.totalAmount, Number(sale.vat));
	
					invoicesList.push({
						invoice_number: sale.invoiceSequence,
						invoice_type: sale.invoiceType
					});
				}

			}

			if (jsonConfig.getVal(jsonConfig.KEYS.hasDiners) && posUtils.isDefined(sale.diners) && sale.diners > 0) {
				if (sale.isDelivery) {
					totalDeliveriesDiners += sale.diners;
					totalDeliveriesDinersSalesAmount += sale.amountWithoutSplit;
				} else {
					totalSittingDiners += sale.diners;
					totalSittingDinersSalesAmount += sale.amountWithoutSplit;
				}
			}
		}

		
		zReport.invoicesList = JSON.stringify(invoicesList);
		zReport.shipmentAndTaxInvs = JSON.stringify(shipmentAndTaxInvs);
		zReport.withdrawalInvs = JSON.stringify(withdrawalInvs);
		let response = await Service.FetchReq.jsonReq('/z_item_qty_stats','post', zReport)
		zReport.totalItems = response.result.total_debit_qty;
		zReport.totalCreditQty = response.result.total_credit_qty;


		let hakafaSpecialItem = Service.Hakafa.HakafaSpecialItemValidation.getHakafaSpecialItem(posVC.Specialitems)
		let hakafaSpecialItemCode = hakafaSpecialItem == null ? "9999" : hakafaSpecialItem.code;
		// zReport.totalHakafaDebtPayments = Number((await Service.db.promiseSqlRead(`
		// 		select sum(unitPrice*quantity) as total from saleItem 
		// 		inner join sale on saleItem.saleid = sale.id where itemCode=${hakafaSpecialItemCode} and 
		// 		sale.invoiceType=${Storage.Entity.Sequence.TYPE_RECEIPT}`)).rows[0]['total'])

		let items = sales.filter(sale => sale.invoiceType == Storage.Entity.Sequence.TYPE_RECEIPT)
		.map(sale => sale.items.filter(item => item.itemCode == hakafaSpecialItemCode)[0]).filter(item => item != null)
		zReport.totalHakafaDebtPayments = items.map(item => item.unitPrice*item.quantity).reduce((a,b) => a+b,0)
		
		let additionalStats = await getAdditionalItemStats(sales)
		
		zReport.averageSale = Math.round((debitSales == 0 ? 0 : totalSaleAmount/debitSales)*100)/100
		zReport.totalSales = saleCount;
		zReport.debitSales = debitSales;
		
		zReport.averageItemPrice = Math.round((totalItems == 0 ? 0 : totalSaleAmount/totalItems)*100)/100
		zReport.averageQtyPerCustomer = Math.round((debitSales == 0 ? 0 : totalItems/debitSales)*100)/100
		zReport.creditSales = creditSales;
		zReport.totalVat = totalVat;
		zReport.salesAbove5k = salesAbove5k;
		zReport.totalCreditAmount = totalCreditAmount;
		zReport.totalDiscountsAmount = totalDiscounts;
		zReport.totalReturnQty = additionalStats.totalReturnQty;
		zReport.totalReturnAmount = additionalStats.totalReturnAmount;
		zReport.totalReceipts = totalReceipts;

		if (jsonConfig.getVal(jsonConfig.KEYS.hasDiners)) {
			zReport.totalSittingDiners = totalSittingDiners;
			zReport.totalDeliveriesDiners = totalDeliveriesDiners;
			zReport.totalSittingDinersSalesAmount = totalSittingDinersSalesAmount;
			zReport.totalDeliveriesDinersSalesAmount = totalDeliveriesDinersSalesAmount;
		}

		zReport.roundedAmount = session.fixedFloat(roundedAmount, 2);
		zReport.roundedCount = roundedCount;

		let bonTotalSumAndQuantity = PositiveTS.Service.HoldSale.getBonTotalSumAndQuantityCanceled()
		zReport.canceledBonCount = bonTotalSumAndQuantity.totalQuantity;
		zReport.canceledBonSum = bonTotalSumAndQuantity.totalPrice;

		let deliveriesTotals = await Service.Delivery.calculateZ(relevantSales);


		zReport.deliveriesCount = deliveriesTotals.count;
		zReport.deliveriesSum = deliveriesTotals.sum;

		zReport.cashMovedToSafe = Service.CashierStatement.getCashSumWithdrawnToSafe();
		let withdrawals = Service.Withdrawal.getWithdrawalsCountAndAmount(sales);
		zReport.withdrawnCash = withdrawals.amount;
		zReport.withdrawnCount = withdrawals.count;
		zReport.enterStatement = Service.CashierStatement.getLastEnterCashierStatementNumber();
		zReport.zStatement = Service.CashierStatement.getCashierZStatement();

		if (Service.Tip.isHasTips()) {
			let tipsData = Service.Tip.totalSalesTipsCountAndAmount(sales);

			zReport.tipsCount = tipsData.count;
			zReport.tipsAmount = tipsData.amount;	
		}
		
		return relevantSales;

	}

	async function getAdditionalItemStats(sales:Array<Storage.Entity.Sale>):Promise<any> {
		let result = {
			totalReturnQty: 0,
			totalReturnAmount: 0
		}
		
		let wrapperSales = []
		for (let sale of sales) {
			if (Storage.Entity.Sequence.isDebitMethod(sale.invoiceType) && sale.invoiceType != Storage.Entity.Sequence.TYPE_RECEIPT) {
				let items = sale.items;
				if (items.length > 0) {
					let wrapperSale = sale.clone();
					wrapperSale.items = items;
					wrapperSales.push(wrapperSale);
				}
			}
		}

		for (let wrapperSale of wrapperSales) {
			for (let saleItem of wrapperSale.items) {
				if (saleItem.quantity < 0) {
					result.totalReturnQty += saleItem.quantity
					result.totalReturnAmount += saleItem.quantity*Service.CreditInvoiceUtils.calculateItemPriceAfterAllDiscounts(wrapperSale,saleItem)
				}
			}
		}

		return result;
	}

	export async function createNewZReport(emvZreport = null, forceWithoutPinpad = !session.pos.isEmv):Promise<{zReport: Storage.Entity.ZReport, sequence: Storage.Entity.Sequence}> {

		if (!Pinia.globalStore.isOnline) {
			throw new Error('offlineError');
		}

		let zReport:PositiveTS.Storage.Entity.ZReport = null;
		// Create new z report
		if (emvZreport) {
			zReport = emvZreport;
		}
		else {
			zReport = createNewZReportObjectFromSession();
		}

		let sales = await getSalesForNewZReport() // Get all the sales that not connected yet to a z report and add them to this z report
		
			// Error: no sales to add to the z report, unable to create z report
		if (sales.length < 1) {
			if (serviceZMasterSlave.isZMasterSlave &&
					serviceZMasterSlave.role == serviceZMasterSlave.ROLES.master) {
				return {zReport: null, sequence: null}
			} else {
				throw new Error('noSalesError');
			}
		}

		let relevantSales = await getZStats(sales,zReport)
			
		let salesPayments:Storage.Entity.SalePayment[] = [].concat.apply([],
			relevantSales.map(sale => 
				sale.payments.map(pay => {
					pay.invoiceType = sale.invoiceType; 
					return pay;
				}) 
			)
		)
		
		zReport.totalTaxInvHakafaPayments = salesPayments.filter(
				pay => pay.invoiceType == Storage.Entity.Sequence.TYPE_TAX_INV || 
				pay.invoiceType == Storage.Entity.Sequence.TYPE_TAX_CREDIT_INV).reduce((a,b) => a+b.amount,0)

		// calcuate z report objects
		let report = await calculateZReport(salesPayments)
		
		var tenbisPluxeeAmount = PositiveTS.Storage.Entity.SalePayment.getTenbisPluxeeAmount(report.salePayments);
		zReport.cibusTotalAmount = tenbisPluxeeAmount.cibusTotalAmount;
		zReport.tenbisTotalAmount = tenbisPluxeeAmount.tenbisTotalAmount;
		zReport.mishlohaTotalAmount = tenbisPluxeeAmount.mishlohaTotalAmount;
		zReport.goodiTotalAmount = tenbisPluxeeAmount.goodiTotalAmount;
		zReport.dtsTotalAmount = tenbisPluxeeAmount.dtsTotalAmount;
		zReport.yaadTotalAmount = tenbisPluxeeAmount.yaadTotalAmount;
		zReport.valuTotalAmount = tenbisPluxeeAmount.valuTotalAmount;
		zReport.tamashTotalAmount = tenbisPluxeeAmount.tamashTotalAmount;
		zReport.paiditTotalAmount = tenbisPluxeeAmount.paiditTotalAmount;
		zReport.paiditCount = tenbisPluxeeAmount.paiditCount;

		zReport.totalHakafaTamash = tenbisPluxeeAmount.totalHakafaTamash;
		zReport.totalHakafa = tenbisPluxeeAmount.totalHakafa;
		zReport.tamashCount = tenbisPluxeeAmount.tamashCount;
		zReport.hakafaCount = tenbisPluxeeAmount.hakafaCount;
		zReport.cibusCount = tenbisPluxeeAmount.cibusCount;
		zReport.tenbisCount = tenbisPluxeeAmount.tenbisCount;
		zReport.mishlohaCount = tenbisPluxeeAmount.mishlohaCount;
		zReport.goodiCount = tenbisPluxeeAmount.goodiCount;
		zReport.dtsCount = tenbisPluxeeAmount.dtsCount;
		zReport.yaadCount = tenbisPluxeeAmount.yaadCount;
		zReport.valuCount = tenbisPluxeeAmount.valuCount;
		zReport.withoutTransmit = forceWithoutPinpad;
		zReport.externalOrdersAmount = Service.ECommerceAPIService.calculateTotalExternalOrderSales(report.vouchers);
		let mishlohaTotals = Service.MishlohaService.getTotalCashOrders(sales);
		zReport.mishlohaCashTotalAmount = mishlohaTotals.amount;
		zReport.mishlohaCashCount = mishlohaTotals.count;
		zReport.deliveryApiTotals = JSON.stringify(tenbisPluxeeAmount.deliveryApiTotals);


		// save calcuated zreport data
		zReport.payments = JSON.stringify(report.payments);
		zReport.vouchers = JSON.stringify(report.vouchers);
		zReport.salesDiscountReport = JSON.stringify(createSalesDiscountReportForZReport(sales))
		
		if (!session.pos.isEmv) {
			zReport.syncStatus = PositiveTS.Storage.Entity.ZReport.SYNC_STATUS_WAITING_TO_BE_SENT;
		}

		// calcuate total amount
		zReport.totalPayments = calcuateZReportTotalAmount(zReport);

			// close the report
		await zReportVC.getPrimaryCategoryStats(zReport);
		if (jsonConfig.getVal(jsonConfig.KEYS.printItemDetailsInZReport)){
			await zReportVC.getItemDetails(zReport)
		}
		await zReportVC.getGrandTotal(zReport);
		let res = await zReport.updateSequenceAndCloseSecondaryReportIfNeeded(sales, serviceZMasterSlave.isZMasterSlave)
		return res;
		
				
	}

	function createSalesDiscountReportForZReport (sales: Array<PositiveTS.Storage.Entity.Sale>) {
		let sumSalesDiscounts = {}
		if (jsonConfig.getVal(jsonConfig.KEYS.sumSalesDiscountReportOnZReport)){
			if (session.pos.useNewPromotions){
				sumSalesDiscounts = createSalesDiscountReportForZReportNewPromotions(sales)
			}else{
				sumSalesDiscounts = createSalesDiscountReportForZReportOldPromotions(sales)
			}
		}

		return sumSalesDiscounts
	}

	function createSalesDiscountReportForZReportNewPromotions (sales: Array<PositiveTS.Storage.Entity.Sale>){
		let sumSalesDiscounts = {}
		let discounts = (new PositiveTS.Storage.Entity.Discount()).allWithoutPromise()
		let discountCodes = discounts.map(discount => discount.discountID)
		
		sales.forEach(sale => {
			let saleJsondata = JSON.parse(sale.jsondata)
			
			if (Array.isArray(saleJsondata.discounts) && saleJsondata.discounts.length){

				saleJsondata.promotions.forEach(promotion => {
					if (promotion.promotionCode.includes('D')){
						let discountCode = promotion.promotionCode.replace('D' ,'')
						if (discountCodes.includes(discountCode)){
							if (sumSalesDiscounts[promotion.promoName] == null){
								sumSalesDiscounts[promotion.promoName] = 0
							}

							sumSalesDiscounts[promotion.promoName] += promotion.discountAmountForGroup
						}
					}
				})			
			}
		})

		return sumSalesDiscounts
	}

	function createSalesDiscountReportForZReportOldPromotions (sales: Array<PositiveTS.Storage.Entity.Sale>){
		let sumSalesDiscounts = {}
		let discounts = (new PositiveTS.Storage.Entity.Discount()).allWithoutPromise()
		let discountCodes = discounts.map(discount => discount.discountID)

		sales.forEach(sale => {
			//sale discount
			if (sale.discountType == '1' && sale.saleDiscountAmount > 0 && discountCodes.includes(sale.discountID)){
				if (sumSalesDiscounts[sale.discountName] == null){
					sumSalesDiscounts[sale.discountName] = 0
				}

				sumSalesDiscounts[sale.discountName] += Number(sale.saleDiscountAmount)
			}

			//item discount
			sale.items.forEach(item => {
				if (item.discountType == '1' && item.discountAmount > 0 && discountCodes.includes(item.discountID)){
					if (sumSalesDiscounts[item.discountName] == null){
						sumSalesDiscounts[item.discountName] = 0
					}
	
					sumSalesDiscounts[item.discountName] += Number(item.discountAmount)
				}
			})
		})

		return sumSalesDiscounts
	}
}}}
