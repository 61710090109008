module PositiveTS {
    export module Components {
      export module SelfServiceGenericPrepInstructionsDialog {  
        export function create() {
  
          let component = {
            template: JST.selfServiceGenericPrepInstructionsDialog(),
            methods: {
              open (hasNoPrepInstructions = false, text = null, openAsInnerDialog = false) {
                return new Promise(async res => {
                  this.openAsInnerDialog = openAsInnerDialog;
                  this.resolve = res;
                  this.hasNoPrepInstructions = hasNoPrepInstructions;

                  if (!posUtils.isBlank(text)) {
                    this.instructionText = text;
                  }

                  await this.$nextTick();
                  
                  if (!this.mobileLayout){
                    if (this.simpleSelfService) {
                      this.$refs.simpleKeyboardInstance.setLayout(this.keyboardLayout);
                      this.$refs.simpleKeyboardInstance.setDisplay(this.keyboardDisplay);
                    }
  
                    this.$refs.simpleKeyboardInstance.setNewInput(this.$refs.textInput);  
                  }
                  this.$el.showModal();
                  this.focusInput();
                })
              },
  
              close(result = null) {
                this.$el.close();
                this.resolve(result);
                this.resolve = null;
                this.instructionText = '';
              },
              ok() {
                this.close(this.instructionText);
              },
              cancel() {
                this.close();
              },
              updateVal(text) {
                this.instructionText = text
              },
              isOpen() {
                return this.$el.open;
              },
              focusInput() {
                if (this.$refs.textInput) {
                  this.$refs.textInput.focus();
                }
              }
            },
            
            computed: {
              keyboardLayout() {
                return { 
                  default: [
                  "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
                  "/ ' \u05e7 \u05e8 \u05d0 \u05d8 \u05d5 \u05df \u05dd \u05e4 \\",
                  "\u05e9 \u05d3 \u05d2 \u05db \u05e2 \u05d9 \u05d7 \u05dc \u05da \u05e3",
                  "\u05d6 \u05e1 \u05d1 \u05d4 \u05e0 \u05de \u05e6 \u05ea \u05e5 . {shift}",
                  "{space}"
                ],
                shift: [
                  "` 1 2 3 4 5 6 7 8 9 0 {bksp}",
                  "Q W E R T Y U I O P { } |",
                  'A S D F G H J K L : "',
                  "Z X C V B N M < > ? {shift}",
                  "{space}"
                ]};
              },
              keyboardDisplay() {
                return {
                  '{continue}': i18next.t('posPayment.confirm'),
                  '{bksp}': '<i class="fa-solid fa-delete-left"></i>',
                  '{shift}': '<i class="far fa-globe"></i>'  
                }
              },
              dialogCSSClass() {
                let cssClasses = {};
                cssClasses['self-service'] = this.simpleSelfService;
                cssClasses['regular-pos'] = !this.simpleSelfService && !this.mobileLayout;
                cssClasses['p2-pro:h-auto p2-pro:w-auto p2-pro:rounded-none'] = this.openAsInnerDialog;
                return cssClasses;
              },
            },
            setup(){

              const globalStore = Pinia.useGlobalStore();
              const {simpleSelfService,mobileLayout} = Pinia.storeToRefs(globalStore);
              const globalStoreProps = {simpleSelfService,mobileLayout}

              return {...globalStoreProps}
            },
            data() {
              return {
                resolve: null,
                instructionText: '',
                hasNoPrepInstructions: false,
                openAsInnerDialog: false,
              }
            },
          }
  
          VueApp.component('self-service-generic-prep-instructions-dialog', component)
  
        }
      }
    }
  }
  
  