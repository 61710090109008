module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dialog id="prep-instructions-dialog-wrapper" :class="dialogClass" :style="{ width: width }"\n		class="p2-pro:h-auto p2-pro:max-h-unset p2-pro:max-w-unset">\n	\n	<template v-if="showSelfServiceAndDalpakTableView">\n		' +
((__t = ( JST.prepInstructionsDialogSelfService() )) == null ? '' : __t) +
'\n	</template>\n\n	<template v-else>\n		' +
((__t = ( JST.prepInstructionsDialogRegular() )) == null ? '' : __t) +
'\n	</template>\n</dialog>';

}
return __p
}