module PositiveTS {
  export const METHOD_HAKAFA = 3;
  export const TYPE_SHIPMENT_INV = 9;
  export module Components {
    const enum PosViewState {
      Buttons = 1,
      Rows,
      Actions,
    }
    export module Pos {
      function initData() {
        return {
          itemMenu: Boolean(jsonConfig.getVal(jsonConfig.KEYS.useSmartMenu)),
          showSalesPersonButton:
          (session.pos.parameterRequireSalesperson != PositiveTS.Storage.Entity.Pos.REQUIRE_DISABLED),
          showPriceChangeButton: initPriceChangeButtonState(),
          initialized: false,
          internalAmount: 0,
          internalTimes: 1,
          currentWeight: "0.000",
          lastScannedItem: {},
          fields: initFields(),
          isMultiCurr: Service.MultiCurr.getInstance().isMultiCurr(),
          isDutyFree: Service.DutyFree.isDutyFree(),
          isPickupEnabledDutyFreeOrShips: Service.Pickup.isPickupEnabled(),
          isTogglePickupAll: false,
          showActionButtons: false,
          isCaveretPos: session.pos.isCaveret,
          deliveryApis: [], // type: BaseDeliveryAPIService
          customerNameClicked: false,
          allowMultipleSaleItemsSelection: Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowMultipleSaleItemsSelection)),
          ignorePrintingOfOnlySelectedSaleItems: false,
          caspitTenants: session.pos.tenantID == "14" || session.pos.tenantID == "17" || window.location.hostname.includes("localhost"),
          savingDalpakAndExiting: false,
          showOrderSummary: false,
        }
      }


      function initPriceChangeButtonState() {
        let MANAGER_APPROVAL_AUTHORIZATIONS = {
          DENY: 1,
          PROMPT: 2,
          ALLOW: 3,
        };

        if (!session.pos.parameterSelectAllowChangeItemPrice) {
          if (session.pos.parameterAllowChangeItemPrice) {
            session.pos.parameterSelectAllowChangeItemPrice = MANAGER_APPROVAL_AUTHORIZATIONS.ALLOW;
          } else {
            session.pos.parameterSelectAllowChangeItemPrice = MANAGER_APPROVAL_AUTHORIZATIONS.PROMPT;
          }
        }

        if (session.pos.parameterSelectAllowChangeItemPrice === MANAGER_APPROVAL_AUTHORIZATIONS.DENY) {
          return false;
        }

        return true;
      }

      async function nameChange() {
        let saleItem = posVC.saleItemForSelectedRow();

        let dg = new PositiveTS.Dialogs.InputDialog()
        let options = new PositiveTS.Dialogs.InputDialogOptions()


        let result = await dg.open({
          inputType: "text",
          header: 'שינוי שם פריט',
          description: 'נא הקלד שם חדש לפריט',
          inputPlaceHolder: saleItem.itemDescription,
          showCancelButton: true,
          keyboardLayout: "hebrew-qwerty-custom"
        });
        if (result) {
          saleItem.itemDescription = result;
          return posVC.persistSaleItem(saleItem);
        }
      }

      function itemDeleteHandler() {
        if(this.allowMultipleSaleItemsSelection) {
          confirmMultipleSaleItemsDelete()
        } else {
          posVC.confirmSaleItemDelete()
        }
      }

      async function confirmMultipleSaleItemsDelete() {
        let saleItemsToDelete = posVC.fetchArrayPosVCSaleItems(Pinia.globalStore.multipleSelectedSaleItems)
        let numberOfSaleItemsToDelete = saleItemsToDelete.length
    
        if(numberOfSaleItemsToDelete == 0) {
          await app.promiseShowAlert({
          header: i18next.t("confirmSaleItemDeleteHeader"),
          content: i18next.t("noItemsSelectedToDelete"),
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
          }, null, null)			
    
          return
        }
    
        let hasMultipleSelections = numberOfSaleItemsToDelete > 1
        let confirmItemsDelete = true
    
        if(hasMultipleSelections) {
          confirmItemsDelete  = await app.promiseShowAlert({
            header: i18next.t("confirmSaleItemDeleteHeader"),
            content: i18next.t('confirmMultipleSaleItemsDelete',{numberOfSaleItems: numberOfSaleItemsToDelete}),
            continueButtonText: i18next.t("ok"),
          }, null, null) == 'continue'
        }
    
        if(confirmItemsDelete) {
          for (let saleItem of saleItemsToDelete) {
            posVC.confirmSaleItemDelete(saleItem, hasMultipleSelections)
          }
        }
      }

      async function fetchExternalDeliveryOrders(externalServiceType: Service.Delivery.ExternalDeliveryService) {
        if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim) && !Pinia.dalpaksStore.allDalpaks){
          return;
        }
        
        let orders = [];
        let allSales = await appDB.sales.toArray();
        let poolOrderIds = []

        switch (externalServiceType) {
          case Service.Delivery.ExternalDeliveryService.Tenbis:
            orders = await Service.TenbisService.fetchTenbisDeliveryAndPoolOrders();
            break;

          case Service.Delivery.ExternalDeliveryService.Pluxee:
            orders = await Service.PluxeeService.fetchCibusDeliveryOrders();
            break;

          case Service.Delivery.ExternalDeliveryService.Paidit:
            orders = await Service.PaiditService.fetchPaiditDeliveryOrders();
            break;
          case Service.Delivery.ExternalDeliveryService.Mishloha:
            let mishlohaCustomer = await Service.MishlohaConvertService.checkMishlohaCustomer()
            if(mishlohaCustomer) {
              orders = await Service.MishlohaService.fetchMishlohaDeliveryOrders();
            }
            break;
        }
        if (!orders) {
          return;
        }

        for (let i = 0; i < orders.length; i++) {
          let order = orders[i];
          try {
            let foundSale;
            let sale: Storage.Entity.Sale;

            switch (externalServiceType) {
              case Service.Delivery.ExternalDeliveryService.Tenbis:
                foundSale = allSales.filter(function (s) { return (s.orderNumber == order.OrderID) })[0];
                if (foundSale) {
                  continue;
                }
                sale = await Service.TenbisConvertService.convertOrderToSale(order);
                break;

              case Service.Delivery.ExternalDeliveryService.Pluxee:
                foundSale = allSales.filter(function (s) { return (s.orderNumber == order.ID) })[0];
                if (foundSale) {
                  continue;
                }
                sale = await Service.PluxeeConvertService.convertOrderToSale(order);

                break;

              case Service.Delivery.ExternalDeliveryService.Paidit:
                foundSale = allSales.filter(function (s) { return (s.orderNumber == order.OrderID) })[0];
                if (foundSale) {
                  continue;
                }
                sale = await Service.PaiditConvertService.convertOrderToSale(order);
                break;

              case Service.Delivery.ExternalDeliveryService.Mishloha:
                let [orderMishlohaID, orderNumber] = order.shareToken.split('/'); 
                foundSale = allSales.find(s => {
                  if (order.shareToken == s.orderNumber.toString()) {
                    return true;
                  } else {
                    let data = JSON.parse(s.jsondata);
                    return data.delivery?.mishlohaId === orderMishlohaID;
                  }
                });

                if (foundSale) {
                  continue;
                }

                let mishlohaSale = await Service.MishlohaConvertService.convertOrderToSale(order)
                let isPickup = JSON.parse(mishlohaSale.sale.jsondata).delivery.isPickup;

                await Service.MishlohaConvertService.checkSplitPaymentAndSplitIfNeeded(mishlohaSale)

                if (!isPickup) {
                  await Service.MishlohaConvertService.setSaleInvoiceTypeAndSequence(mishlohaSale)
                }

                Service.MishlohaConvertService.setSaleTotals(mishlohaSale)  

                sale = mishlohaSale.sale
                sale.items = mishlohaSale.saleItems;
                sale.payments = mishlohaSale.salePayments;

                break;
            }
            if(!sale){
              continue;
            }
            if (jsonConfig.getVal(jsonConfig.KEYS.sandiManUsername)) {
              if((externalServiceType === Service.Delivery.ExternalDeliveryService.Tenbis && jsonConfig.getVal(jsonConfig.KEYS.useSandiManForTenBis))
              || (externalServiceType === Service.Delivery.ExternalDeliveryService.Mishloha && jsonConfig.getVal(jsonConfig.KEYS.useSandiManForMishloha))
              || (externalServiceType === Service.Delivery.ExternalDeliveryService.Pluxee && jsonConfig.getVal(jsonConfig.KEYS.useSandiManForCibus))) {
                try {
                  await PositiveTS.Service.Sandi.SandiService.CreateDelivery(sale);  
                } catch (err) {
                  Service.Logger.error('Error in sending order to Sandi delivery API :: ' + _.invert(Service.Delivery.ExternalDeliveryService)[externalServiceType]);
                  Service.Logger.error(err);
                  Service.Logger.error(order);
                }
                
              }
            }
            if (sale.items.length > 0){
              let jsondata = JSON.parse(sale.jsondata);

              if (jsonConfig.getVal(jsonConfig.KEYS.hasDiners) && (posUtils.isBlank(sale.diners) || sale.diners == 0)) {
                sale.diners = 1;
              }

              if (posUtils.isBlank(sale.customerName) && posUtils.isBlank(sale.customerPhone) && posUtils.isDefined(jsondata.customer)) {
                sale.customerName = `${jsondata.customer.s_first_name} ${jsondata.customer.s_last_name}`
                sale.customerPhone = jsondata.customer.s_phone_number_1;
              }

              let isPickup = jsondata.delivery.isPickup;
              let fullSale = new PositiveTS.Service.FullSale(sale, sale.items, sale.payments);
              let fullSaleFormat = PositiveTS.Service.FullSale.getFullSaleFormat(sale, sale.items, sale.payments);

              if (isPickup) {
                await Service.LogicalPrinterBonPrint.printBons(fullSale)
                await Service.LogicalPrinterBonPrint.updateSaleItemsBonPrinted(fullSale)

                if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                  await Service.DalpakSharedOrders.createOrderDalpak(fullSaleFormat, sale.orderNumber);
                } else {
                  Service.HoldSale.markSaleAsHoldedSale(sale);
                  await Service.FullSale.persist(sale, sale.items, sale.payments);
                }
              } else {
                sale.syncStatus = Storage.Entity.Sale.SYNC_STATUS_WAITING_TO_BE_SENT;
                sale.syncLastMessageTimestamp = DateUtils.fullFormat();
                let persistedSale = await Service.FullSale.persist(sale, sale.items, sale.payments);
    
                if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                  await Service.DalpakSharedOrders.createOrderDalpak(fullSaleFormat);
                }

                await Service.LogicalPrinterBonPrint.printBons(fullSale)
              }



              switch (externalServiceType) {
                case Service.Delivery.ExternalDeliveryService.Tenbis:
                  if(order.isPoolOrder){
                    poolOrderIds.push(order.poolId)
                    break;
                  }
                  Service.TenbisService.updateOrderStatus(order.OrderID, 'inprocess');
                  break;

                case Service.Delivery.ExternalDeliveryService.Pluxee:
                  //no need to update status - it uses the built in set_packages function
                  Service.PluxeeService.removeOrderFromLocalOrders(order.ID)
                  break;

                case Service.Delivery.ExternalDeliveryService.Paidit:
                  Service.PaiditService.updateOrderStatus(order.OrderID, 'inprocess');
                  break;

                case Service.Delivery.ExternalDeliveryService.Mishloha:
                  Service.MishlohaService.updateOrderStatus(order.shareToken, '1');
                  break;
              }
              
              if (jsonConfig.getVal(jsonConfig.KEYS.autoTenbisOrderPrintInvoice) && !isPickup) {
                await Printing.Invoice.printInvoice(sale, saleItemHelper.unflattenSaleItems(sale.items), sale.payments, true);
              }

              if (jsonConfig.getVal(jsonConfig.KEYS.printItemListBon)) {
                Printing.Invoice.printItemListBon(sale, saleItemHelper.unflattenSaleItems(sale.items));
              }


            }
          } catch (err) {
            Service.Logger.error('Error in Converting order from ' + _.invert(Service.Delivery.ExternalDeliveryService)[externalServiceType]);
            Service.Logger.error(err);
            Service.Logger.error(order);
          }
        }

        if(poolOrderIds.length > 0){
          let uniqPoolOrderIds = _.uniq(poolOrderIds)

          switch(externalServiceType){
            case Service.Delivery.ExternalDeliveryService.Tenbis:

            for (const poolId of uniqPoolOrderIds) {
              Service.TenbisService.updatePoolOrderStatus(poolId, 'inprocess')  
            }
            break;
          }

        }
      }
      
      async function fetchExternalECommerceOrders() {
        let eCommerceServices: Array<typeof Service.ECommerceAPIService> = []
        if (jsonConfig.getVal(jsonConfig.KEYS.isDigitradeOrdersApiActive)) {
          eCommerceServices.push(Service.DigitradeService)
        }
        if (jsonConfig.getVal(jsonConfig.KEYS.isCashCowOrdersApiActive)) {
          eCommerceServices.push(Service.CashCowService)
        }

        for (let index = 0; index < eCommerceServices.length; index++) {
          const service = eCommerceServices[index];
          await service.fetchOrders()
        }
      }

      async function fetchExternalOrdersCount() {
        let countResponse = await Service.FetchReq.jsonReq(`/external_orders/orders_count`, "get")
        const result = countResponse.result
        const totalOrders = result.new + result.in_progress
        Pinia.globalStore.setExternalOrderIndicator(totalOrders)
        Pinia.globalStore.setNewExternalOrdersIndicator(result.new > 0)
      }

      async function posExternalECommerceFetcher() {
        try {
          await fetchExternalECommerceOrders();//not need to check isOnline because it is already checked in pos.ts if hasInternet
          if (PositiveTS.Reachability.isOnline) {
            await fetchExternalOrdersCount();
          }
        } catch (error) {
          console.error("Error fetching e-commerce orders")
          console.error(error)
        }
        const intervalMinutes = Number(jsonConfig.getVal(jsonConfig.KEYS.externalOrderCheckInterval))
        setTimeout(() => { posExternalECommerceFetcher() }, 1000 * 60 * intervalMinutes);
      }

      async function posExternalDeliveryFetcher(externalServiceType: Service.Delivery.ExternalDeliveryService, timeout: Number) {
        try {
          await fetchExternalDeliveryOrders(externalServiceType);
        } catch (error) {
          let msg = "";
          if (error && error.message) {
            msg = error.message;
          }
          if (typeof (error) === "string") {
            msg = error;
          }
          console.error(`external delivery fetch orders error.\n Service Type: ${externalServiceType}\n Reason: ${msg}`);
          console.error(error);
          Service.Logger.error(error);
        }

        setTimeout(() => { posExternalDeliveryFetcher(externalServiceType, timeout); }, timeout);
      }      


      // This is for the dynamic apis
      async function posExternalDeliveryAPIFetcher() {
        for (let deliveryApi of this.deliveryApis) {
          deliveryAPIFetcherJob(deliveryApi)
        }
      }

     async function deliveryAPIFetcherJob (deliveryApi) {
        let timeout = deliveryApi.apiConfig.timeRunFetchJob || 60000
        try {
          await deliveryApi.handleNewOrders()
        } catch (error) {
          let msg = "";
          if (error && error.message) {
            msg = error.message;
          }
          if (typeof (error) === "string") {
            msg = error;
          }
          console.error(`external delivery fetch orders error.\n Service Type: ${(deliveryApi as Service.BaseDeliveryAPIService).serviceName()}\n Reason: ${msg}`);
          console.error(error);
          Service.Logger.error(error);
        }

        setTimeout(deliveryAPIFetcherJob, timeout, deliveryApi)
      }


      async function startClearOldSalesInterval(timeout: Number) {
        setTimeout(() => Service.Sale.deleteOldSales(), timeout);
        try {
          await Service.Sale.deleteOldSales();
        } catch (err) {
          console.error(err);
        }
      }

      function setSearchByCode() {
        Pinia.globalStore.setSearchMode('byCode')
      }

      function setSearchByDesc() {
        Pinia.globalStore.setSearchMode('byDesc')
      }

      function openFullSearch() {
Pinia.componentsStore.openComponent( {componentName:"fullSearch", args: []});
      }

      function openQuicksale() {
Pinia.componentsStore.openComponent( {componentName:"quicksaleDialog", args: []});
      }

      async function openOpenDeliveries() {
        if (Pinia.globalStore.deliveryErrorExists) {
          await app.promiseShowAlert({
            header: i18next.t('error'),
            content: i18next.t('delivery.deliveryErrorExists'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true,
          });
        }

        app.showLoadingMessage("מאתר הזמנות")
        Pinia.componentsStore.openComponent( {componentName:"openDeliveryOrdersDialog", args: []});
        Pinia.globalStore.setDeliveriesIndicator(0)
        localStorage.setItem("deliveriesIndicator", "0");
        app.hideLoadingMessage();
      }

      async function closeDeliveryOrdersDialog() {
        app.showLoadingMessage("מאתר הזמנות")
        let couriers = await PositiveTS.Service.Delivery.getCouriers();
        if (couriers.length > 0) {
Pinia.componentsStore.openComponent( {componentName:"closeDeliveryOrdersDialog", args: []});
          app.hideLoadingMessage();
        }
        else {
          app.hideLoadingMessage();
          return app.promiseShowAlert({
            header: i18next.t('error'),
            content: i18next.t('pos.noCouriersFound'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          });
        }
      }

      async function openPickupRemarksModal() {
        let ordererDeliveryRemarks = false;
        if(JSON.parse(posVC.sale.jsondata).delivery?.ordererDeliveryRemarks?.length > 0) {
          ordererDeliveryRemarks = JSON.parse(posVC.sale.jsondata).delivery?.ordererDeliveryRemarks
        }
        await PositiveTS.Service.OrdererPickupRemarks.setOrdererPickupRemarks(null, null, null, true, null, null, ordererDeliveryRemarks)
      }

      async function openCouriersAssignmentDialog() {
        let couriers = await PositiveTS.Service.Delivery.getCouriers();
        if (couriers.length > 0) {
Pinia.componentsStore.openComponent( {componentName:"couriersAssignmentDialog", args: []});
        }
        else {
          return app.promiseShowAlert({
            header: i18next.t('error'),
            content: i18next.t('pos.noCouriersFound'),
            continueButtonText: i18next.t("ok"),
            hideCancelButton: true
          });
        }
      }


      // function openPreparationInstructions() {
      //   let saleItem:PositiveTS.Storage.Entity.SaleItem = posVC.saleItemForSelectedRow();
      //   PositiveTS.VueInstance.$refs.prepInstructionsDialog.open(saleItem)
      //   .then((item:Storage.Entity.SaleItem) => {
      //     if (item != null) {
      //       return posVC.persistSaleItem(item)
      //       .then(posVC.saleUpdated)
      //     }
      //   })
      // }

      function updateItemsBonPrintedAt(): Map<number, Storage.Entity.SaleItem> {
        let itemsToUpdate = new Map<number, Storage.Entity.SaleItem>();

        for (let index = 0; index < posVC.saleItems.length; index++) {
          let item = posVC.saleItems[index];
          if (item.item.logicalOrGroupPrinters != "[]" == true) {
            item.bonPrintedAt = moment().format("HH:mm");
            itemsToUpdate.set(item.rowNumber, item);

            if (item.children != null) {
              for (let index = 0; index < item.children.length; index++) {
                let child = item.children[index];
                child.bonPrintedAt = moment().format("HH:mm");
                itemsToUpdate.set(item.rowNumber, item);

                if (child.children != null) {
                  for (let cindex = 0; cindex < child.children.length; cindex++) {
                    const childchild = child.children[cindex];
                    childchild.bonPrintedAt = moment().format("HH:mm");
                    itemsToUpdate.set(item.rowNumber, item);
                  }
                }
              }
            }
          }
        }
        return itemsToUpdate
      }


      export async function printBon(skipReprintSilently = false) {
        let ignorePrintingOfOnlySelectedSaleItems = this.ignorePrintingOfOnlySelectedSaleItems
        let printOnlySelectedItems = this.allowMultipleSaleItemsSelection && this.multipleSelectedSaleItems.length > 0 && !ignorePrintingOfOnlySelectedSaleItems

        try {
          app.blockUIAndShowMessage(i18next.t("pos.printingBons"), false);
          let bonItems = posVC.saleItems

          if(printOnlySelectedItems) {
            bonItems = []

            for (const saleItem of this.multipleSelectedSaleItems) {
              let parentItem = Service.LogicalPrinterBonPrint.getParentItemIfChildIsSelected(saleItem)
              bonItems.push(posVC.fetchPosVCSaleItem(parentItem))
            }
  
            bonItems = _.uniqBy(bonItems, 'rowNumber');
          }

          let itemsToBePrinted = PositiveTS.Storage.Entity.SaleItem.flattenItems(bonItems).filter(item => item.item.logicalOrGroupPrinters != "[]").length;

          if(!posVC.sale["hasUpdatedGroupItems"]){
            bonItems = bonItems.filter(item => item.bonPrintedAt == null)
          }
          
          bonItems.map(item => _.cloneDeep(item)) //clone deep
          bonItems = bonItems.filter(item => item.item.logicalOrGroupPrinters != "[]")


          if ((bonItems.length == 0) && (posVC.saleItems.length > 0)) {

            if (skipReprintSilently) {
              return;
            }

            if (itemsToBePrinted == 0) {
              await app.promiseShowAlert({
                header: i18next.t("error"),
                content: i18next.t("pos.noItemsToBePrinted"),
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              })
              return;
            }

            let answer = await app.promiseShowAlert({
              header: i18next.t('pos.printBonsAgainTitle'),
              content: i18next.t('pos.printBonsAgain'),
              continueButtonText: i18next.t("pos.print"),
              cancelButtonText: i18next.t("cancel")
            })

            if (answer == 'continue') {
              await Service.LogicalPrinterBonPrint.printBons(Service.FullSale.posVCSale, true, ignorePrintingOfOnlySelectedSaleItems)
            }
          }
          else {
            await Service.LogicalPrinterBonPrint.printBons(Service.FullSale.posVCSale, false, ignorePrintingOfOnlySelectedSaleItems)
            let itemsToUpdate = new Map<number, Storage.Entity.SaleItem>();
            let itemsLength = printOnlySelectedItems ? bonItems.length : posVC.saleItems.length
            for (let index = 0; index < itemsLength; index++) {
              let item = printOnlySelectedItems ? posVC.fetchPosVCSaleItem(bonItems[index]) : posVC.saleItems[index];
              if ((item.bonPrintedAt == null || item['hasChanges']) && (item.item.logicalOrGroupPrinters != "[]" == true)) {
                item.bonPrintedAt = moment().format("HH:mm");
                itemsToUpdate.set(item.rowNumber, item);

                if (item.children != null) {
                  for (let index = 0; index < item.children.length; index++) {
                    let child = item.children[index];
                    child.bonPrintedAt = moment().format("HH:mm");
                    itemsToUpdate.set(item.rowNumber, item);

                    if (child.children != null) {
                      for (let cindex = 0; cindex < child.children.length; cindex++) {
                        const childchild = child.children[cindex];
                        childchild.bonPrintedAt = moment().format("HH:mm");
                        itemsToUpdate.set(item.rowNumber, item);
                      }
                    }
                  }
                }


              }
            }

            posVC.setLastPrintedBonGroupItemsHashStrings()
            posVC.saleUpdated(itemsToUpdate);
          }
        }
        finally {
          if(posVC.sale["hasUpdatedGroupItems"]){
            posVC.sale["hasUpdatedGroupItems"] = false
          }
          if(printOnlySelectedItems) {
            Pinia.globalStore.clearSelectedItemsAndAllowedActions()
          }
          this.ignorePrintingOfOnlySelectedSaleItems = false
          app.resumeUIAndHideMessage(false);
        }
      }

      function initFields() {
        let fields = {
          'amount': {
            order: 0,
            selected: true,
            allowDecimal: true,
            inEditMode: true,
            hasDecimal: false,
            type: 'number'
          }
        }
        for (let field in fields) {
          fields[field].model = field;
        }
        return fields
      }

      function onKeyTapped(key) {
        let aThis = PositiveTS.VueInstance.$refs.pos;

        let field = aThis.fields['amount']
        aThis.keyPadHanlder(key, field, aThis);
      }

      export async function printBonAndHoldSale() {
        try {
          this.ignorePrintingOfOnlySelectedSaleItems = true
          await printBon();
          app.blockUIAndShowMessage(i18next.t("pos.holdingSale"), false);
          await Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.HOLD_SALE, posVC.sale.cashierEmployeeID, null, posVC.getTotalAmount());

          if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
            await Service.Dalpak.exitDalpakAndSaveSale();
          } else {
            await PositiveTS.Service.HoldSale.holdCurrentSale();
          }
        }
        catch (e) {
          Service.Logger.error(e);
          throw e;
        }
        finally {
          app.resumeUIAndHideMessage(false);
        }
      }

      export async function updateScale(scale) {
        PositiveTS.VueInstance.$refs.pos.currentWeight = (scale / 1000).toFixed(3);
      }

      export async function updateLastScannedItem(item) {
        PositiveTS.VueInstance.$refs.pos.lastScannedItem = item;
      }


      export function create() {
        let component = {
          template: JST.pos(),
          mixins: [keyPadMixin],
          components: {
            'restaurant-item-menu': RestaurantItemMenu.getComponent(),
            'restaurant-order-summary': RestaurantOrderSummary.getComponent(),
            'restaurant-desktop-layout': RestaurantDesktopLayout.getComponent(),
          },
          methods: {
            open() {
              if (this.posState === "payment") {
                PositiveTS.VueInstance.$refs.posPaymentDialog.open();
              } else if (this.$refs.restaurantItemMenu) {
                this.$refs.restaurantItemMenu.open();
              } else if (this.$refs.restaurantDesktopLayout) {
                this.$refs.restaurantDesktopLayout.open();
              }
            },
            isStaging() {
              return posUtils.isStaging();
            },
            async splitSalePayment() {
              await Service.SplitSalePayment.startSplitSaleProcess();
            },
            async ototCheckBalance() {
              let cardNumber = await Service.Otot.openScanBraceletDialog();
              if (cardNumber) {
                await Service.Otot.checkBalanceAndShowMessage(cardNumber);
              }
            },
            async printOtotTag() {
              await Service.Otot.printEntranceTagFromExistingQrCode(false);
            },
            async activateRfidBraceletViaQrCode() {
              await Service.Otot.activateRfidBraceletViaQrCode();
            },
            showSplitSale: () => Boolean(jsonConfig.getVal(jsonConfig.KEYS.allowSplitSalePayment)) && Boolean(jsonConfig.getVal(jsonConfig.KEYS.showSplitSalePaymentButton)),
            onKeyTapped: onKeyTapped,
            confirmSaleDelete: () => posVC.confirmSaleRestart(),
            confirmSaleDeleteWithoutMessage: () => posVC.confirmSaleRestart(true),
            togglePresent: () => posVC.togglePresent(),
            togglePickup() {
              let saleItem: Storage.Entity.SaleItem = posVC.saleItemForSelectedRow()
              // If no sale item was found, abort!
              if (saleItem == null) {
                console.error('Row is not associated with any sale items');
                return;
              }
              let maxPrice = Service.DutyFree.getMaxPriceForPickupItem();
              if (maxPrice === null ||  maxPrice >= saleItem.unitPrice) {
                Service.DutyFree.togglePickup(saleItem)
              } else {
                let currency = Service.MultiCurr.instance.getPosCurrencySign();
                app.showAlert({
                  header: i18next.t('pos.payAttention'),
                  content: i18next.t('pos.cannotPickupItemsBeyondMaxPrice', { price: maxPrice, currency: currency }),
                  continueButtonText: i18next.t("ok"),
                  hideCancelButton: true
                });
              }
            },
            itemDeleteHandler: itemDeleteHandler,
            checkStockForSaleItem: () => posVC.checkStockForSaleItem(),
            changePriceClicked: () => posVC.changePriceClicked(),
            selectSalesPersonForSaleItemClicked: () => posVC.selectSalesPersonForSaleItemClicked(),
            openFullSearch: () => openFullSearch(),
            openQuicksale: () => openQuicksale(),
            posExternalDeliveryFetcher: posExternalDeliveryFetcher,
            posExternalDeliveryAPIFetcher: posExternalDeliveryAPIFetcher,
            posExternalECommerceFetcher: posExternalECommerceFetcher,
            startClearOldSalesInterval: startClearOldSalesInterval,
            setSearchByCode: setSearchByCode,
            setSearchByDesc: setSearchByDesc,
            openOpenDeliveries: openOpenDeliveries,
            closeDeliveryOrdersDialog: closeDeliveryOrdersDialog,
            openCouriersAssignmentDialog: openCouriersAssignmentDialog,
            openPickupRemarksModal: openPickupRemarksModal,
            openPreparationInstructions: () => posVC.editItemWithPreparationInstructions(),
            printBonAndHoldSale: printBonAndHoldSale,
            printBon: printBon,
            nameChange: nameChange,
            setPickupAllItems() {
              this.isTogglePickupAll = !this.isTogglePickupAll
              this.setMutlipleItemsForPickup(posVC.saleItems, this.isTogglePickupAll);
            },
            setPickupOnItem() {
              if(this.allowMultipleSaleItemsSelection) {
                this.setMutlipleItemsForPickup(this.multipleSelectedSaleItems);
              } else {
                this.togglePickup();
              }
            },
            setMutlipleItemsForPickup(saleItems: Storage.Entity.SaleItem[], toggleTo: boolean = null) {
              let maxPrice = Service.DutyFree.getMaxPriceForPickupItem();
              let itemsThatCouldNotBePickedUp: Storage.Entity.SaleItem[] = []
              for (const saleItem of saleItems) {
                if (maxPrice === null || maxPrice >= saleItem.unitPrice || toggleTo === false) { 
                  Service.DutyFree.togglePickup(saleItem, toggleTo);
                } else {
                  itemsThatCouldNotBePickedUp.push(saleItem);
                }
              }
              if (itemsThatCouldNotBePickedUp.length === 0) {
                return;
              }
              let currency = Service.MultiCurr.instance.getPosCurrencySign();
              let content = `<p>${i18next.t('pos.cannotPickupItemsBeyondMaxPrice', { price: maxPrice, currency: currency })}</p>`
              for (const saleItem of itemsThatCouldNotBePickedUp) {
                content += `<p> - ${saleItem.itemDescription} (${saleItem.unitPrice}${currency})</p>`
              }
              app.showAlert({
                header: i18next.t('pos.payAttention'),
                content: content,
                continueButtonText: i18next.t("ok"),
                hideCancelButton: true
              });
            },
            openTimeItemDialog(finish = true) {
              if(this.allowMultipleSaleItemsSelection) {
                let timeItem = posVC.fetchPosVCSaleItem(this.multipleSelectedSaleItems[0])
                Service.TimeItem.openTimeItemDialog(timeItem, finish);
              } else {
                Service.TimeItem.openTimeItemDialog(posVC.saleItemForSelectedRow(), finish);
              }
            },
            openExternalOrdersList() {
Pinia.componentsStore.openComponent( {componentName:"externalOrdersList", args: []})
            },
            setOrOpenPricelists: async () => {
              await Service.PriceList.setOrOpenPricelistHakafaCustomers()
            },           

            selectDiscountHandler() {
              if(this.allowMultipleSaleItemsSelection) {
                
                let allItemsCanGetDiscount = this.multipleSelectedSaleItems.every(selectedSaleItem => Pinia.globalStore.canMakeItemDiscount(selectedSaleItem))
                if(!allItemsCanGetDiscount) {
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('pos.cannotAddDiscountOnNonDiscountableItems'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  });

                  return
                }
              }

              posVC.selectDiscountForSaleItem()

            },

      
            async manualQuantityChange() {
              if(this.allowMultipleSaleItemsSelection) {
                let cannotChangeQuantity = this.multipleSelectedSaleItems.some(saleItem => saleItem.hasWeight || saleItem.timeItemVersion)

                if(cannotChangeQuantity) {

                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('pos.cannotChangeQuantiyForSelectedItems'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  });

                  return
                }

              }

              if (PositiveTS.Service.SmartVoucher.isLoadCardSale(posVC.saleItems, posVC.Specialitems)) {
                console.error('Specialitem count can only be one');
                return Promise.resolve();
              }

              let selectedItems

              if(this.allowMultipleSaleItemsSelection) {
                let saleItems = this.multipleSelectedSaleItems
                selectedItems = posVC.fetchArrayPosVCSaleItems(saleItems)

              } else {
                selectedItems = [posVC.saleItemForSelectedRow()]
              }

              let allowZeroPriceCount = 0
              let hasWeightCount = 0

              for (const item of selectedItems) {
                if(item.item.allowZeroPrice) {
                  allowZeroPriceCount +=1 
                }

                if(item.item.hasWeight) {
                  hasWeightCount += 1
                }
              }

              let allowZeroPrice = allowZeroPriceCount == selectedItems.length
              let hasWeight = hasWeightCount == selectedItems.length
              let firstItem = selectedItems[0]

              let result = await 
              Pinia.componentsStore.openComponent( {
                componentName: 'qtyDialog', 
                args: [
                  firstItem.quantity,
                  allowZeroPrice,
                  session.pos.parameterAllowNegativeQty || session.pos.isRoshemet,
                  hasWeight
                ]
              });

              for (const saleItem of selectedItems) {
                let tmpItem = saleItem.clone()
                let originalQuantity = saleItem.quantity;
                let newQuantity = firstItem.quantity;
        
                var iQty = Number(result.qty);
                if (iQty < 0 && !session.pos.parameterAllowNegativeSale) { return; }
                if ((iQty < 0 && !session.pos.parameterAllowNegativeQty) || (iQty < originalQuantity && Service.ManagerApproval.isNegativeChangingQuantity()) ) {
                  return app.showManagerApprovalDialog()
                    .then(function (managerEmployee) {
                      saleItem.discountApprovedByEmployeeID = managerEmployee.employeeID;
                      newQuantity = iQty;
                      tmpItem.quantity = newQuantity - originalQuantity
                      if (posVC.canSaleItemBePersisted(tmpItem) === true) {
                        if (session.pos.useMinPriceForNegItem == true && saleItem.item.minUnitPrice && saleItem.item.minUnitPrice > 0 && saleItem.item.minUnitPrice < saleItem.unitPrice) {
                          saleItem.priceBeforeChangedToMin = saleItem.unitPrice;
                          saleItem.unitPrice = saleItem.item.minUnitPrice;
                        }
                        saleItem.quantity = newQuantity;
                        posVC.persistSaleItem(saleItem);
                      }
                    });
                } 
                else {
                  newQuantity = iQty;
                  tmpItem.quantity = newQuantity - originalQuantity
        
                    if (posVC.canSaleItemBePersisted(tmpItem) === true) {
                      if (session.pos.useMinPriceForNegItem == true) {
                        if (iQty > 0){
                          if (saleItem.priceBeforeChangedToMin > 0) {
                            saleItem.unitPrice = saleItem.priceBeforeChangedToMin;
                          }
                        }else{
                          if (saleItem.item.minUnitPrice && saleItem.item.minUnitPrice > 0 && saleItem.item.minUnitPrice < saleItem.unitPrice) {
                            saleItem.priceBeforeChangedToMin = saleItem.unitPrice;
                            saleItem.unitPrice = saleItem.item.minUnitPrice;
                          }
                        }
                      }
                      saleItem.quantity = newQuantity
                      posVC.persistSaleItem(saleItem);
                    }
                }
              }
            },

            async getPackage() {

              let saleItems

              if(this.allowMultipleSaleItemsSelection){
                saleItems =  posVC.fetchArrayPosVCSaleItems(this.multipleSelectedSaleItems)
              } else {
                saleItems = [posVC.saleItemForSelectedRow()]
              }

              let result = await Storage.Entity.ItemPackage.getAllPackages(posVC.sale.companyID)
      
              let selected = await Pinia.componentsStore.openComponent( {
                componentName: 'packageDialog', 
                args: [result, saleItems[0]]
              })

              for (const saleItem of saleItems) {
                if (selected.approved) {
                  saleItem.quantity += saleItem.reduceQuantity / 1000;
                  saleItem.itemDescription = saleItem.item.description;
        
        
                  saleItem.quantity = saleItem.quantity - (selected.weight / 1000);
                  saleItem.quantity = parseFloat(session.fixedNumber(saleItem.quantity, 3));
        
                  if (selected.weight > 0) {
                    saleItem.itemDescription = saleItem.itemDescription + " הופחת " + String(selected.weight) + " גרם"
                  }
                  saleItem.reduceQuantity = selected.weight;
                  saleItem.selectedPackagesById = JSON.stringify(selected.selectedPackagesById);
                  posVC.persistSaleItem(saleItem);
                }
              }
            },

            getWeightAgainHandler() {
              let saleItem
              if(this.allowMultipleSaleItemsSelection) {
                if(this.multipleSelectedSaleItems.length > 1) {
                  app.showAlert({
                    header: i18next.t('error'),
                    content: i18next.t('pos.cannotRescaleMoreThanOneItem'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  });
        
                  return
                }
                saleItem = posVC.fetchPosVCSaleItem(this.multipleSelectedSaleItems[0])
                this.getWeightAgain(saleItem)
              } else {
                saleItem = posVC.saleItemForSelectedRow()
                this.getWeightAgain(saleItem)
              }
            },

            getWeightAgain(item: Storage.Entity.SaleItem = posVC.saleItemForSelectedRow()) {
              if (PositiveTS.Service.SmartVoucher.isLoadCardSale(posVC.saleItems, posVC.Specialitems)) {
                console.error('Specialitem count can only be one');
                return Promise.resolve();
              }
      
              let saleItem = item
                , prevWeight = parseFloat(session.fixedNumber((saleItem.quantity + saleItem.reduceQuantity / 1000), 3));
      
      
      
              return PositiveTS.Service.Scale.getW(Math.abs(prevWeight))
                .then((weight) => {
      
                  //set the weight as if there is no package
                  saleItem.quantity = weight;
                  saleItem.reduceQuantity = 0;
                  saleItem.itemDescription = saleItem.item.description;
      
                  let tempPromise = Promise.resolve();
      
                  if (saleItem.hasWeight && jsonConfig.getVal(jsonConfig.KEYS.autoPopSelectItemPackage)) {
      
                    tempPromise = PositiveTS.Storage.Entity.ItemPackage.getAllPackages(posVC.sale.companyID)
                      .then(result => {
      
                        return Pinia.componentsStore.openComponent( {componentName:"packageDialog", args: [result, saleItem]})
                          .then(selected => {
                            if (selected.approved) {
                              saleItem.quantity = weight - (selected.weight / 1000);
                              saleItem.quantity = parseFloat(session.fixedNumber(saleItem.quantity, 3));
      
                              if (selected.weight > 0) {
                                saleItem.itemDescription = saleItem.item.description + " הופחת " + String(selected.weight) + " גרם"
                              } else {
                                saleItem.itemDescription = saleItem.item.description;
                              }
                              saleItem.reduceQuantity = selected.weight;
                              saleItem.selectedPackagesById = JSON.stringify(selected.selectedPackagesById);
                            }
                          });
                      })
                  }
      
      
                  tempPromise.then(() => {
                    return posVC.persistSaleItem(saleItem);
                  });
      
      
                })
                .catch(function (err) {
      
                  return app.promiseShowAlert({
                    header: i18next.t('error'),
                    content: err.message,
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  });
                })
            },

            setCompensationHandler() {
              let selectedSaleItems

              if(this.allowMultipleSaleItemsSelection) {
                selectedSaleItems = posVC.fetchArrayPosVCSaleItems(this.multipleSelectedSaleItems)
              } else {
                selectedSaleItems = [posVC.saleItemForSelectedRow()]
              }

              for (let item of posVC.saleItems) {
                if (item.hasCompensation) {
                  posVC.removeCompensationOnItem(item)
                }
              }
    
              let jsonData = JSON.parse(posVC.sale.jsondata);
              jsonData.compensationUsed = true;
              posVC.sale.jsondata = JSON.stringify(jsonData);


              for (const saleItem of selectedSaleItems) {
                this.setCompensationOnItem(saleItem)
              }

            },

            setCompensationOnItem(item) {
              let saleItem = item;
              saleItem.hasCompensation = true;
              saleItem.priceBeforeCompensation = saleItem.unitPrice;
              saleItem.unitPrice = 0;
              posVC.persistSaleItem(saleItem);
            },

            setNegativeWeight() {
              let saleItems
      
              if(this.allowMultipleSaleItemsSelection) {
                saleItems = posVC.fetchArrayPosVCSaleItems(this.multipleSelectedSaleItems)
              } else {
                saleItems = [posVC.saleItemForSelectedRow()]
              }
      
              for (const saleItem of saleItems) {
                saleItem.quantity = saleItem.quantity * -1;
                posVC.persistSaleItem(saleItem);
              }
            },

            async selfServiceSupermarketActionButtonsToggler() {
              if (this.showActionButtons) {
                this.showActionButtons = false
                return
              }
              if (this.isCaveretPos) {
                app.showLoadingMessageDialog(i18next.t('caveretPayment.swipeCard'));
                let cardNumber = await Service.EMV.swipe();
                if (posUtils.isNullOrUndefinedOrEmptyString(cardNumber)) {
                  app.hideLoadingMessageDialog();
                  return
                }
                let taz = parseInt(cardNumber.substr(4, 9))
                cardNumber = parseInt(cardNumber.substr(13, 8));
                let employee = await PositiveTS.Service.CaveretEmployeeApproval.isEmployeeInStore(taz, cardNumber);
                app.hideLoadingMessageDialog();
                if (employee) {
                  PositiveTS.VueInstance.$refs.caveretPasswordDialog.magneticCard = cardNumber
                  PositiveTS.VueInstance.$refs.caveretPasswordDialog.taz = taz
                  let password = await Pinia.componentsStore.openComponent( {componentName:"caveretPasswordDialog", args: []})
                  this.showActionButtons = true
                } else {
                  await app.showAlertDialog({
                    header: i18next.t('error'),
                    content: i18next.t('caveretPasswordDialog.noPermission'),
                    continueButtonText: i18next.t("ok"),
                    hideCancelButton: true
                  })
                }
              } else {
                let isApproved = await app.showManagerApprovalDialog([Storage.Entity.Employee.IS_MANAGEER_MINUS_CONFIRM])
                if (isApproved) {
                  return this.showActionButtons = true
                }
              }
            },

            companyID: () => {
              return session.company.companyID;
            },
            tenantID: () => {
              return session.company.tenantID;
            },
            holdSale: async () => {
              app.blockUIAndShowMessage(i18next.t("pos.holdingSale"), false);
              try {
                Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.HOLD_SALE, posVC.sale.cashierEmployeeID, null, posVC.getTotalAmount()).then(() => { });
                await Service.HoldSale.holdCurrentSale();
              }
              catch (e) {
                Service.Logger.error(e);
                throw e;
              }
              finally {
                app.resumeUIAndHideMessage(false);
              }
            },
            releaseSale: () => {
Pinia.componentsStore.openComponent( {componentName:"releaseSaleDialog", args: []});
            },
            calculator: () => {
Pinia.componentsStore.openComponent( {componentName:"calculatorDialog", args: []});
            },
            async printPerforma() {
              await Service.Performa.printPerforma(posVC.sale, posVC.saleItems, posVC.salePayments)

              if (jsonConfig.getVal(jsonConfig.KEYS.isDalpakim)) {
                Service.Dalpak.dalpakPrinted(this.currentDalpak);
              }

              if (!session.pos.onlyPrintBonAfterPayment) {
                let bonItems = Storage.Entity.SaleItem.flattenItems(posVC.saleItems)
                  .map(item => _.cloneDeep(item)) //clone deep
                await Service.LogicalPrinterBonPrint.sendBonToLogicalPrinters(posVC.sale, bonItems, posVC.salePayments)
              }
            },
            printLastSaleCopy: async () => {
              console.log("print last invoice");
              let sales = await appDB.sales.where('invoiceSequence').above(0).toArray(); //.orderBy('timestamp').last()
              if (sales.length > 0) {
                let sale = Storage.Entity.Sale.import(_.sortBy(sales, 'timestamp')[sales.length - 1])

                await Printing.Invoice.printInvoice(sale, sale.items, sale.payments, false);

              }
              else {
                await app.promiseShowAlert({
                  header: "הדפסת חשבונית אחרונה",
                  content: "לא קיימות חשבוניות קודמות להדפסה",
                  hideCancelButton: true
                })
              }
            },
            feedPaper: () => {
              printer.lineFeed();
            },
            addGeneralItem: async function () {
              if (this.amount == 0) {
                return;
              }
              let item = session.allItems.get(Shared.Constants.Item.GENERIC_ITEM);
              if (!item) {
                console.error("general item not found!");
                return;
              }


              let sizesColors = [{ size: 'null', color: 'null', barcode: item.code }];
              let saleItem = (new PositiveTS.Storage.Entity.SaleItem()).importFromItemAndBarcode(item, sizesColors[0]);
              saleItem.saleID = posVC.sale.id;
              saleItem.unitPrice = this.amount;
              saleItem.quantity = this.times;
              saleItem.rowNumber = posVC.getRowNumber();

              // Set the size and color for the item to the only size and color combination
              saleItem.color = sizesColors[0].color;
              saleItem.size = sizesColors[0].size;
              saleItem.barcode = sizesColors[0].barcode;

              let canAddItem = posVC.canSaleItemBePersisted(saleItem)
              //can item be persisted is a broken function that returns true if it can but either false or text if it doesn't
              //seriously we should fix that. every reference to that function in this file is probably broken too
              if (canAddItem === true) {
                // Persist the item
                await posVC.persistNewSaleItemWithoutGroups(saleItem, true);
                this.amount = 0;
                this.times = 1;
                this.fields['amount'].hasDecimal = false;
              }
            },
            timesTapped: function () {
              this.times = this.amount;
              this.amount = 0;
              this.fields['amount'].hasDecimal = false;
            },
            clearTapped: function () {
              this.times = 1;
              this.amount = 0;
              this.fields['amount'].hasDecimal = false;
            },
            itemSheiltaOpen() {
              Pinia.componentsStore.openComponent( {componentName:"itemSheilta", args: []});
            },
            openPastInvoices() {
              posPastInvoicesVC.currentPage = 0;
              pNavigator.pushPage('pos-past-invoices', i18next.t('pageTitle.posPastInvoices'), null, null);
            },
            customerNameTooltipDisplay() {
              if(this.customerNameClicked) { return }
              this.customerNameClicked = true
              setTimeout(() => {
                this.customerNameClicked = false
              }, 5000); 
            },            
            hypLogo() {
              return `/versions/${PositiveTS.Version.appVersion}/assets/images/logos/hyp.png`
            },
            positLogo() {
              return `/versions/${PositiveTS.Version.appVersion}/assets/images/logos/posit.png`
            },
            showButtons() {
              let aThis = PositiveTS.VueInstance.$refs.pos;
              aThis.rowsOrButtonsState = PosViewState.Buttons;
            },
            showItemRows() {
              let aThis = PositiveTS.VueInstance.$refs.pos;
              aThis.rowsOrButtonsState = PosViewState.Rows;
            },
            showSaleActions() {
              let aThis = PositiveTS.VueInstance.$refs.pos;
              aThis.rowsOrButtonsState = PosViewState.Actions;
            },
            openSearchInvoices() {
              pNavigator.pushPage('pos-search-invoices', i18next.t('pageTitle.posSearchInvoices'), null, null);
            },
            async openSplitSaleScreen(){
              if (this.mobileLayout || this.dalpakTablesView) {
                if (!this.saleHasItems) {
                  return;
                }
                await Service.SplitSalePayment.startSplitSaleProcess();
              }
            },
            async openSplitPaymentScreen(){
              if (this.mobileLayout || this.dalpakTablesView) {
                if (!this.saleHasItems) {
                  return;
                }
                await Pinia.componentsStore.openComponent( {
                  componentName:"selectSplitPaymentAmountDialog", args: [true]});
              }
            },
            async openPaymentScreenSingleButton() {
              if (this.mobileLayout || this.dalpakTablesView) {
                if (!this.saleHasItems) {
                  return;
                }

                if (this.saleTotal < 0) {
                  Pinia.componentsStore.openComponent( {componentName:"creditSelectModal", args: []});
                  return;
                }
                PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.openPaymentScreen();
              }


              if (this.simpleSelfService && this.saleTotal > 0) {
                if (Service.Upsale.hasUpsales()) {
                  await Service.Upsale.showUpsales();
                }

                if(Boolean(jsonConfig.getVal(jsonConfig.KEYS.askForClubMembershipeMode))){
                  let availableClubs = jsonConfig.getVal(jsonConfig.KEYS.memberOfClubs);
                  let selectedClub = availableClubs.find(club => club !== 'hakafa')
                  /* Switch to use in the future if we want to implement more clubs in self service. */
                  switch(selectedClub){
                    case 'valuecard':
                      await PositiveTS.Service.ValuecardPointsDialog.openDialogIfRequired();
                      break;
                  }
                }
                Pinia.globalStore.setOtotSaleBracelet({
                  bracelet: null,
                  status: Service.Otot.BRACELET_STATUS.NEW
                })
                if (jsonConfig.getVal(jsonConfig.KEYS.selfServiceShowTotalOrderBeforePay)){
                  Pinia.componentsStore.openComponent( {componentName:"selfServiceTotalBeforePayDialog", args: []});
                }else{
                  Pinia.componentsStore.openComponent( {componentName:"selfServicePaymentDialog", args: []});
                }
              }
            },
            openTipPaymentScreen() {
              PositiveTS.VueInstance.$refs.pos.$refs.posPaymentButtons.openTipPaymentScreen();
            },
            getPosCurrencySign() {
              return Service.MultiCurr.getInstance().getPosCurrencySign();
            },
            async dalpaksSaveExit(skipBonPrint = false) {
              if(this.savingDalpakAndExiting) {
                return;
              }
              this.savingDalpakAndExiting = true;
              app.showLoadingMessage(i18next.t("dalpaks.loadingDalpaks"))
              if (posVC.saleItems.length > 0) {
                if (jsonConfig.getVal(jsonConfig.KEYS.printBonOnDalpakExit) && !skipBonPrint) {
                  let saleItems: any = posVC.saleItems
                  let saleHasItemWithChanges = saleItems.some(saleItem => saleItem.hasChanges == true)
                  this.ignorePrintingOfOnlySelectedSaleItems = true
                  await this.printBon(!saleHasItemWithChanges);
                }
                this.selectSaleItem(null)
                await Service.Dalpak.exitDalpakAndSaveSale();
              } else {
                this.confirmSaleDeleteWithoutMessage();
              }
              
              app.hideLoadingMessage();
              this.savingDalpakAndExiting = false;
            },
            async ototBalanceCheck() {
              let cardNumber = await Service.Otot.openScanBraceletDialog();

              if (cardNumber) {
                await Service.Otot.checkBalanceAndShowMessage(cardNumber);
              }
            },
             async incrementQuantityExt() {
              if(this.allowMultipleSaleItemsSelection) {
                for (const saleItem of this.multipleSelectedSaleItems) {
                  this.incrementQuantity(saleItem)
                }
              } else {
                this.incrementQuantity()
              }
            },
            async moveDalpak() {
              let saleItems = [];
              let dalpakToMoveTo = await Pinia.componentsStore.openComponent( {componentName:"selectDalpakFromListDialog"});

              if (dalpakToMoveTo) {
                if(this.allowMultipleSaleItemsSelection){
                  saleItems =  posVC.fetchArrayPosVCSaleItems(this.multipleSelectedSaleItems)
                } else {
                  saleItems = [posVC.saleItemForSelectedRow()]
                }
                await Service.Dalpak.moveItemsFromCurrentDalpak(saleItems, dalpakToMoveTo);
              }
            },
          },
          data: initData,
          setup(){
            const languageManagerStore = Pinia.useLanguageManagerStore();
            const {isRTL,isLTR} = Pinia.storeToRefs(languageManagerStore);

            const posStore = Pinia.usePosStore();
            const {isPOSReady} = Pinia.storeToRefs(posStore);

            const dalpaksStore = Pinia.useDalpaksStore();
            const {currentSelectedDalpak} = Pinia.storeToRefs(dalpaksStore);

            const {incrementQuantity,decrementQuantity,editItemWithGroups} = posStore
            const globalStore = Pinia.useGlobalStore();

            

            const {
              saleItems,
              customerCompensation,
              sale,
              isNirDavidEntranceSale,
              useRoshemetBigLayout,
              posState,
              portraitMode,
              isSpecialItemSelected,
              blockItemPriceChange,
              hideButtonMenu,
              showInvForItemButton,
              isShowQuicksaleButton,
              showGiftForItemButton,
              multipleSelectedSaleItems,
              selectedSaleItem,
              customerText,
              saleTotal,
              totalQuantity,
              isHaveToChooseClubMembership,
              askForClubMembershipeMode,
              mizdamenOrHakafaMode,
              itemWithDialogXL,
              simpleSelfService,
              standaloneMode,
              allowedItemActions,
              selfServiceQuickSale,
              deliveriesIndicator,
              deliveryErrorExists,
              isDeliverySale,
              mobileLayout,
              releaseSaleAvailable,
              mobilePosNavState,
              holdSaleAvailable,
              externalOrderIndicator,
              newExternalOrdersIndicator,
              isWithdrawalSale,
              isPickupSale,
              posCustomerNameData,
              selfServiceSuperMarket
              
            } = Pinia.storeToRefs(globalStore);
            const globalStoreProps = {
              sale,
              saleItems,
              selfServiceSuperMarket,
              posCustomerNameData,
              isPickupSale,
              isWithdrawalSale,
              newExternalOrdersIndicator,
              externalOrderIndicator,
              deliveriesIndicator,
              holdSaleAvailable,
              rowsOrButtonsState:mobilePosNavState,
              customerCompensation,
              releaseSaleAvailable,
              mobileLayout,
              isDeliverySale,
              isNirDavidEntranceSale,
              useRoshemetBigLayout,
              posState,
              portraitMode,
              isSpecialItemSelected,
              blockItemPriceChange,
              hideButtonMenu,
              showInvForItemButton,
              isShowQuicksaleButton,
              showGiftForItemButton,
              multipleSelectedSaleItems,
              selectedSaleItem,
              customerText,
              saleTotal,
              totalQuantity,
              isHaveToChooseClubMembership,
              askForClubMembershipeMode,
              mizdamenOrHakafaMode,
              itemWithDialogXL,
              simpleSelfService,
              standaloneMode,
              allowedItemActions,
              selfServiceQuickSale,
              deliveryErrorExists}

              const {selectSaleItem} = globalStore
              const globalStoreActions = {selectSaleItem}

            return {...globalStoreActions,...globalStoreProps,currentDalpak:currentSelectedDalpak,isRTL,isLTR,isPOSReady,incrementQuantity,decrementQuantity,editItemWithGroups}
            
          },
          computed: {
            saleItemsCount(){
              return Pinia.globalStore.saleItems.length
            },
            dinersNumber(){
              return Pinia.globalStore.sale ? Pinia.globalStore.sale.diners : 0;
            },
            showPos(){
              return Pinia.globalStore.posState == "pos"
            },
            searchModeByCode(){
              return Pinia.globalStore.searchMode == "byCode"
            },
            searchModeByDesc(){
              return Pinia.globalStore.searchMode == "byDesc"
            },
            fullBtnImgText(){
              return Pinia.globalStore.simpleSelfService && jsonConfig.getVal(jsonConfig.KEYS.sprateBtnImgText) && jsonConfig.getVal(jsonConfig.KEYS.fullBtnImgText)
            },
            sprateBtnImgText(){
              return Pinia.globalStore.simpleSelfService && jsonConfig.getVal(jsonConfig.KEYS.sprateBtnImgText)
            },
            noSaleItems(){
              return Pinia.globalStore.saleItems?.length == 0 
            },
            dalpakTablesView() {
              return jsonConfig.getVal(jsonConfig.KEYS.dalpakTablesView);
            },
            restaurantNewPOS() {
              return jsonConfig.getVal(jsonConfig.KEYS.restaurantNewPOS);
            },
            isDalpakimNewInstractionsScreen() {
              return jsonConfig.getVal(jsonConfig.KEYS.dalpakimNewInstractionsScreen)
            },
            tabsClass() {
              let count = 3;
              if(jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos)) {
                count++;
              }
              if(jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairModule)) {
                count++;
              }
              if(jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline)) {
                count--;
              }
              return `tabs-${count}`;
            },
            saleHasItems() {
              return this.saleItems && this.saleItems.length > 0
            },
            hasInternet() {
              return session.pos.hasInternetStore
            },
            salesPersonForItem() {
              if (this.selectedSaleItem == null) {
                return this.sale ? (this.sale.salespersonEmployeeName || "") : ""
              }
              return this.selectedSaleItem.salespersonEmployeeName || "";
            },
            amount: {
              get: function () { //we do not use arrow functions here intentionally to let vue.js handle the value of 'this'
                if (this.internalAmount == null) {
                  this.internalAmount = 0;
                }
                return Number(this.internalAmount)
              },
              set: function (value) { //we do not use arrow functions here intentionally to let vue.js handle the value of 'this'
                this.internalAmount = Number(value);
              }
            },

            times: {
              get: function () { //we do not use arrow functions here intentionally to let vue.js handle the value of 'this'
                if (this.internalTimes == null || this.internalTimes == 0) {
                  this.internalTimes = 1;
                }
                return Number(this.internalTimes)
              },
              set: function (value) { //we do not use arrow functions here intentionally to let vue.js handle the value of 'this'
                this.internalTimes = Math.max(0.1, Number(value));
              }
            },

            isHasTips() {
              return Service.Tip.isHasTips();
            },
            isOtot() {
              return Service.Otot.isOtotActive();
            },
            showOtotPrintBraceletButton() {
              return this.isOtot && jsonConfig.getVal(jsonConfig.KEYS.showOtotPrintBraceletButton);
            },
            showOtotRFIDBraceletActivation() {
              return this.isOtot && jsonConfig.getVal(jsonConfig.KEYS.showOtotRFIDBraceletActivation);
            },
            multipleMigvans() {
              return Service.MultipleMigvans.isActive();
            },

            mobileLayoutTotalForPayText() {
              return this.saleTotal < 0 ? i18next.t("pos.toRefund") : i18next.t("pos.totalForPay")
            },
            
            showTotalItems () {
              return (!(this.mobileLayout && this.showSplitSale())) && 
                (!this.simpleSelfService || (this.simpleSelfService && this.selfServiceSuperMarket))
            },
            selfServiceShowCheckBalance(){
              return jsonConfig.getVal(jsonConfig.KEYS.selfServiceShowCheckBalance)
            },
            isDeliveryPos(){
              return jsonConfig.getVal(jsonConfig.KEYS.isDelivery)
            },
            isRoshemetLayout(){
              return jsonConfig.getVal(jsonConfig.KEYS.isRoshemetLayout)
            },
            allowBonPrinting(){
              return jsonConfig.getVal(jsonConfig.KEYS.allowBonPrinting)
            },
            allowBonPrintingAndHold(){
              return jsonConfig.getVal(jsonConfig.KEYS.allowBonPrintingAndHold)
            },
            allowQuickAccountActions(){
              return jsonConfig.getVal(jsonConfig.KEYS.allowQuickAccountActions);
            },
            multipleRowsPaymentButtons(){
              return jsonConfig.getVal(jsonConfig.KEYS.multipleRowsPaymentButtons);
            },
            isDalpakim(){
              return jsonConfig.getVal(jsonConfig.KEYS.isDalpakim);
            },
            hasQuickAccessToPrintBill(){
              return jsonConfig.getVal(jsonConfig.KEYS.hasQuickAccessToPrintBill)
            },
            isDalpakimOnline(){
              return jsonConfig.getVal(jsonConfig.KEYS.isDalpakimOnline);
            },
            hasDiners(){
              return jsonConfig.getVal(jsonConfig.KEYS.hasDiners);
            },
            allowQuickBonPrinting(){
              return jsonConfig.getVal(jsonConfig.KEYS.allowQuickBonPrinting);
            },
            isRoshemet(){
              return session.pos.isRoshemet;
            },
            isAndroid(){
              return session.isAndroid;
            },
            hasFlights(){
              return session.pos.hasFlights
            },
            doNotPrintOriginalInvoice(){
              return jsonConfig.getVal(jsonConfig.KEYS.doNotPrintOriginalInvoice);
            },
            showPaymentButtonsInStandAlone(){
              return jsonConfig.getVal(jsonConfig.KEYS.showPaymentButtonsInStandAlone);
            },
            scaleOSD(){
              return jsonConfig.getVal(jsonConfig.KEYS.scaleCom) == Service.Scale.S2Scale
            },
            hideDiscounts(){
              return jsonConfig.getVal(jsonConfig.KEYS.hideDiscounts);
            },
            hideSearchBtn(){
              return jsonConfig.getVal(jsonConfig.KEYS.hideSearchBtn);
            },
            itemQueryOnlyPos(){
              return jsonConfig.getVal(jsonConfig.KEYS.itemQueryOnlyPos);
            },
            usePictures(){
              return Boolean(jsonConfig.getVal(jsonConfig.KEYS.usePictures));
            },
            directHakafaPayment(){
              return jsonConfig.getVal(jsonConfig.KEYS.directHakafaPayment);
            },
            mobilePhoneRepairModule(){
              return jsonConfig.getVal(jsonConfig.KEYS.mobilePhoneRepairModule);
            },
            canManageHakafaCustomer(){
              return jsonConfig.getVal(jsonConfig.KEYS.canManageHakafaCustomerInPos);
            },
            smallCustomerName(){
              let state = Pinia.globalStore;
              if(state.posCustomerNameData){
                return state.posCustomerNameData.displayName.length > 16
              }
            }, 
              isExternalOrderSale(){
                if(Pinia.globalStore.isDeliverySale) {
                  let saleData = JSON.parse(posVC.sale.jsondata);
                  if(!posUtils.isBlank(saleData.delivery) && saleData.delivery.isExternalOrder && saleData.delivery.j5) {
                    return true;
                  }
                }
                return false;
              },
            
          },
          mounted: async function () {
            if(this.dalpakTablesView){
              if(document.querySelector<HTMLElement>('#header')){
                document.querySelector<HTMLElement>('#header').style.display = 'none'
              }
            }
            if (!this.initialized) {
              setTimeout(() => {
                $('#pos [data-role="button"]').uibutton();
              }, 0)
              this.initialized = true;

              if (this.hasQuickAccessToPrintBill) {
                // Some ducktape fix because the Grid-css doesn't have the option to add new row when stacked with many items
                $("#pos-dalpak-print-bill-quick-access").parent().css("grid-column","1/78");
              }

              if (this.hasInternet) {
                this.posExternalECommerceFetcher()
              }
              if (Pinia.globalStore.isExternalDeliveryOrdersActive) {
                if (jsonConfig.getVal(jsonConfig.KEYS.isTenbisDeliveryApiActive)) {
                  this.posExternalDeliveryFetcher(Service.Delivery.ExternalDeliveryService.Tenbis, 60000)
                }
                if (jsonConfig.getVal(jsonConfig.KEYS.isPaiditDeliveryApiActive)) {
                  this.posExternalDeliveryFetcher(Service.Delivery.ExternalDeliveryService.Paidit, 9000)
                }
                if (jsonConfig.getVal(jsonConfig.KEYS.isCibusDeliveryApiActive)) {
                  this.posExternalDeliveryFetcher(Service.Delivery.ExternalDeliveryService.Pluxee, 60000)
                }
                if (jsonConfig.getVal(jsonConfig.KEYS.isMishlohaOrdersApiActive)) {
                  this.posExternalDeliveryFetcher(Service.Delivery.ExternalDeliveryService.Mishloha, 60000)
                }
              }

              if (jsonConfig.getVal(jsonConfig.KEYS.deliveryApis)?.length > 0) {
                for (let deliveryApi of jsonConfig.getVal(jsonConfig.KEYS.deliveryApis)) {
                  try {
                    this.deliveryApis.push(Service.BaseDeliveryAPIService.getServiceFromJsonData(deliveryApi));
                  } catch(err) {
                    Service.Logger.error("Can't initialize delivery api " + deliveryApi.name);
                    Service.Logger.error(err);
                  }
                }

                this.posExternalDeliveryAPIFetcher();
              }

              if (!posUtils.isBlank(jsonConfig.getVal(jsonConfig.KEYS.hoursToSaveSales)) && posUtils.isNumeric(jsonConfig.getVal(jsonConfig.KEYS.hoursToSaveSales))) {
                this.startClearOldSalesInterval(1000 * 60 * 60);
              }


            }
          },
          watch: {
            showOrderSummary: function (val) {
              if (val) {
                this.$nextTick(() => {
                  if(document.querySelector(".order-items")){
                    document.querySelector(".order-items").scrollTop = document.querySelector(".order-items").scrollHeight
                  }
                })
              }
            }
          }
        }

        VueApp.component('pos', component)
      }
    }
  }
}
