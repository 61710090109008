import PinpadConfig from "./PinpadConfig";
import ILoadingMessageAndDialogManager from "../../ILoadingMessageAndDialogManager";
import IAddon from "../../pinpad/interfaces/IAddon";
import { DialogAnswer } from "../../ILoadingMessageAndDialogManager";
import ILogger from "../../ILogger";
import EmvOutput from "./EmvOutput";
import EmvRequest from "./EmvRequest";
import Pinpad from "./Pinpad";
import KeepAlive from "./KeepAlive";
import RequestType from '../enums/EmvRequestType'
import PanEntryModeType from '../enums/PanEntryModeType'
import MtiType from '../enums/MtiType'
import TranType from '../enums/TranType'
import { isBlank, isNullOrUndefinedOrEmptyString, sleep, xml2json } from "../../Utils";
import MultiCurrencyService from './MultiCurrencyService';
import i18next from "i18next";
import _ from "loadsh"


export default class Emv {

  protected keepAlive: KeepAlive

  constructor(protected pinpad: Pinpad,
    protected configurations: PinpadConfig,
    protected addon: IAddon,
    protected loadingMessageAndDialogManager: ILoadingMessageAndDialogManager,
    protected logger: ILogger,
    protected multiCurrencyService: MultiCurrencyService) {

    if (this.configurations.keepAliveEmv) {
      this.keepAlive = new KeepAlive(this.pinpad, this, this.logger, this.configurations.keepAliveEmvMinutesInterval);
      this.pinpad.setKeepAlive(this.keepAlive);
    }
  }

  initializePinpadAddon() {
    return this.pinpad.initializePinpadAddon();
  }

  async killPinpadAddon() {
    return await this.pinpad.killPinpadAddon();
  }

  isFullEmv(panEntryMode) {
    return panEntryMode == PanEntryModeType.FULL_EMV_CODE || panEntryMode == PanEntryModeType.FULL_EMV_NFC;
  }


  async pinpadConfiguration() {
    this.loadingMessageAndDialogManager.showLoadingMessage(i18next.t("emv.performingPinpadConfiguration"))
    let request = this.pinpad.getXmlRequest("013", this.configurations.emvTimeout);
    let addonResponse = await this.pinpad.sendRequestToPinpad(request, RequestType.EMV);
    let parsedResponse = this.pinpad.parseResult(addonResponse);
    this.loadingMessageAndDialogManager.hideLoadingMessage();
    return parsedResponse;
  }

  formatFirstPaymentAndFixedAmount(amount, paymentsCount, currencyRate = null, requestedFirstPayment?): { firstPayment: string, fixedAmount: string } {
    let fixedAmount = 0;
    paymentsCount = Number(paymentsCount);
    if (currencyRate) {
      amount = (amount * currencyRate)
    }

    let firstPayment = Math.round(amount * 100);

    if (!isNullOrUndefinedOrEmptyString(requestedFirstPayment) && requestedFirstPayment > 0 && paymentsCount > 1) {
      firstPayment = Math.round(requestedFirstPayment * 100);
      fixedAmount = (Math.round(amount * 100) - firstPayment) / (paymentsCount - 1)
    }
    else if (paymentsCount > 1) {
      firstPayment = (Math.round(amount * 100)) % paymentsCount + parseInt(String(Math.round(amount * 100) / paymentsCount));
      fixedAmount = (Math.round(amount * 100) - firstPayment) / (paymentsCount - 1)
    }

    if (paymentsCount > 1) {
      fixedAmount = Math.floor(fixedAmount);
      let paymentDiff = Math.round(amount * 100) - (firstPayment + (fixedAmount * (paymentsCount - 1)));

      if (paymentDiff != 0) {
        firstPayment += paymentDiff;
      }
    }

    return { firstPayment: String(firstPayment), fixedAmount: String(fixedAmount) }
  }

  createTransmitRequestObj() {
    return this.pinpad.getXmlRequest("006", this.configurations.emvTimeout);
  }

  createCheckConnectionRequestObj() {
    return this.pinpad.getXmlRequest("003", this.configurations.emvTimeout, undefined, undefined, 45000);
  }

  createGetTotalRequestObj() {
    return this.pinpad.getXmlRequest("005", this.configurations.emvTimeout)
  }

  createGetTransmitReportObj(sessionNumber, date) {
    return this.pinpad.getXmlRequest("015", this.configurations.emvTimeout, { "Session-Number": sessionNumber, Date: date })
  }

  createTransactionQueryRequest(XField, requestId = null) {
    let request = this.pinpad.getXmlRequest("012", this.configurations.emvTimeout, { XField: XField }, requestId);
    request.XField = XField
    return request;
  }

  createQRRequest(qrString, timeout = 30) {
    return this.pinpad.getXmlRequest("020", timeout, { "QrCode": qrString })
  }

  async DisplayQR(qrString, timeout = 30) {
    let request = this.createQRRequest(qrString, timeout)
    return this.pinpad.sendRequestToPinpad(request, RequestType.EMV);
  }

  async createPaymentRequestObj(amount, emvMode, emvCreditTerms: number, numberOfPayments: number, verificationCode?, tranType = String(TranType.CHARGE), firstPayment?, specialAddendumSettl?: string, ParameterJ = "4", extraRequestTagData = null) {
    let mti = String(MtiType.COMMIT);
    let uniqueXField = this.generateXField(tranType, mti, String(Math.round(amount * 100)));
    let payments = this.formatFirstPaymentAndFixedAmount(amount, numberOfPayments, null, firstPayment);

    this.storeXField(uniqueXField);

    let request: any = {
      PanEntryMode: emvMode,
      XField: uniqueXField,
      Mti: mti, //400 for cancel
      TranType: tranType, //1 for debit, 53 for zikui (refund) //30 for berur itra
      Amount: String(Math.round(amount * 100)),
      Currency: this.multiCurrencyService.getPaymentCurrencyCreditRequestEMV(),
      CreditTerms: String(emvCreditTerms),
      ParameterJ: ParameterJ
    }

    if (emvCreditTerms == 6 || emvCreditTerms == 8) {
      request = {
        ...request, ...{
          FirstPayment: String(payments.firstPayment),
          NotFirstPayment: String(payments.fixedAmount),
          NoPayments: String(emvCreditTerms) == "6" ? String(numberOfPayments) : String(numberOfPayments - 1)
        }
      }
    }


    if (!this.configurations.skipVerificationCode && verificationCode) {
      request.AuthorizationNo = verificationCode;
      request.AuthorizationCodeManpik = "5"
    }
    request.SwitchSend = "0"
    if (specialAddendumSettl) {
      request.AddendumSettl = specialAddendumSettl;
    }
    if (extraRequestTagData) {
      request = { ...request, ...extraRequestTagData }
    }
    let requestId = String(Date.now());

    let xmlReq = this.pinpad.getXmlRequest("001", this.configurations.emvTimeout, request, requestId);
    xmlReq.XField = uniqueXField;
    xmlReq.RequestId = requestId;
    return xmlReq;
  }

  async createGenericRequestObj(data: EmvRequest) {
    let uniqueRequestId = this.generateXField(data.TranType, data.Mti, data.Amount);

    this.storeXField(uniqueRequestId);

    let command = data.Command || "001";
    delete data.Command;

    let request: any = {
      ParameterJ: "4",
      XField: uniqueRequestId,
      ...data
    }

    request.SwitchSend = "0"


    let xmlReq = this.pinpad.getXmlRequest(command, this.configurations.emvTimeout, request, uniqueRequestId);
    xmlReq.XField = uniqueRequestId;
    xmlReq.RequestId = uniqueRequestId;

    return xmlReq;
  }

  async swipe(throwException = false) {
    let expToThrow = null;

    try {
      let request = this.pinpad.getXmlRequest("023", this.configurations.emvTimeout, undefined, undefined, 120000);
      let addonResponse = await this.pinpad.sendRequestToPinpad(request, RequestType.EMV);
      let result = this.pinpad.parseResult(addonResponse);
      if (result.ResultCode != "10044") {
        if (!isNullOrUndefinedOrEmptyString(result.Track2)) {
          return result.Track2;
        } else {
          let errorMsg = `${i18next.t("emv.emptyTrack2")} `;
          if (!isNullOrUndefinedOrEmptyString(result.ResultCode)) {
            if (i18next.exists(`emv.errors.${result.ResultCode}`)) {
              errorMsg += i18next.t(`emv.errors.${result.ResultCode}`);
            }
            else if (result.ResultCode != "0") {
              errorMsg += i18next.t("emv.errorNum", { error: result.ResultCode });
            }
          }

          if (throwException) {
            expToThrow = new Error(errorMsg)
          } else {
            this.loadingMessageAndDialogManager.showAlert(errorMsg, i18next.t('error'));
          }
        }
      }
    } catch (err) {
      this.logger.error(err);
    }

    if (expToThrow) {
      throw expToThrow;
    }
  }

  async setAdapativeEMVPosNumber() {
    let pinpadConfig = await this.pinpadConfiguration();
    if (pinpadConfig.ResultCode != 0) {
      await this.loadingMessageAndDialogManager.showAlert(i18next.t("emv.pinpadConfigurationError"), i18next.t('error'));
      return false
    }

    this.configurations.posNumber = pinpadConfig.TermNo;

    return this.configurations.posNumber;
  }

  async createCancelRequestObj(originalPaymentToCancel, isRefund = false, specialAddendumSettl?: string,extraRequestTagData?:object) {

    let uniqueXField = this.generateXField(originalPaymentToCancel.TranType, String(MtiType.CANCEL), originalPaymentToCancel.Amount);

    let request: any = {
      XField: uniqueXField,
      Mti: MtiType.CANCEL,
      Currency: originalPaymentToCancel.Currency,
      CreditTerms: originalPaymentToCancel.CreditTerms,
      Amount: originalPaymentToCancel.Amount,
      TranType: originalPaymentToCancel.TranType,
      OriginalUid: originalPaymentToCancel.Uid,
      ParameterJ: "4"
    }
    this.saveCancelCall(request.OriginalUid, request.XField);

    if (specialAddendumSettl) {
      request.AddendumSettl = specialAddendumSettl;
    }
    if(extraRequestTagData){
      request = {...request,...extraRequestTagData}
    }
    let requestId = String(Date.now());

    let xmlReq = this.pinpad.getXmlRequest("001", this.configurations.emvTimeout, request, requestId);
    xmlReq.XField = uniqueXField;
    xmlReq.RequestId = requestId;
    return xmlReq;

  }

  async createCreditRequestObj(paymentToCredit, isManual: boolean, specialAddendumSettl?: string,extraRequestTagData?:object): Promise<EmvRequest> {
    let requestAmount = String(Math.round((this.multiCurrencyService.isMultiCurr() ? paymentToCredit.currencyAmount : paymentToCredit.amount) * 100))
    let uniqueXField = this.generateXField(String(TranType.CREDIT), String(MtiType.COMMIT), requestAmount);

    this.storeXField(uniqueXField);

    let amountForPayments = paymentToCredit.amount

    if (paymentToCredit.creditLeadCurrencyAmount && paymentToCredit.currencyRate) {
      amountForPayments = paymentToCredit.creditLeadCurrencyAmount
    }

    let payments = this.formatFirstPaymentAndFixedAmount(amountForPayments, paymentToCredit.payments_count, paymentToCredit.currencyRate);

    let creditTerms = paymentToCredit.CreditTerms;

    let request: any = {
      XField: uniqueXField,
      PanEntryMode: isManual ? PanEntryModeType.PHONE_DEAL : PanEntryModeType.PINPAD_DEAL,
      Mti: MtiType.COMMIT,
      Currency: this.multiCurrencyService.getCurrencyCodeEMV(paymentToCredit),
      CreditTerms: creditTerms,
      Amount: requestAmount,
      TranType: String(TranType.CREDIT),
      ParameterJ: "4",
    }

    if (creditTerms == 6 || creditTerms == 8) {
      request.FirstPayment = String(payments.firstPayment)
      request.NotFirstPayment = String(payments.fixedAmount)
      request.NoPayments = creditTerms == "6" ? String(paymentToCredit.payments_count) : String(paymentToCredit.payments_count - 1)
    }

    if (specialAddendumSettl) {
      request.AddendumSettl = specialAddendumSettl
    }
    if(extraRequestTagData){
      request = {...request,...extraRequestTagData}
    }
    let requestId = String(Date.now());

    let xmlReq = this.pinpad.getXmlRequest("001", this.configurations.emvTimeout, request, requestId);
    xmlReq.XField = uniqueXField;
    xmlReq.RequestId = requestId;
    return xmlReq;
  }

  pad(num: string, size = 3): string {
    let s = "0000000000000000000000" + num
    return s.substr(s.length - size)
  }

  storeXField(XField) {
  }

  moveUnknownXFieldToFailed(XField) {
  }

  markXFieldAsSuccessful(XField) {
  }

  moveFailedXFieldToSuccessfullAfterRecovery(XField) {
  }

  removeXFieldFromSuccessfull(XField) {
  }

  verifyEmvOutput(output: EmvOutput, input: EmvRequest, originalPayment?: EmvOutput): { hasError: boolean, errorMessage: string, isCancellationError?: boolean } {
    let hasError = false
    let errorMessage = null

    if (output.ResultCode == '-1000' || isNullOrUndefinedOrEmptyString(output.ResultCode)) {
      //positive EmvAddon.exe internal error
      return { hasError: true, errorMessage: i18next.t('emv.GENERAL_ERROR') }
    }

    if (output.Mti == "400" && Number(output.ResultCode) == 0) {  //if response is a successfull cancellation try and erase it's respective successfull payment
      if (!isNullOrUndefinedOrEmptyString(originalPayment)) {
        this.removeXFieldFromSuccessfull(originalPayment.Xfield);
      }
    } else if (Number(output.ResultCode) == 0) {  //The pinpad responded with a good result code move it to successfullxfields
      this.markXFieldAsSuccessful(output.Xfield);
    }
    else {
      this.moveUnknownXFieldToFailed(output.Xfield);
    }

    if (output.ResultCode && output.ResultCode == "0" && output.Solek && output.Solek == "0") {
      return { hasError: true, errorMessage: i18next.t('emv.cardDenied') };
    }
    if ((!isNullOrUndefinedOrEmptyString(output.RequestId)) && output.RequestId != input.RequestId) {
      if(output.Xfield == input.XField){
        this.logger.info('possible emv mismatch but xfields are the same');
        this.logger.info([input, output]);
      }else{
        this.logger.info('emv input/output mismatch');
        this.logger.info([input, output]);
        //critical ERROR! this should not happen!
        //TODO: should I send cancel transaction in this case? or just wait for sinun at the end of the day?
        return { hasError: true, errorMessage: i18next.t('emv.REQUEST_AND_OUTPUT_MISMATCH') }
      }

    }

    let request = xml2json(input.rawXml);

    if (Number(output.ResultCode) != 0 || Number(output.AshStatus) != 0) {
      hasError = true
      if (output.ResultCode == "10048" && this.pad(output.Status, 4) != "0000" && this.pad(output.AshStatus) == "431") {
        errorMessage = `${output.Status} - ${i18next.t('emv.statusCodes.' + output.Status)}`
      }
      else if (i18next.exists(`emvAshStatusCodes.${this.pad(output.AshStatus)}`)) {
        errorMessage = `${output.AshStatus} - ${i18next.t('emvAshStatusCodes.' + this.pad(output.AshStatus))}`
      }
      else {
        errorMessage = i18next.t('emv.GENERAL_ERROR')
      }

      if (request.Request.Mti == MtiType.CANCEL) {
        return { hasError: true, errorMessage: errorMessage, isCancellationError: true }
      }

    }
    return { hasError: hasError, errorMessage: errorMessage }
  }

  getDepositReport(sessionNumber: string, date: string, full = false) {
    return new Promise((resolve, reject) => {
      if (isNullOrUndefinedOrEmptyString(sessionNumber)) {
        return resolve({})
      }
      let request = this.createGetTransmitReportObj(sessionNumber, date)
      this.pinpad.sendRequestToPinpad(request, RequestType.EMV, i18next.t('emv.GETTING_TRANS_REPORT'))
        .then(addonResponse => {
          this.logger.dir(addonResponse);
          let result = this.pinpad.parseResult(addonResponse)
          resolve(full ? result : result.DepositReport)
        })
    })

  }

  updatePinPad(): Promise<EmvOutput> {
    let request = this.pinpad.getXmlRequest("010", this.configurations.emvTimeout, { SubCommand: "007" });
    return this.pinpad.sendRequestToPinpad(request, RequestType.EMV, i18next.t('emv.UPDATING_PINPAD'))
      .then(addonResponse => {
        this.logger.dir(addonResponse);
        return this.pinpad.parseResult(addonResponse)
      })
  }

  sendEMVLogsToCaspitFTP(): Promise<EmvOutput> {
    let request = this.pinpad.getXmlRequest("010", this.configurations.emvTimeout, { SubCommand: "003" });
    return this.pinpad.sendRequestToPinpad(request, RequestType.EMV, i18next.t('emv.SENDING_LOGS_TO_FTP'))
      .then(addonResponse => {
        this.logger.dir(addonResponse);
        return this.pinpad.parseResult(addonResponse)
      })
  }

  callCaspitTechServer(): Promise<EmvOutput> {
    let request = this.pinpad.getXmlRequest("010", this.configurations.emvTimeout, { SubCommand: "002" });
    return this.pinpad.sendRequestToPinpad(request, RequestType.EMV, i18next.t('emv.CALLING_CASPIT_TECH_SERVER'))
      .then(addonResponse => {
        this.logger.dir(addonResponse);
        return this.pinpad.parseResult(addonResponse)
      })
  }

  async terminalCheck() {
    let request = this.pinpad.getXmlRequest("010", this.configurations.emvTimeout, { SubCommand: "006" });
    let addonResponse = await this.pinpad.sendRequestToPinpad(request, RequestType.EMV, i18next.t('emv.CHECKING_TERMINAL_CONNECTION'));
    this.logger.dir(addonResponse);
    let result = this.pinpad.parseResult(addonResponse);
    if (result.ResultCode == "0") {
      this.loadingMessageAndDialogManager.showAlert(i18next.t('emv.connectionOk'), i18next.t('success'));
    }
    else {
      let errorMsg = `${i18next.t("generalError")} `;
      if (!isNullOrUndefinedOrEmptyString(result.ResultCode)) {
        if (i18next.exists(`emv.errors.${result.ResultCode}`)) {
          errorMsg = i18next.t(`emv.errors.${result.ResultCode}`);
        }
        else {
          errorMsg = i18next.t("emv.errorNum", { error: result.ResultCode });
        }
      }
      this.loadingMessageAndDialogManager.showAlert(errorMsg, i18next.t('error'));
    }
  }

  getTotal(): Promise<EmvOutput> {
    let request = this.createGetTotalRequestObj();
    return this.pinpad.sendRequestToPinpad(request, RequestType.EMV, i18next.t('emv.GETTING_TRANS_REPORT'))
      .then(addonResponse => {
        this.logger.dir(addonResponse);
        return this.pinpad.parseResult(addonResponse)
      })
  }

  getLoadingMessageTextByEmvRequest(actionData) {
    if (String(actionData.TranType) == String(TranType.CREDIT)) {
      return i18next.t('emv.EMV_RETURN_IN_PROGRESS');
    }

    if (String(actionData.Mti) == String(MtiType.CANCEL) || String(actionData.Mti) == String(MtiType.REVERSAL)) {
      return i18next.t('emv.EMV_CANCEL_IN_PROGRESS');
    }

    return i18next.t('emv.EMV_IN_PROGRESS');
  }

  async makeCreditCardAction(actionData: EmvRequest, isRetry = false) {
    try {
      let parsedResult;
      let request = await this.createGenericRequestObj(actionData)
      this.logger.log(JSON.stringify(request));
      let addonResponse = await this.pinpad.sendRequestToPinpad(request, RequestType.EMV, this.getLoadingMessageTextByEmvRequest(actionData));

      if (this.configurations.isNewVersionAddonFiles || this.configurations.isAndroid) {
        let emvResponseResult = this.verifySanitizeAndParseResult(addonResponse);

        if (!emvResponseResult.valid) {
          let cancelResponse = await this.attemptCancelTransaction(addonResponse, actionData.CreditTerms, actionData.TranType, parseInt(actionData.Amount) / 100)
          let dummyOutput = new EmvOutput()
          dummyOutput.ResultCode = "-1000"

          return { success: false, result: dummyOutput, error: cancelResponse };
        }
        parsedResult = emvResponseResult.emvResponse;

      } else {
        parsedResult = this.pinpad.parseResult(addonResponse);
      }
      this.logger.dir(parsedResult)
      let status = this.verifyEmvOutput(parsedResult, request);

      if (!status.hasError) {
        return { success: true, result: parsedResult, error: null };
      } else {
        if (isRetry || isNullOrUndefinedOrEmptyString(parsedResult.ResultCode)) {
          return { success: false, result: parsedResult, error: status.errorMessage };
        } else {
          let retryFunc = async () => await this.makeCreditCardAction(actionData, true);
          return await this.makeCreditCardActionRecovery(parsedResult, status, request.XField, retryFunc);
        }
      }
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  async startPinpadRecovery(request: any, parsedResult: any, isResetService: boolean, isShownResetInstructions: boolean = false): Promise<{ success: boolean; result: any; error: any;  request: EmvRequest | null } | { success: boolean; result: EmvOutput; error: string; request: EmvRequest | null }> {
    return { success: false, result: parsedResult, error: i18next.t('emv.PINPAD_RECOVERY'), request: null };
  }


  async payWithCreditCard(amount, emvMode: string, creditCardPaymentType, numberOfPayments, verificationCode?, tranType = String(TranType.CHARGE), firstPayment?, isRetry?, ParameterJ?, extraRequestTagData?)
    : Promise<{ success: boolean, result: EmvOutput, error: string }> {
    try {
      let parsedResult;
      let request = await this.createPaymentRequestObj(amount, emvMode, creditCardPaymentType.emvTypeCode, numberOfPayments, verificationCode, tranType, firstPayment, null, ParameterJ, extraRequestTagData)
      this.logger.log(JSON.stringify(request));
      let addonResponse = await this.pinpad.sendRequestToPinpad(request, RequestType.EMV, tranType == String(TranType.CREDIT) ? i18next.t('emv.EMV_RETURN_IN_PROGRESS') : i18next.t('emv.EMV_IN_PROGRESS'));

      // Check if we need to start the recovery process
      let startRevoceryProcess = false;
      if (isBlank(addonResponse)) {
        startRevoceryProcess = true;
      } else {
        if (this.configurations.isNewVersionAddonFiles || this.configurations.isAndroid){
          let emvResponseResult = this.verifySanitizeAndParseResult(addonResponse);
          parsedResult = emvResponseResult.emvResponse;
        }else{
          parsedResult = this.pinpad.parseResult(addonResponse);
        }

        if (this.pinpad.isCommunicationError(Number(parsedResult?.ResultCode)) || isBlank(parsedResult)) {
          startRevoceryProcess = true;
        }
      }
      let requestToVerify = request;
      if (startRevoceryProcess) {
        let recoveryResult = await this.startPinpadRecovery(request, parsedResult, isRetry, false);
        parsedResult = recoveryResult.result;
        if (!recoveryResult.success) {
          return { success: false, result: parsedResult, error: recoveryResult.error };
        }else{
          requestToVerify = recoveryResult.request;
          requestToVerify.RequestId = request.RequestId;
        }
      } else { }

      let status = this.verifyEmvOutput(parsedResult, requestToVerify);
      if (!status.hasError) {
        parsedResult.internalPaymentType = Number(creditCardPaymentType.ccTypeID);
        return { success: true, result: parsedResult, error: null };
      } else {
        return { success: false, result: parsedResult, error: status.errorMessage };
      }

    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }
  async makeCreditCardActionRecovery(parsedResult: EmvOutput, status, xField: string, onRetry: Function) {
    let recoveredFromError = false;

    let isCommunicationError = this.pinpad.isCommunicationError(Number(parsedResult.ResultCode));
    let isToPreventDuplicateTransactions = this.configurations.preventDuplicateTransactionWithXFields && parsedResult.AshStatus == '425';
    let retryCommunicationSucceeded = false;
    let blockListFileOutdated = parsedResult.Status == '1015';

    if (isCommunicationError) {
      retryCommunicationSucceeded = await this.isPinPadConnected();
    }

    if (retryCommunicationSucceeded || isToPreventDuplicateTransactions) {
      let foundTransaction = await this.findTransactionInPinpad(xField);
      if (foundTransaction.success) {
        recoveredFromError = true;
        parsedResult = foundTransaction.transaction;

        this.moveFailedXFieldToSuccessfullAfterRecovery(xField);
      } else {
        return await onRetry();
      }
    }

    if (blockListFileOutdated) {
      await this.callShvaWithoutTransmittingTransactions(i18next.t('emv.blockListFileOutdatedUpdatingIt'));
      return await onRetry();
    }

    this.logger.error(status.errorMessage);
    return { success: recoveredFromError, result: parsedResult, error: status.errorMessage };
  }



  async attemptCancelTransaction(addonResponse, creditTerms, tranType, amount): Promise<string> {
    let dummyEmvOutput = new EmvOutput();

    let responseString = this.getEmvResponseString(addonResponse)
    let originalUid = "";
    let originalIdStartPosition = (responseString.indexOf("<Uid>") + "<Uid>".length);
    let originalIdEndPosition = responseString.indexOf("</Uid>");
    originalUid = responseString.slice(originalIdStartPosition, originalIdEndPosition);

    if (originalIdStartPosition != (-1 + "<Uid>".length) &&
      originalIdEndPosition != -1 && !isNullOrUndefinedOrEmptyString(originalUid)) {

      dummyEmvOutput.Currency = this.multiCurrencyService.getPaymentCurrencyCreditRequestEMV();
      dummyEmvOutput.CreditTerms = creditTerms;
      dummyEmvOutput.Amount = String(Math.round(amount * 100));
      dummyEmvOutput.TranType = tranType;
      dummyEmvOutput.Uid = originalUid;

      try {
        let cancelResponse = await this.cancelPayment(dummyEmvOutput);
        if (cancelResponse.success) {
          return i18next.t('emv.responseIsNotComplete');
        }
      } catch (error) {
        this.logger.error(error);
      }
    }

    return i18next.t('emv.badResponseWasntCancelled');
  }

  verifySanitizeAndParseResult(emvResponse: any): { valid: boolean, emvResponse?: EmvOutput } {
    let responseString = this.getEmvResponseString(emvResponse)

    const allowedChars = /[a-zA-Zא-ת0-9ء-ي.&\-_\(\)\/\"\'%*!,:–?#|@\{\}\[\]\$\+ \<\>\=]/g
    responseString = responseString.match(allowedChars).join("")

    if (!this.isResponseComplete(responseString)) { return { valid: false }; }

    if (typeof emvResponse == "object") {
      if (this.configurations.isHttpConnection) {
        emvResponse.body = responseString;
      } else {
        emvResponse.request.result = responseString;
      }
    } else {
      emvResponse = responseString;
    }

    try {
      let parsedResult = this.pinpad.parseResult(emvResponse);
      return { valid: true, emvResponse: parsedResult }
    } catch (error) {
      this.logger.error(error)
      return { valid: false }
    }
  }

  getEmvResponseString(emvResponse: any): string {
    if (typeof emvResponse == "object") {
      return this.configurations.isHttpConnection ? emvResponse.body : emvResponse.request.result
    } else {
      return emvResponse
    }
  }

  isResponseComplete(emvResponse) {
    let isValid = emvResponse.includes("<EMV_Output>") && emvResponse.includes("</EMV_Output>");
    isValid = isValid || (emvResponse.includes("<Response>") && emvResponse.includes("</Response>"));
    isValid = isValid && (emvResponse.indexOf("<ReceiptMerchant>") == -1 || emvResponse.indexOf("</ReceiptMerchant>") != -1);
    isValid = isValid && (emvResponse.indexOf("<ReceiptCustomer>") == -1 || emvResponse.indexOf("</ReceiptCustomer>") != -1);

    return isValid;
  }

  async findTransactionInPinpad(xField: string, requestId = null, rawResult = false, showLoadingMessage = true) {
    try {
      if(showLoadingMessage){
        this.loadingMessageAndDialogManager.showLoadingMessage(i18next.t("emv.checkingForSaleInPinpad"));
      }
      let queryRequest = this.createTransactionQueryRequest(xField, requestId);
      let rawResponse = await this.pinpad.sendRequestToPinpad(queryRequest, RequestType.EMV)

      if (isBlank(rawResponse)) {
        return { success: false, transaction: null, nullResponse: true };
      }

      let queryResponse = this.pinpad.parseResult(rawResponse);
      if(showLoadingMessage){
        this.loadingMessageAndDialogManager.hideLoadingMessage()
      }
      if (queryResponse.ResultCode == "0") {
        return { success: true, transaction: rawResult ? rawResponse : Object.assign(new EmvOutput(), queryResponse.EMV_Output), request: queryRequest };
      }
      return { success: false, transaction: rawResult ? queryResponse : Object.assign(new EmvOutput(), queryResponse), request: queryRequest };
    } catch (error) {
      if(showLoadingMessage){
        this.loadingMessageAndDialogManager.hideLoadingMessage()
      }
      this.logger.error(error);
      return { success: false, transaction: null, request: null };
    }
  }

  cancelPayment(paymentToCancel: EmvOutput)
    : Promise<{ success: boolean, result: EmvOutput, error: string }> {
    return new Promise(async (resolve, reject) => {

      let request = await this.createCancelRequestObj(paymentToCancel);
      this.logger.dir(JSON.stringify(request));
      this.pinpad.sendRequestToPinpad(request, RequestType.EMV)
        .then((addonResponse: any) => {
          return this.pinpad.parseResult(addonResponse)
        })
        .then(parsedResult => {
          this.logger.dir(parsedResult)
          let status = this.verifyEmvOutput(parsedResult, request, paymentToCancel);
          if (status.hasError) {
            this.logger.error(status.errorMessage);
            resolve({ success: false, result: parsedResult, error: status.errorMessage })
          }
          else {
            resolve({ success: true, result: parsedResult, error: null })
          }
        })
        .catch(error => {
          reject(error)
        })

    })

  }

  async creditPayment(originalPayment: any)

    : Promise<{ success: boolean, result: EmvOutput, error: string, originalPayment: any }> {
    if (this.configurations.moneyOnlyRefund) {
      return this.creditPaymentInternal(originalPayment, false);
    } else {

      let result = await this.loadingMessageAndDialogManager.showAlertWithAnswer(
        i18next.t('emv.CREDIT_DIALOG_CONTENT'),
        i18next.t('emv.CREDIT_HEADER'),
        i18next.t('emv.CREDIT_DIALOG_CONTINUE_BUTTON_TEXT'),
        i18next.t('emv.CREDIT_DIALOG_CANCEL_BUTTON_TEXT'),
        true);

      if (result == DialogAnswer.OK) {
        return await this.creditPaymentInternal(originalPayment, false)
      }

      return await this.creditPaymentInternal(originalPayment, true)
    }
  }

  creditPaymentInternal(originalPayment, isManual: boolean): Promise<{ success: boolean, result: EmvOutput, error: string, originalPayment: any }> {
    return new Promise(async (resolve, reject) => {
      if (originalPayment.isEmv) {
        originalPayment = Object.assign(new EmvOutput(), originalPayment)
      }
      let request;
      let waitMessage;
      if (this.configurations.moneyOnlyRefund && this.configurations.shouldCancelOnCredit()) {
        request = await this.createCancelRequestObj(originalPayment, true);
        waitMessage = i18next.t('emv.cancelingPayment');
      } else {
        request = await this.createCreditRequestObj(originalPayment, isManual);
        waitMessage = i18next.t('emv.EMV_CREDIT_IN_PROGRESS');
      }
      this.logger.dir(request);
      this.pinpad.sendRequestToPinpad(request, RequestType.EMV, waitMessage)
        .then(async addonResponse => {
          let result = this.pinpad.parseResult(addonResponse);

          if (this.configurations.preventDuplicateTransactionWithXFields && result.AshStatus == "425" && result.ResultCode == "10050") {
            // If the transaction already exists in the pinpad, we get it and return the existing transaction

            let existingTransactionData = await this.findTransactionInPinpad(result.Xfield, result.RequestId, true);

            if (existingTransactionData.success) {

              result = this.pinpad.parseResult(existingTransactionData.transaction);

              Object.assign(result, result.EMV_Output);
              delete result.EMV_Output;
            }
          }

          return result;
        })
        .then(parsedResult => {
          this.logger.dir(parsedResult)
          let status = this.verifyEmvOutput(parsedResult, request, originalPayment)
          if (status.hasError && status.isCancellationError) {
            let jsonRequest = xml2json(request.rawXml);
            this.getCancelCallByUidFromPinpad(jsonRequest.Request.OriginalUid).then(response => {
              if (response) {
                response = Object.assign(new EmvOutput, response);
                resolve({ success: true, result: response, error: null, originalPayment: originalPayment });
              } else {
                resolve({ success: false, result: parsedResult, error: status.errorMessage, originalPayment: originalPayment });
              }
            })
          }
          else if (status.hasError) {
            this.logger.error(status.errorMessage);
            resolve({ success: false, result: parsedResult, error: status.errorMessage, originalPayment: originalPayment })
          }
          else {
            resolve({ success: true, result: parsedResult, error: null, originalPayment: originalPayment })
          }
        })
        .catch(error => {
          reject(error)
        })

    })
  }

  async callShvaWithoutTransmittingTransactions(optionalSplashMsg?: string) {
    let request = this.pinpad.getXmlRequest("010", this.configurations.emvTimeout, { SubCommand: "006" });
    let addonResponse = await this.pinpad.sendRequestToPinpad(request, RequestType.EMV, optionalSplashMsg || i18next.t('emv.CALLING_SHVA_WITHOUT_TRANSMITTING_TRAN'))
    this.logger.dir(addonResponse);
    return this.pinpad.parseResult(addonResponse)
  }

  deleteCurrentPinpadTran(){
    if(this.configurations.usePinpadInSwitchMode == true || this.configurations.isGateway == true){
      let request = this.pinpad.getXmlRequest("010", this.configurations.emvTimeout, { SubCommand: "013" });
      return this.pinpad.sendRequestToPinpad(request, RequestType.EMV, i18next.t('emv.DELETING_TRAN_FILE'))
        .then(addonResponse => {
          this.logger.dir(addonResponse);
          return this.pinpad.parseResult(addonResponse)
        })
    }
  }

  async transmitAndDeleteTranIfNeeded(){
      let results = [];
      let transmitResult = await this.transmit()
      results.push(transmitResult)
      if (transmitResult.ResultCode == "0") {
        if(this.configurations.usePinpadInSwitchMode == true || this.configurations.isGateway == true){
          let deleteResult = await this.deleteCurrentPinpadTran()
          results.push(deleteResult)
      }
    }

    return results;
  }

  transmit() {
    let request, loadingMessage;

    if(this.configurations.usePinpadInSwitchMode == true || this.configurations.isGateway == true){
      loadingMessage = i18next.t('emv.EMV_PERFORMING_TRANSMIT_TO_SWITCH')
      request = this.pinpad.getXmlRequest("010", this.configurations.emvTimeout, { SubCommand: "012" })
    }
    else {
      request = this.createTransmitRequestObj();
      loadingMessage = i18next.t('emv.EMV_PERFORMING_TRANSMIT');
    }

    return this.pinpad.sendRequestToPinpad(request, RequestType.EMV, loadingMessage)
      .then(addonResponse => {
        this.logger.dir(addonResponse);
        return this.pinpad.parseResult(addonResponse)
      })
  }

  async isPinPadConnected(): Promise<boolean> {
    this.loadingMessageAndDialogManager.showLoadingMessage(i18next.t('emv.CHECKING_PINPAD_CONNECTION'));
    try {
      let request = this.createCheckConnectionRequestObj();
      let addonResponse = await this.pinpad.sendRequestToPinpad(request, RequestType.EMV);
      let result = this.pinpad.parseResult(addonResponse);
      this.loadingMessageAndDialogManager.hideLoadingMessage();
      if (result.ResultCode != "0" && !isNullOrUndefinedOrEmptyString(result.ResultCode)) {
        window.localStorage.setItem("lastEMVErrorCode", result.ResultCode)
      }
      else {
        window.localStorage.removeItem("lastEMVErrorCode");
      }
      return result.ResultCode == "0";
    }
    catch (error) {
      this.logger.error(error);
      this.loadingMessageAndDialogManager.hideLoadingMessage();
      return false;
    }

  }

  async pinpadVersion(): Promise<number> {
    let request = this.createCheckConnectionRequestObj();
    let addonResponse = await this.pinpad.sendRequestToPinpad(request, RequestType.EMV);
    let result = this.pinpad.parseResult(addonResponse);

    //example
    //response format: "8510331463"
    //return last 4 digits as a number - 1463
    return Number(result.AshraitEMVVersion.substr(-4))
  }

  manualConfirmationNumberRequired(result: EmvOutput): boolean {
    return (this.pad(result.AshStatus) == "003")
  }

  oldFileExists(result: EmvOutput): boolean {
    return result.ResultCode == "10048" && result.Status == "1015";
  }

  oldTranNotEmpty(result: EmvOutput): boolean {
    return result.ResultCode == "10048" && result.Status == "1014";
  }

  isTimeoutResponse(result: EmvOutput) {
    return result.ResultCode == "10000" || result.AshStatus == "706"
  }

  saveCancelCall(uid: string, xField: string) {
  }

  async getCancelCallByUidFromPinpad(uid) {
    return null;
  }

  isNegativeCharge(paymentData) {
    return String(paymentData.TranType) == String(TranType.CREDIT) || String(paymentData.Mti) == String(MtiType.CANCEL);
  }

  async getTranFile(fileNo = 0, recordsPerRequest = 1, currentRecord = 0, requestId = null) {
    let request = this.pinpad.getXmlRequest("007", this.configurations.emvTimeout, {
      FileNo: this.pad(String(fileNo), 2),
      RecordsPerRequest: this.pad(String(recordsPerRequest)),
      CurrentRecord: this.pad(String(currentRecord)),
    }, requestId)

    let addonResponse = await this.pinpad.sendRequestToPinpad(request, RequestType.EMV, i18next.t('emv.GETTING_TRANS_REPORT'));
    this.logger.dir(addonResponse);
    return this.pinpad.parseResult(addonResponse);
  }

  generateXField(transactionType: string, mtiType: string, amount: string) {
    return String((new Date()).getTime());
  }

  getDepositReportSumAndCount(depositReportLines) {
    const DEBIT_TITLE_LINE = "        הבוח  לקש כ\"הס";
    const CREDIT_TITLE_LINE = "        תוכז  לקש כ\"הס";

    let totals = {
      debitSum: 0,
      creditSum: 0,
      debitCount: 0,
      creditCount: 0,
    }

    let debitIndex = depositReportLines.indexOf(DEBIT_TITLE_LINE);
    let creditIndex = depositReportLines.indexOf(CREDIT_TITLE_LINE);

    if (debitIndex > -1) {
      let splitDebitSums = depositReportLines[debitIndex + 1].split(/\s+/);
      totals.debitCount = parseInt(splitDebitSums[0]);
      totals.debitSum = parseFloat(splitDebitSums[1]);
    }

    if (creditIndex > -1) {
      let splitCreditSums = depositReportLines[creditIndex + 1].split(/\s+/);
      totals.creditCount = parseInt(splitCreditSums[0]);
      totals.creditSum = parseFloat(splitCreditSums[1]);
    }

    return totals;
  }

  getDepositReportDateTime(depositReportLines) {
    const DATE_LINE_TEXT = ":ךיראת";
    const TIME_LINE_TEXT = ":העש";

    let date = null;
    let time = null;

    for (let line of depositReportLines) {
      if (line.includes(DATE_LINE_TEXT)) {
        date = line.replace(DATE_LINE_TEXT, "").trim();
      }

      if (line.includes(TIME_LINE_TEXT)) {
        time = line.replace(TIME_LINE_TEXT, "").trim();

        if (date) {
          break;
        }
      }
    }

    if (date && time) {
      return date + ' ' + time;
    }

    return null;
  }
}
