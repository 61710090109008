
module PositiveTS {
  export module Service {
    export module AppWorkerHandler {
      export function init(posAccessToken:string,currentDBVersion) {
        try {
          session.worker = new (<any>window).Worker("assets/queueWorker.js");
          session.worker.onmessage = incomingMessage;
          let realDBVersion = Number(currentDBVersion);
          const isOnline = window.localStorage.getItem("isOnline") == 'true';
          sendMessage(Shared.Constants.Messaging.InitQueues,{token: posAccessToken, dbVersion: realDBVersion, isOnline: isOnline});
        }
        catch (e) {
          console.error('failed initializing worker');
          console.error(e);
        }
      }

      export function sendMessage(method:string,payload:any) {
        if (session && session.worker) {
          console.debug(`outgoing message: ${method}, payload is: ${payload}`);
          session.worker.postMessage({method: method,payload: payload});
        }
      }

      function incomingMessage(event) {
        switch (event.data.type) {
          case PositiveTS.Shared.Constants.Messaging.OfflineSalesChanged:
          case PositiveTS.Shared.Constants.Messaging.OnlineStatusChanged:
          case PositiveTS.Shared.Constants.Messaging.InitQueues:
            console.debug(event.data);
            Pinia.globalStore[event.data.type](event.data.payload)
            break;
          default:
            console.debug(event.data);
        }

      }
    }
  }
}