module PositiveTS {
  export module Service {
    export module ManagerApproval {
      const GENERATE_SMS_CODES_URL = '/employees/send_manager_sms_codes'; 
      export let isSmsCodesSent = false;
      let smsCodesData = [];
      export async function requestSmsCodes(permissions){
          try{
              app.showLoadingMessageDialog(i18next.t('managerApprovalBySmsLoading'));
              let permission = '';
              if(!posUtils.isBlank(permissions) && posUtils.isArray(permissions)){
                  permission = permissions[0];
              }
              const data = {
                  employeeRequestName: session.pos.employeeName,
                  requestedAction:permission
              }
              let response = await PositiveTS.Service.FetchReq.jsonReq(GENERATE_SMS_CODES_URL, 'post', {...data});   

              if(response.result.success == true){
                  saveSmsCodesData(response.result);    
                  app.hideLoadingMessageDialog();
              }else{
                  app.hideLoadingMessageDialog();
                  if(response.result.message){
                      showError(response.result.message);
                  }else{
                      showError(i18next.t("managerApprovalBySmsError"));
                  }
              }
          }catch(err){
              console.log(err);
              showError(i18next.t("managerApprovalBySmsError"));
              app.hideLoadingMessageDialog();
          }
      }

      function saveSmsCodesData(savedHashedCodes){
              try{
                  isSmsCodesSent = true;
                  smsCodesData = savedHashedCodes.data;
              }catch(err){
                  console.log(err);
              }
      }
      export function resetSmsCodesData(){
          isSmsCodesSent = false;
          smsCodesData =[];
      }
      export function verifySmsCode(code){
          try{
              let managersData = smsCodesData;
              if(posUtils.isBlank(managersData)){
                  return null;
              }
              const hashedClientCode = Service.Sha256.sha256.hash(code);
              let manager = managersData.find(manager => manager.hashed_sms_code == hashedClientCode);
              if(!posUtils.isBlank(manager)){
                  return manager;
              }
          }catch(err){
              console.log(err);
              return null;
          }
      }
      function showError(errorText) {
          return app.showAlertDialog({
          header: i18next.t('error'),
          content: errorText,
          continueButtonText: i18next.t("ok"),
          hideCancelButton: true
          })
      }
      export async function byFingerprint(permissions){
          try{
              let verifyedUser = await PositiveTS.Service.Fingerprint.verify();
              let userMagneticCard = await PositiveTS.Service.WasmDB.promiseSql("select magneticCard from Employee where employeeID = " + verifyedUser);
              let result = await PositiveTS.Service.GlobalUI.handleManagerMagneticCard(userMagneticCard[0].magneticCard, permissions);
              return result;
          }catch(err){
              showError(err.message)
          }
      }
      export async function swipeCard() {
    
          try{
              app.showLoadingMessage("העבר כרטיס מנהל");
              let cardNumber = await PositiveTS.Service.EMV.swipe();
              app.hideLoadingMessage();
              return cardNumber;
          }catch(err){
              app.hideLoadingMessage();
          }
    }
      export function isOpenDrawerPrinting(){
          return jsonConfig.getVal(jsonConfig.KEYS.isOpenDrawerManagerApproval);
      }
      export async function createOpenDrawerLogSuspiciousActivity(){
          try{
              let result =  await Storage.Entity.SuspiciousActivityLog.logSuspiciousActivity(Shared.Constants.SuspiciousActions.OPEN_DRAWER, session.pos.employeeID);
              return result;
          }catch(err){
              console.log(err)
          }
      }
    export function isReducingQuantity(){
              return jsonConfig.getVal(jsonConfig.KEYS.isReducingQuantityManagerApproval);
    }
    export function isNegativeChangingQuantity(){
              return jsonConfig.getVal(jsonConfig.KEYS.isNegativeChangingQuantityManagerApproval);
    }


      }
  }
}