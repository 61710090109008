module PositiveTS {
export module Service {
export module GlobalUI {
    let unSubSelfServiceKeypadInput = () => { };
    let unSubSelfServiceKeypadInputSubmit = () => { };

    export function blockUIAndShowMessage(message, blockEvents = true) {
		if (blockEvents) {
			$(document).off('keypress');
			$(document).off('keyup');
		}
        app.showLoadingMessageDialog(message);
        Pinia.globalStore.setBlockUIStatus({blocked: true, message: message, blockEvents: blockEvents});
	}

	export function resumeUIAndHideMessage(resumeEvents = true) {
		if (resumeEvents) {
			$(document).off('keypress');
			$(document).on('keypress',posVC.onKeyPress);
			$(document).off('keyup',app.handleEnterEscape);
			$(document).on('keyup',app.handleEnterEscape);
		}
		Pinia.globalStore.setBlockUIStatus({blocked: false, message: null, blockEvents: !resumeEvents});
        app.hideLoadingMessageDialog();
	}

	export function showLoadingMessageDialog(content){
		let dialog = PositiveTS.VueInstance.$refs.loadingMessage;
		dialog.content = content || i18next.t('content');
		return dialog.open();
		
	}
	export function hideLoadingMessageDialog() {
		let dialog = PositiveTS.VueInstance.$refs.loadingMessage;
		return dialog.close();
    }
    function promptManagerApprovalErrorMsg(text){
        $('#prompt-manager-approval-error-msg').text(text);
        $('#prompt-manager-approval-error-msg').fadeIn('slow').delay(5000).fadeOut('slow');
    }

    function promiseShowAlertInner(options, continueCallback, cancelCallback) {		
        return new Promise(function(resolve, reject) {
            app.showAlert(options, function(){
                if (continueCallback) {continueCallback();}
                resolve("continue");
            }, function(){
                if (cancelCallback) {cancelCallback();}
                resolve("cancel");
            });
        });
    }

    export async function promiseShowAlert(options, continueCallback, cancelCallback) {
        let result = await runAndUnblockUI(promiseShowAlertInner,options, continueCallback, cancelCallback );
        return result;
    }
    
    function showManagerApprovalDialogInner(permissions) {
        return new Promise(async (resolve,reject) => {
            let isManagerApproveActionBySMS = jsonConfig.getVal(jsonConfig.KEYS.isManagerApproveActionBySMS);
            if(isManagerApproveActionBySMS){
                PositiveTS.Service.ManagerApproval.resetSmsCodesData();
            }
            if(jsonConfig.getVal(jsonConfig.KEYS.passwordProtected) && !jsonConfig.getVal(jsonConfig.KEYS.isSelfServiceSuperMarketPos)) {
                Pinia.componentsStore.openComponent( {
                    componentName: 'loginDialog', 
                    args: [false, false, true, {resolve: resolve, reject: reject}]
                });
            }else if(jsonConfig.getVal(jsonConfig.KEYS.passwordProtected) && jsonConfig.getVal(jsonConfig.KEYS.isSelfServiceSuperMarketPos)){
                Pinia.componentsStore.openComponent( {
                    componentName: 'loginDialog', 
                    args: [false, false, false, {resolve: resolve, reject: reject}]
                });
            } else {		 
                let swipeBtn = '<li id="prompt-manager-approval-swipe-card-btn" class="swipe-icon fad fa-scanner-keyboard" style="font-size: 30px; margin-right: 3px; margin-top: 4px;"></li>';
                $('#prompt-manager-approval-manager-card').val('');
                
                $("#prompt-manager-approval-manager-card-container").inputField('unbind');
                $("#prompt-manager-approval-manager-card-container").inputField();
                $("#prompt-manager-approval-manager-card-container").inputField('focus');
                
    
                // unbind the clicks before register to the click
                // that way i make shure the click will cal only once
                $('#prompt-manager-approval-continue').off('click');
                
                $('#prompt-manager-approval-error-msg').hide();
                if((jsonConfig.getVal(jsonConfig.KEYS.usePinPadSwipe)) && (session.pos.isEmv) && !isManagerApproveActionBySMS) {
                    $("#prompt-manager-approval-swipe-card-btn").remove();
                    $("#prompt-manager-approval-manager-card-container").append(swipeBtn);
                    $("#prompt-manager-approval-swipe-card-btn").off('click');
                    $("#prompt-manager-approval-swipe-card-btn").on('click',async function(){
                        try{
                            let cardNumber = await PositiveTS.Service.ManagerApproval.swipeCard();
                            if(cardNumber){
                                $('#prompt-manager-approval-manager-card').val(cardNumber);
                                $("#prompt-manager-approval-continue").trigger("click");
                            }
                        }catch(err){
                            console.log(err);
                        }
                        
                    });
                }
                $("#prompt-manager-approval-sms").off('click');
                $("#prompt-manager-approval-sms").on('click',function () {
                    PositiveTS.Service.ManagerApproval.requestSmsCodes(permissions);
                });

                $("#prompt-manager-approval-fingerprint").unbind('click');
                $("#prompt-manager-approval-fingerprint").on('click',async function(){
                    try{
                        let verifiedFingerPrint = await Service.ManagerApproval.byFingerprint(permissions);
                        if(verifiedFingerPrint){
                            resolve(verifiedFingerPrint)
                        }
                        
                    }catch(err){
                        console.log(err);
                    }
                });

                $('#prompt-manager-approval-continue').on('click', async function () {
                    if(PositiveTS.Service.ManagerApproval.isSmsCodesSent){
                        let smsCodeValue = $('#prompt-manager-approval-manager-card').val(); 
                        let managerApprovedTheAction = PositiveTS.Service.ManagerApproval.verifySmsCode(smsCodeValue);
                        if(!posUtils.isBlank(managerApprovedTheAction)){
                            try{
                                let employee = new PositiveTS.Storage.Entity.Employee();
                                let employees = await employee.fetchByStoreAndEmployeeID(managerApprovedTheAction.store_id,managerApprovedTheAction.employee_id)
                                PositiveTS.Service.ManagerApproval.resetSmsCodesData();
                                GlobalUI.hideManagerApproval()
                                return resolve(employees[0]);
                            }catch(err){
                                console.log(err);
                            }
                        }else{
                            promptManagerApprovalErrorMsg(i18next.t("managerApprovalGenericError"))
                        }
                    }

                    $('#prompt-manager-approval-error-msg').hide();
                    var magneticCard = $('#prompt-manager-approval-manager-card').val();

                    if (posUtils.isNullOrUndefinedOrEmptyString(magneticCard)) {
                        return;
                    }
                    if(!isManagerApproveActionBySMS){
                        const verifiedUser = await handleManagerMagneticCard(magneticCard, permissions)
                        if(verifiedUser) {
                            resolve(verifiedUser)
                        }
                    }else{
                        promptManagerApprovalErrorMsg(i18next.t("managerApprovalGenericError"))
                    }

                });
                $('#prompt-manager-approval-cancel').on('click',function () {
                    GlobalUI.hideManagerApproval()
                    return reject();
                });
    
                // Show the alert dialog overlay
                $('#alert-overlay').show();
    
                // Show the alert dialog
                $('#prompt-manager-approval').show();
    
                // Set the focus on the input field
                let inputEle = $('#prompt-manager-approval-manager-card');
                inputEle.focus();
                if (jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)) {
                    $("#prompt-manager-approval-manager-card-container-keyboard-icon").hide();
                    $('#prompt-manager-approval').css('height', 'unset');
                    inputEle.keyup(() => {
                        Pinia.globalStore.setSelfServiceKeypadInput(inputEle.val()); 
                    });
                    Pinia.componentsStore.openComponent( { componentName: "SelfServiceKeypadModal", args: [] });

                    unSubSelfServiceKeypadInput = Vue.watch(() => Pinia.globalStore.selfServiceKeypadInputVal, (newValue, oldValue) => {
                        inputEle.val(Pinia.globalStore.selfServiceKeypadInputVal);
                    });
                    unSubSelfServiceKeypadInputSubmit = Vue.watch(() => Pinia.globalStore.isSubmitedSelfServiceKeypadInput, (newValue, oldValue) => {
                        inputEle.val(Pinia.globalStore.selfServiceKeypadInputVal);
                        $("#prompt-manager-approval-continue").trigger("click");
                    });
                } else {
                    $("#prompt-manager-approval-manager-card-container-keyboard-icon").trigger("click")
                }
                
            }
        })
    }
    export function closeManagerApproval(){
        $("#prompt-manager-approval-cancel").click();
    }
    export function hideManagerApproval(){
        if (jsonConfig.getVal(jsonConfig.KEYS.simpleSelfService)) {
            unSubSelfServiceKeypadInput();
            unSubSelfServiceKeypadInputSubmit();
            Pinia.componentsStore.closeComponent("SelfServiceKeypadModal")
        }
        $('#prompt-manager-approval').hide();
        $('#alert-overlay').hide();
    }

    export async function handleManagerMagneticCard(magneticCard, permissions) {
        // Check whether the given card is a manager card
        try{
            let employee = new PositiveTS.Storage.Entity.Employee();
            let employees = await employee.fetchByStoreAndMagneticCardAndPermissions(session.pos.storeID, magneticCard, permissions);
            
            if(employees){
                if (employees.length == 0) {
                    throw new Error('no employees found');
                }
                GlobalUI.hideManagerApproval()
                return employees[0];
            }
            else{
                return null;
            }
        }catch(err){
            promptManagerApprovalErrorMsg(i18next.t("givenCardIsNotmanagerCard"))
            return null;  
        }
    }

    export async function showManagerApprovalDialog(permissions) {
        let result = await runAndUnblockUI(showManagerApprovalDialogInner,permissions);
        return result;
    }

    export async function runAndUnblockUI(func,...args) {
        let uiBlockMessage = Pinia.globalStore.uiBlockMessage;
        let uiBlocked = Pinia.globalStore.uiBlocked;
        let uiBlockedEvents = Pinia.globalStore.uiBlockedEvents;
        let restoreUIBlock = false;
        if (uiBlocked) {
            restoreUIBlock = true;
            resumeUIAndHideMessage(uiBlockedEvents)
        }
        let result = await func.call(this,...args);
        if (restoreUIBlock) {
            blockUIAndShowMessage(uiBlockMessage,uiBlockedEvents)        
        }

        return result;

    }

}}}